import {
  serverCreateNewProblem,
  serverDeleteProblem,
  serverGetAllProblems,
  serverGetProblem,
  serverUpdateProblem, serverUpdateProblemTypeJudge
} from '../../config/services/backend';
import { OK, ALERT_TYPES, TypeJudgeType } from '../../types';
import { addNotice } from './notices';
import { history } from '../reducers';
import { addBlocking, addLoading, subBlocking, subLoading } from './flags';
import { APP_ROUTES } from '../../config/constans';
import {
  NewProblemState,
  ProblemMetaState,
  PROBLEMS_ACTIONS,
  ProblemsActionTypes,
  ProblemState
} from '../types/';

export const updateProblem = (problem: ProblemState): ProblemsActionTypes => {
  return {
    type: PROBLEMS_ACTIONS.UPDATE_PROBLEM,
    problem
  }
};

export const replaceProblems = (problems: Array<ProblemMetaState>): ProblemsActionTypes => {
  return {
    type: PROBLEMS_ACTIONS.REPLACE_PROBLEMS,
    problems
  }
};

export const createNewProblem = (newProblem: NewProblemState) => {
  return async (dispatch: Function) => {
    dispatch(addBlocking());
    dispatch(addLoading());
    const request = await serverCreateNewProblem(newProblem);
    if (request.ok === OK) {
      dispatch(updateProblem(request.result));
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `Graph ${request.result.name} created`}));
      history.push(APP_ROUTES.ROOT.JUDGE.PROBLEM.VIEW(request.result.id));
    } else {
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
    dispatch(subLoading());
    dispatch(subBlocking());
  }
};

export const getProblem = (problemId: number) => {
  return async (dispatch: Function) => {
    dispatch(addBlocking());
    dispatch(addLoading());
    const request = await serverGetProblem(problemId);
    if (request.ok === OK) {
      dispatch(updateProblem(request.result));
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `Successfully ${request.result.name} problem loaded`}));
    } else {
      history.push(APP_ROUTES.ROOT.JUDGE.PROBLEM.LIST);
      dispatch(addNotice({type: ALERT_TYPES.INFO, label: 'Redirect to list'}));
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
    dispatch(subLoading());
    dispatch(subBlocking());
  }
};

export const putProblemTypeJudge = (chunkProblem: {id: number, typeJudge: TypeJudgeType }) => {
  return async (dispatch: Function) => {
    dispatch(addBlocking());
    dispatch(addLoading());
    const request = await serverUpdateProblemTypeJudge(chunkProblem);
    if (request.ok === OK) {
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `Test groups updated`}));
    } else {
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
    dispatch(subLoading());
    dispatch(subBlocking());
  }
};

export const putProblem = (problem: ProblemState) => {
  return async (dispatch: Function) => {
    dispatch(addBlocking());
    dispatch(addLoading());
    const request = await serverUpdateProblem(problem);
    if (request.ok === OK) {
      dispatch(updateProblem(problem));
      history.push(APP_ROUTES.ROOT.JUDGE.PROBLEM.VIEW(problem.id));
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `Problem ${problem.name} updated`}));
    } else {
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
    dispatch(subLoading());
    dispatch(subBlocking());
  }
};

export const deleteProblem = (problemId: number) => {
  return async (dispatch: any) => {
    dispatch(addBlocking());
    dispatch(addLoading());
    const request = await serverDeleteProblem(problemId);
    if (request.ok === OK) {
      history.push(APP_ROUTES.ROOT.JUDGE.PROBLEM.LIST);
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `Problem ${problemId} deleted`}));
    } else {
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
    dispatch(subLoading());
    dispatch(subBlocking());
  }
};

export const getAllProblems = () => {
  return async (dispatch: Function) => {
    dispatch(addLoading());
    const request = await serverGetAllProblems();
    if (request.ok === OK) {
      dispatch(replaceProblems(request.result));
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `Get all problems`}));
    } else {
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
    dispatch(subLoading());
  }
};
