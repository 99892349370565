import { connect } from 'react-redux';

import Users from '../../components/Admin/Users/Users';
import { Message, RootStateType } from '../../store/types';
import { addNotice } from '../../store/actions';

export const mapStateToProps = (state: RootStateType) => {
  return {}
};

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    addNotice(notice: Message) {
      dispatch(addNotice(notice));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);

