import React from 'react';
import { useParams } from 'react-router';
import { secRoutes } from '../../../../config/constans';
import { Link } from 'react-router-dom';
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons';

export default () => {
  const params = useParams();
  const routesPath = [];
  secRoutes.forEach((section, index) => {
    if (params[section]) {
      const pathname = `/train/material/${secRoutes.slice(0, index + 1).map((sec) => (params[sec])).join('/')}`;
      routesPath.push(<div style={{display: 'flex', alignItems: 'center'}} key={pathname}>
        <NavigateNextIcon/>
        <Link to={{pathname: pathname}}>
          {params[section]}
        </Link>
      </div>);
    }
  });
  return (
    <div style={{display: 'flex', padding: '20px 0'}}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <NavigateNextIcon/>
        <Link to={{pathname: '/train/material'}} style={{flexGrow: '1'}}>
          tutorials
        </Link>
      </div>
      {routesPath}
    </div>
  )
}
