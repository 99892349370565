import React from "react";
import {Redirect} from "react-router";

export default ({
                  hash, // state
                  saveAccessToken
                }) => {
  const params = hash.split('&');
  if (params.length > 0 && params[0].startsWith('#access_token=')) {
    const key = decodeURIComponent(params[0].replace('#access_token=', ''));
    saveAccessToken(key);
  }
  return <Redirect to={'/account/user'}/>
}
