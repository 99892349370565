import React from 'react';

import EnhancedTable from '../../Shared/TableDefault';
import { HeaderBar } from '../../../containers/Shared/HeaderBar';

export default () => {
  return (
    <>
      <EnhancedTable/>
      <HeaderBar title="Ranking" actions={[]}/>
    </>
  );
}
