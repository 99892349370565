import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Cancel as CancelIcon, Save as SaveIcon } from '@material-ui/icons';

import './AddProblem.scss';

import { PROBLEM_INPUT_TYPE, PROBLEM_OUTPUT_TYPE, PROBLEM_STATUS, TEST_STATUS } from '../../../../types';
import { NewProblemState, UserState } from '../../../../store/types';
import { HeaderBar } from '../../../../containers/Shared';
import { APP_ROUTES } from '../../../../config/constans';
import { Link } from 'react-router-dom';
import Body from '../Editor/Body';

interface Props {
  // state
  user: UserState,
  // dispatch
  createNewProblem: Function
}

export default ({user, createNewProblem}: Props) => {
  const [newProblem, setNewProblem] = useState<NewProblemState>({
    name: 'Pedrito',
    status: PROBLEM_STATUS.PRIVATE,
    timeLimitPerTest: 1000,
    memoryLimitPerTest: 256000,
    typeInput: PROBLEM_INPUT_TYPE.STANDARD,
    typeOutput: PROBLEM_OUTPUT_TYPE.EXACT,
    description: 'Pedrito es un joven que le gustan las cadenas. El trabaja con caracteres del alfabeto en ingles en minuscula (`abcdefghijklnopqrstuvwxyz`) y mayuscula (`ABCDEFGHIJKLMNOPQRSTVWXYZ`) menos con la `m` ni con la `U` por que a Pedrito no le gustan esos caracteres, tambien trabaja con los caracteres `$ % ^ & ! |`.\n' +
      '\n' +
      'Pedrito tiene una forma de calcular el valor de sus cadenas y es la  siguiente:\n' +
      '\n' +
      '$\\sum_{i=0}^{|cadena| - 1} valor(caracter_i)*(-1)^{i}$, donde $|cadena|$ es la longitud de la cadena.\n' +
      '\n' +
      'El valor de los caracteres con los que trabaja Pedrito se muestran en la siguiente tabla:\n' +
      '\n' +
      'Caracter | Valor\n' +
      '-- | --\n' +
      'a, b, c, ... , x, y, z | 1, 2, 3, ..., 23, 24, 25\n' +
      'A, B, C, ...., X, Y, Z | 1, 2, 3, ..., 23, 24, 25\n' +
      '`$` | 111\n' +
      '`%` | 222\n' +
      '`^` | 333\n' +
      '`&` | 444\n' +
      '`!` | 555\n' +
      '`|` | 666\n' +
      '\n' +
      'Ayuda a pedrito a resolver este problema.\n',
    inputDescription: 'Una cadena con máximo $10^5$ caracteres con los que trabaja Pedrito.',
    outputDescription: 'Una cadena con máximo $10^5$ caracteres con los que trabaja Pedrito.',
    samples: [{input: 'abaca^aasf%$!|||', output: '1231435', group: 0, id: -4, status: TEST_STATUS.OK},
      {input: 'abcdefghijklnopqrst', output: '234', group: 0, id: -3, status: TEST_STATUS.OK},
      {input: 'a', output: '1', group: 0, id: -2, status: TEST_STATUS.OK},
      {input: 'bbbbbbbbbbbbbbbbbbbbbbbbb', output: '0', group: 0, id: -1, status: TEST_STATUS.OK}],
    author: user.nickname,
    tags: []
  });
  return (
    <>
      <HeaderBar
        title="Add Problem"
        actions={[
          <IconButton title={'Save'} onClick={() => createNewProblem(newProblem)}><SaveIcon/></IconButton>,
          <Link to={APP_ROUTES.ROOT.JUDGE.PROBLEM.LIST}>
            <IconButton title={'Cancel'}><CancelIcon/></IconButton>
          </Link>
        ]}
      />
      <Body problem={newProblem} setProblem={setNewProblem}/>
    </>
  );
}
