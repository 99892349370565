import React from 'react';
import { TextField } from '@material-ui/core';

import './TextFiledEditable.scss';

type Props = {
  label: string,
  value: string,
  onChange: any,
  rowsMax?: number,
  fullWidth?: boolean,
  isPassword?: boolean,
  error?: boolean
}

export const TextFieldEditable = ({label, value, onChange, rowsMax, fullWidth, isPassword, error}: Props) => {
  return (
    <TextField
      style={!!fullWidth ? {padding: '8px 0 4px 0'} : {padding: '8px 6px 4px 6px'}}
      id={`standard-basic-${label}`}
      type={isPassword ? 'password' : 'text'}
      label={label}
      variant="outlined"
      value={value}
      onChange={onChange}
      multiline={!isPassword}
      fullWidth={!!fullWidth}
      rowsMax={rowsMax ? rowsMax : undefined}
      className="text-field"
      error={!!error}
    />
  );
}
