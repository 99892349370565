import React from 'react';
import { Redirect } from 'react-router';
import clsx from 'clsx';

import { useStyles } from '../../config/styles';
import LoginCard from '../../containers/Login/LoginCard';
import { HeaderBar } from '../../containers/Shared';
import { UserState } from '../../store/types';
import { APP_ROUTES } from '../../config/constans';

export default ({user, toReturn}: { user: UserState, toReturn: string }) => {
  if (user.isLogged) {
    if (toReturn === APP_ROUTES.RADIX) {
      return <Redirect to={APP_ROUTES.ROOT.PROFILE.SELF}/>
    }
    return <Redirect to={toReturn}/>;
  }
  const classes = useStyles();
  return (
    <>
      <HeaderBar title={'Login'} actions={[]}/>
      <div className={clsx(classes.pageMaxHeight, classes.boxShadow)}>
        <LoginCard/>
      </div>
    </>
  )
}
