import {applyMiddleware, compose, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import thunk from "redux-thunk";

import createRootReducer from './reducers'
import {history} from "./reducers";

const logger = store => next => action => {
  // console.info('dispatching', action);
  // if (action.type === "@@router/LOCATION_CHANGE") {
  //   const user = JSON.parse(sessionStorage.getItem('data-user'));
  //   if (user) {
  //     sessionStorage.setItem('data-user', JSON.stringify({...user, lastVisit: action.payload.location.pathname}));
  //   }
  // }
  const result = next(action);
  // console.info('next state', store.getState());
  return result;
};


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer, // root reducer with router state
    preloadedState,
    // compose(
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        logger,
        thunk
      ),
    )
  );
  return store
}
