import { ProblemMetaState, PROBLEMS_ACTIONS, ProblemsActionTypes, ProblemState } from '../types';

export const problems = (state: { [key: number]: ProblemState } = {}, action: ProblemsActionTypes) => {
  const newState = {...state};
  switch (action.type) {
    case PROBLEMS_ACTIONS.UPDATE_PROBLEM:
      newState[action.problem.id] = {...newState[action.problem.id], ...action.problem};
      return newState;
    case PROBLEMS_ACTIONS.REPLACE_PROBLEMS:
      const newEmptyState: { [key: number]: ProblemState } = {};
      action.problems.forEach((problem: ProblemMetaState) => {
        newEmptyState[problem.id] = {...newState[problem.id], ...problem};
      });
      return newEmptyState;
    default:
      return state;
  }
};
