import React from 'react';
import {
  Grid
} from '@material-ui/core';

import { TextFieldEditable, SelectorEditable } from '../../Shared';
import { UserState } from '../../../store/types';
import { ROLES } from '../../../config/constans';
import { UserNewType } from '../../../config/Types';
import { ROLES_SELECTOR, EDITABLE, NEW, TYPE_EDIT, TYPE_NEW } from '../../../types';

interface Props {
  // state
  user: UserState,
  // props
  kind: TYPE_EDIT | TYPE_NEW
  userEditable: UserNewType,
  setUserEditable: Function,
}

export default ({user, kind, userEditable, setUserEditable}: Props) => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item style={{width: '300px'}}>
        <TextFieldEditable
          label="Name"
          value={userEditable.name}
          onChange={(e: any) => setUserEditable({...userEditable, name: e.target.value})}
        />
      </Grid>
      <Grid item style={{width: '300px'}}>
        <TextFieldEditable
          label="Given Name"
          value={userEditable.givenName}
          onChange={(event: any) => setUserEditable({...userEditable, givenName: event.target.value})}
        />
      </Grid>
      <Grid item style={{width: '300px'}}>
        <TextFieldEditable
          label="Family Name"
          value={userEditable.familyName}
          onChange={(event: any) => setUserEditable({...userEditable, familyName: event.target.value})}
        />
      </Grid>
      <Grid item style={{width: '300px'}}>
        <TextFieldEditable
          label="Nickname"
          value={userEditable.nickname}
          onChange={(event: any) => setUserEditable({...userEditable, nickname: event.target.value})}
        />
      </Grid>
      {kind === NEW && <Grid item style={{width: '300px'}}>
          <TextFieldEditable
              label="Email"
              value={userEditable.email}
              onChange={(event: any) => setUserEditable({...userEditable, email: event.target.value})}
          />
      </Grid>}
      {user.role === ROLES.SUPER_ADMIN &&
      <Grid item>
          <SelectorEditable
              editable={EDITABLE}
              selectorData={ROLES_SELECTOR}
              value={userEditable.role}
              onChange={(value: { value: ROLES }) => setUserEditable({...userEditable, role: value.value})}
          />
      </Grid>}
      <Grid item style={{width: '300px'}}>
        <TextFieldEditable
          label="Codeforces nickname"
          value={userEditable.codeforcesUserName}
          onChange={(event: any) => setUserEditable({...userEditable, codeforcesUserName: event.target.value})}
        />
      </Grid>
      <Grid item style={{width: '300px'}}>
        <TextFieldEditable
          label="Topcoder nickname"
          value={userEditable.topcoderUserName}
          onChange={(event: any) => setUserEditable({...userEditable, topcoderUserName: event.target.value})}
        />
      </Grid>
      <Grid item style={{width: '300px'}}>
        <TextFieldEditable
          label="Codechef nickname"
          value={userEditable.codechefUserName}
          onChange={(event: any) => setUserEditable({...userEditable, codechefUserName: event.target.value})}
        />
      </Grid>
      <Grid item style={{width: '300px'}}>
        <TextFieldEditable
          label="UVA nickname"
          value={userEditable.uvaUserName}
          onChange={(event: any) => setUserEditable({...userEditable, uvaUserName: event.target.value})}
        />
      </Grid>
    </Grid>
  );
}
