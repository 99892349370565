import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Card, CardActionArea, CardMedia, Typography,
  CardContent, Button, CardActions,
  Grid, IconButton
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';

import { useStyles } from '../../../config/styles';
import { UserState } from '../../../store/types';
import AddCategory from '../../../containers/Train/Category/AddCategory';
import EditCategory from './Category/EditCategory';
import DeleteCategory from './Category/DeleteCategory';
import {
  serverDeleteCategory,
  serverGetPages,
  serverUpdateCategory
} from '../../../config/services/backend';
import { OK, ALERT_TYPES, PageStateType } from '../../../types';
import { APP_ROUTES, ROLES } from '../../../config/constans';
import { HeaderBar } from '../../../containers/Shared';

type Props = {
  //state
  user: UserState,
  // dispatch
  addNotice: Function
}

export default ({user, addNotice}: Props) => {
  const classes = useStyles();
  const [categories, setCategories] = useState<Array<PageStateType>>([]);
  const getData = useCallback(async () => {
    const request = await serverGetPages('root');
    if (request.ok === OK) {
      setCategories(request.result.map((result: any) => ({
        date: result.date,
        title: result.title,
        tag: result.tag,
        parent: result.parent,
        description: result.description,
        status: result.status,
        ownerNickname: result.ownerNickname
      })));
      addNotice({type: ALERT_TYPES.SUCCESS, label: `Categories listed`});
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: request.message});
    }
  }, [addNotice]);
  const editCategory = async (category: PageStateType, oldCategory: PageStateType) => {
    const request = await serverUpdateCategory({
      title: category.title,
      tag: category.tag,
      parent: category.parent,
      description: category.description,
      status: category.status
    }, oldCategory.tag);
    if (request.ok === OK) {
      await getData();
      addNotice({type: ALERT_TYPES.SUCCESS, label: `Category ${category.title} modified`});
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: request.message});
    }
  };
  const deleteCategory = async (category: PageStateType) => {
    const request = await serverDeleteCategory(category.tag);
    if (request.ok === OK) {
      await getData();
      addNotice({type: ALERT_TYPES.SUCCESS, label: `Category ${category.title} deleted`});
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: request.message});
    }
  };
  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <>
      <HeaderBar title={'Categories'} actions={[]}/>
      <div className={clsx(classes.pageMaxHeight)}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          {categories.map((category) => {
            return <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={category.tag}>
              <Card className={''}>
                <Link to={APP_ROUTES.ROOT.TRAIN.CATEGORY.TAG(category.tag)}>
                  <CardActionArea>
                    <CardMedia
                      className={''}
                      image={'/images/coding.png'}
                      title={'Coding'}
                      style={{width: '100%', height: '100px'}}
                    />
                    <CardContent>
                      <Typography
                        variant="subtitle1"
                        className={clsx(classes.topLeftPosition, classes.backgroundDarkest)}
                      >
                        {category.tag}
                      </Typography>
                      <Typography variant="h5" component="h2">
                        {category.title}
                      </Typography>
                      <Typography gutterBottom variant="caption" display="block">
                        {category.status}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {category.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Link>
                <CardActions>
                  {(user.role === ROLES.SUPER_ADMIN || user.role === ROLES.ADMIN) && <>
                      <EditCategory button={<Button>Edit</Button>} category={category} action={editCategory}/>
                      <DeleteCategory button={<Button>Delete</Button>} category={category} action={deleteCategory}/>
                  </>}
                </CardActions>
              </Card>
            </Grid>
          })}
          {(user.role === ROLES.ADMIN || user.role === ROLES.SUPER_ADMIN) &&
          <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
              <AddCategory
                  button={<Card style={{textAlign: 'center', padding: '20px'}}>
                    <IconButton aria-label="add-category" className={''}>
                      <AddCircleIcon fontSize="large"/>
                    </IconButton>
                    <Typography gutterBottom variant="h5" component="h2">
                      Add Category
                    </Typography>
                  </Card>}
                  getData={getData} parent={'root'}
              />
          </Grid>}
        </Grid>
      </div>
    </>
  );
}
