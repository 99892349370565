import React, { useState } from 'react';
import { AppBar, Grid, IconButton, Toolbar, Typography } from '@material-ui/core';
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import './HeaderBar.scss';
import { useStyles } from '../../../config/styles';
import { useInterval } from '../../../helpers/customHooks';

export const HeaderBarComponent = ({loading, blocking, title, back, actions}: { loading: number, blocking: number, title: string, back?: string, actions?: Array<JSX.Element> }) => {
  const [pos, setPos] = useState(true);
  useInterval(() => {
    setPos(prevState => !prevState);
  }, loading ? 3000 : null);
  const classes = useStyles();
  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {back &&
          <Link to={back}>
              <IconButton>
                  <ArrowBackIosIcon/>
              </IconButton>
          </Link>}
          {!back && <div style={{marginLeft: '15px'}}/>}
          <Typography variant="h1" style={{width: '100%'}}>
            {title}
          </Typography>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={1}
            className={blocking ? classes.disable : ''}
          >
            {actions && actions.map((action, index) => <Grid item key={index}>{action}</Grid>)}
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.appBarBorderBottom}>
        {loading && <div
            className={classes.backgroundLight + (pos ? ' loader-point-left-to-right' : ' loader-point-right-to-left')}
        />}
      </div>
    </>
  );
}
