import { UserAccountState, USERS_ACTIONS, UsersActionTypes } from '../types';
import {
  serverGetUser
} from '../../config/services/backend';
import { OK, ALERT_TYPES } from '../../types';
import { addNotice } from './notices';
import { history } from '../reducers';
import { APP_ROUTES } from '../../config/constans';

export const updateUserAccount = (user: UserAccountState): UsersActionTypes => {
  return {
    type: USERS_ACTIONS.UPDATE_USER,
    user
  }
};

export const getUserAccount = (nickname: string) => {
  return async (dispatch: Function) => {
    const request = await serverGetUser(nickname);
    if (request.ok === OK) {
      dispatch(updateUserAccount({...request.result}));
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `User account ${request.result.nickname} loaded`}));
    } else {
      history.push(APP_ROUTES.ROOT.PROFILE.SELF);
      dispatch(addNotice({type: ALERT_TYPES.INFO, label: 'Redirect to account'}));
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
  }
};
