import React from 'react';
import {
  Code as CodeIcon,
  List as ListIcon,
  BubbleChart as BubbleChartIcon,
  Build as BuildIcon
} from '@material-ui/icons';

import NavBarList from './NavBarList';
import { APP_ROUTES } from '../../../config/constans';
import { NavbarItem } from '../../../types/navbar';

export default ({visible}: { visible: boolean }): JSX.Element => {
  const items: Array<NavbarItem> = [
    {
      icon: <ListIcon/>,
      title: 'List Codes',
      link: APP_ROUTES.ROOT.CODE.LIST
    },
    {
      icon: <CodeIcon/>,
      title: 'New Code',
      link: APP_ROUTES.ROOT.CODE.NEW
    },
    {
      icon: <ListIcon/>,
      title: 'List Graphs',
      link: APP_ROUTES.ROOT.GRAPH.LIST
    },
    {
      icon: <BubbleChartIcon/>,
      title: 'New Graph',
      link: APP_ROUTES.ROOT.GRAPH.NEW
    }
  ];
  const head = {
    icon: <BuildIcon/>,
    title: 'Utils'
  };
  return <NavBarList visible={visible} head={head} items={items}/>;
}
