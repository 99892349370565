export interface UserAccountState {
  name: string,
  givenName: string,
  familyName: string,
  nickname: string,
  imageUrl: string,
  codeforcesUserName: string,
  topcoderUserName: string,
  codechefUserName: string,
  uvaUserName: string
}

export enum USERS_ACTIONS {
  UPDATE_USER = 'UPDATE_USER'
}

interface UpdateOnlySourcesAction {
  type: USERS_ACTIONS.UPDATE_USER,
  user: UserAccountState,
}

export type UsersActionTypes = UpdateOnlySourcesAction;
