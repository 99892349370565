import { connect } from 'react-redux';

import Room from "../../../../components/Train/Rooms/Room/Room";

export const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
  }
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Room);

