import {USER_ACTIONS} from "../../store/types/user";
import store from "../../store";

export const authorizedRequest = async (url, options) => {
  const requestOptions = {
    ...options,
    headers: {...options.headers, 'Accept': 'application/json'},
    credentials: "include"
  };
  return await fetch(url, requestOptions)
    .then((response) => {
      if (response.status === 498) {
        store.dispatch({type: USER_ACTIONS.LOGOUT_USER});
      }
      return response.text();
    })
    .catch((error) => {
      console.error('The Error:', error);
      return 'FETCH CATCH ERROR :' + JSON.stringify(error);
    });
};
