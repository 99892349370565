import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Delete as DeleteIcon,
  FiberManualRecord as FiberManualRecordIcon
} from '@material-ui/icons';

import './EditLabels.scss';
import {
  Edge,
  ENTITY,
  Graph,
  GRAPH_EDGE_TYPE,
  LABEL_NODE_POSITIONS,
  Node,
  GRAPH_EDGE_TYPE_SELECTOR,
  LABEL_NODE_POSITIONS_SELECTOR,
  EDITABLE
} from '../../../types';
import { SelectorEditable, TextFieldEditable } from '../../Shared';

type EditConfig = {
  container: {
    originX: number,
    originY: number,
    zoom: number,
    height: number,
    width: number,
  },
  edgeType: GRAPH_EDGE_TYPE,
  nodes: {
    background: string,
    borderColor: string,
    borderWidth: number,
    radio: number,
    labels: Array<{ color: string, position: LABEL_NODE_POSITIONS, size: number, default: string, newValue: string, name: string }>
  },
  edges: {
    color: string,
    width: number,
    labels: Array<{ color: string, size: number, default: string, newValue: string, name: string }>
  }
}

type Props = {
  graph: Graph,
  setGraph: Function,
  editProps: { entity: string, key: number },
  setEditProps: Function
}

export default ({graph, setGraph, editProps, setEditProps}: Props) => {
  const [editGraph, setEditGraph] = useState<{ config: EditConfig, nodes: { [key: number]: Node }, edges: { [key: number]: Edge } }>(JSON.parse(JSON.stringify({
    ...graph,
    config: {
      ...graph.config,
      nodes: {
        ...graph.config.nodes,
        labels: graph.config.nodes.labels.map(label => ({...label, newValue: '_VALUE_'}))
      },
      edges: {
        ...graph.config.edges,
        labels: graph.config.edges.labels.map(label => ({...label, newValue: '_VALUE_'}))
      }
    }
  })));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const onClose = () => setEditProps({entity: ENTITY.NONE, key: 0});
  const keyEntity = editProps.entity === ENTITY.NODE ? 'nodes' : 'edges';
  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" style={{minWidth: '300px'}}>
        Editing {editProps.entity === ENTITY.NODE ? 'node' : editProps.entity === ENTITY.EDGE ? 'edge' : 'graph'}
        {editProps.entity !== ENTITY.GRAPH &&
        <Button
            variant="outlined"
            onClick={() => {
              if (editProps.entity === ENTITY.NODE) {
                const newNodes = {...graph.nodes};
                const newEdges: { [key: number]: Edge } = {};
                delete newNodes[editProps.key];
                for (const edgeKey in graph.edges) {
                  if (graph.edges[edgeKey].from !== editProps.key && graph.edges[edgeKey].to !== editProps.key) {
                    newEdges[edgeKey] = graph.edges[edgeKey];
                  }
                }
                setGraph({
                  ...graph,
                  nodes: newNodes,
                  edges: newEdges
                });
              } else {
                const newEdges = {...graph.edges};
                delete newEdges[editProps.key];
                setGraph({
                  ...graph,
                  edges: newEdges
                });
              }
              onClose();
            }}
            style={{right: '24px', position: 'absolute'}}
        >
            Delete
        </Button>}
      </DialogTitle>
      {editProps.entity === ENTITY.GRAPH ?
        <DialogContent>
          <SelectorEditable
            editable={EDITABLE}
            selectorData={GRAPH_EDGE_TYPE_SELECTOR}
            value={editGraph.config.edgeType}
            onChange={(value: { value: GRAPH_EDGE_TYPE }) => {
              const newGraph = {...editGraph};
              newGraph.config.edgeType = value.value;
              setEditGraph(newGraph);
            }}
          />
          <Typography variant="h5" gutterBottom>
            Nodes
          </Typography>
          <div className="box-name-default">
            <TextFieldEditable
              label="Radio"
              value={'' + editGraph.config.nodes.radio}
              onChange={(event: any) => {
                const newGraph = {...editGraph};
                newGraph.config.nodes.radio = Number.isNaN(event.target.value) ? 0 : +event.target.value;
                setEditGraph(newGraph);
              }}
            />
            <TextFieldEditable
              label="Background"
              value={editGraph.config.nodes.background}
              onChange={(event: any) => {
                const newGraph = {...editGraph};
                newGraph.config.nodes.background = event.target.value;
                setEditGraph(newGraph);
              }}
            />
            <FiberManualRecordIcon style={{color: editGraph.config.nodes.background}}/>
            <TextFieldEditable
              label="Border width"
              value={'' + editGraph.config.nodes.borderWidth}
              onChange={(event: any) => {
                const newGraph = {...editGraph};
                newGraph.config.nodes.borderWidth = Number.isNaN(event.target.value) ? 0 : +event.target.value;
                setEditGraph(newGraph);
              }}
            />
            <TextFieldEditable
              label="Border color"
              value={editGraph.config.nodes.borderColor}
              onChange={(event: any) => {
                const newGraph = {...editGraph};
                newGraph.config.nodes.borderColor = event.target.value;
                setEditGraph(newGraph);
              }}
            />
            <FiberManualRecordIcon style={{color: editGraph.config.nodes.borderColor}}/>
          </div>
          <Typography variant="h6" gutterBottom>
            Nodes labels
          </Typography>
          {editGraph.config.nodes.labels.map((label, index) => {
            return (
              <div className="box-name-default" key={'label-node-' + index} style={{paddingBottom: '10px'}}>
                <div>
                  <div className="box-name-default">
                    <TextFieldEditable
                      label="Name"
                      value={label.name}
                      onChange={(event: any) => {
                        const newGraph = {...editGraph};
                        newGraph.config.nodes.labels[index].name = event.target.value;
                        setEditGraph(newGraph);
                      }}
                    />
                    <TextFieldEditable
                      label="Default"
                      value={label.default}
                      onChange={(event: any) => {
                        const newGraph = {...editGraph};
                        newGraph.config.nodes.labels[index].default = event.target.value;
                        setEditGraph(newGraph);
                      }}
                    />
                    <TextFieldEditable
                      label="Value"
                      value={label.newValue}
                      onChange={(event: any) => {
                        const newGraph = {...editGraph};
                        newGraph.config.nodes.labels[index].newValue = event.target.value;
                        setEditGraph(newGraph);
                      }}
                    />
                  </div>
                  <div className="box-name-default">
                    <TextFieldEditable
                      label="Text size"
                      value={'' + label.size}
                      onChange={(event: any) => {
                        const newGraph = {...editGraph};
                        newGraph.config.nodes.labels[index].size = Number.isNaN(event.target.value) ? 0 : +event.target.value;
                        setEditGraph(newGraph);
                      }}
                    />
                    <TextFieldEditable
                      label="Text color"
                      value={label.color}
                      onChange={(event: any) => {
                        const newGraph = {...editGraph};
                        newGraph.config.nodes.labels[index].color = event.target.value;
                        setEditGraph(newGraph);
                      }}
                    />
                    <FiberManualRecordIcon style={{color: label.color}}/>
                    <SelectorEditable
                      editable={EDITABLE}
                      selectorData={LABEL_NODE_POSITIONS_SELECTOR}
                      value={label.position}
                      onChange={(value: { value: LABEL_NODE_POSITIONS }) => {
                        const newGraph = {...editGraph};
                        newGraph.config.nodes.labels[index].position = value.value;
                        setEditGraph(newGraph);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <IconButton
                    aria-label="delete-node-label"
                    onClick={() => {
                      const newGraph = {...editGraph};
                      newGraph.config.nodes.labels = newGraph.config.nodes.labels.filter((l: {}, i: number) => index !== i);
                      for (const key in newGraph.nodes) {
                        newGraph.nodes[key].labels = newGraph.nodes[key].labels.filter((l: {}, i: number) => index !== i);
                      }
                      setEditGraph(newGraph);
                    }}>
                    <DeleteIcon/>
                  </IconButton>
                </div>
              </div>
            );
          })}
          <div>
            <IconButton aria-label="add" onClick={() => {
              const newGraph = {...editGraph};
              newGraph.config.nodes.labels.push({
                color: '#000',
                position: LABEL_NODE_POSITIONS.CENTER,
                default: '_INDEX_',
                newValue: '_VALUE_',
                name: 'index',
                size: 20
              });
              for (const key in newGraph.nodes) {
                newGraph.nodes[key].labels.push({
                  value: '_INDEX_'
                });
              }
              setEditGraph(newGraph);
            }}>
              <AddCircleOutlineIcon/>
            </IconButton>
          </div>
          <Typography variant="h5" gutterBottom>
            Edges
          </Typography>
          <div className="box-name-default">
            <TextFieldEditable
              label="Width"
              value={'' + editGraph.config.edges.width}
              onChange={(event: any) => {
                const newGraph = {...editGraph};
                newGraph.config.edges.width = Number.isNaN(event.target.value) ? 0 : +event.target.value;
                setEditGraph(newGraph);
              }}
            />
            <TextFieldEditable
              label="Color"
              value={editGraph.config.edges.color}
              onChange={(event: any) => {
                const newGraph = {...editGraph};
                newGraph.config.edges.color = event.target.value;
                setEditGraph(newGraph);
              }}
            />
            <FiberManualRecordIcon style={{color: editGraph.config.edges.color}}/>
          </div>
          <Typography variant="h6" gutterBottom>
            Edges Labels
          </Typography>
          {editGraph.config.edges.labels.map((label: { name: string, default: string, newValue: string, size: number, color: string }, index: number) => {
            return (
              <div className="box-name-default" key={'label-edge-' + index} style={{paddingBottom: '10px'}}>
                <div>
                  <div className="box-name-default">
                    <TextFieldEditable
                      label="Name"
                      value={label.name}
                      onChange={(event: any) => {
                        const newGraph = {...editGraph};
                        newGraph.config.edges.labels[index].name = event.target.value;
                        setEditGraph(newGraph);
                      }}
                    />
                    <TextFieldEditable
                      label="Default"
                      value={label.default}
                      onChange={(event: any) => {
                        const newGraph = {...editGraph};
                        newGraph.config.edges.labels[index].default = event.target.value;
                        setEditGraph(newGraph);
                      }}
                    />
                    <TextFieldEditable
                      label="Value"
                      value={label.newValue}
                      onChange={(event: any) => {
                        const newGraph = {...editGraph};
                        newGraph.config.edges.labels[index].newValue = event.target.value;
                        setEditGraph(newGraph);
                      }}
                    />
                  </div>
                  <div className="box-name-default">
                    <TextFieldEditable
                      label="Text size"
                      value={'' + label.size}
                      onChange={(event: any) => {
                        const newGraph = {...editGraph};
                        newGraph.config.edges.labels[index].size = Number.isNaN(event.target.value) ? 0 : +event.target.value;
                        setEditGraph(newGraph);
                      }}
                    />
                    <TextFieldEditable
                      label="Text color"
                      value={label.color}
                      onChange={(event: any) => {
                        const newGraph = {...editGraph};
                        newGraph.config.edges.labels[index].color = event.target.value;
                        setEditGraph(newGraph);
                      }}
                    />
                    <FiberManualRecordIcon style={{color: label.color}}/>
                  </div>
                </div>
                <div>
                  <IconButton aria-label="delete" onClick={() => {
                    const newGraph = {...editGraph};
                    newGraph.config.edges.labels = newGraph.config.edges.labels.filter((l: {}, i: number) => index !== i);
                    setEditGraph(newGraph);
                  }}>
                    <DeleteIcon/>
                  </IconButton>
                </div>
              </div>
            );
          })}
          <div>
            <IconButton aria-label="add" onClick={() => {
              const newGraph = {...editGraph};
              newGraph.config.edges.labels.push({
                color: '#fff',
                default: '_INDEX_',
                newValue: '_VALUE_',
                name: 'index',
                size: 25
              });
              for (const key in newGraph.edges) {
                newGraph.edges[key].labels.push({
                  value: '_INDEX_'
                });
              }
              setEditGraph(newGraph);
            }}>
              <AddCircleOutlineIcon/>
            </IconButton>
          </div>
        </DialogContent> :
        <DialogContent>
          {editGraph[keyEntity][editProps.key].labels.map((label, index) => {
            return (
              <div key={'label-' + keyEntity + index} style={{paddingBottom: '10px'}}>
                <TextFieldEditable
                  label={editGraph.config[keyEntity].labels[index].name}
                  value={label.value}
                  onChange={(event: any) => {
                    const newGraph = {...editGraph};
                    newGraph[keyEntity][editProps.key].labels[index].value = event.target.value;
                    setEditGraph(newGraph);
                  }}
                />
              </div>
            );
          })}
        </DialogContent>
      }
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" onClick={() => {
          setGraph({
            ...editGraph,
            config: {
              ...editGraph.config,
              nodes: {
                ...editGraph.config.nodes,
                labels: editGraph.config.nodes.labels.map(label => {
                  const newLabel = {...label};
                  delete newLabel.newValue;
                  return newLabel;
                })
              },
              edges: {
                ...editGraph.config.edges,
                labels: editGraph.config.edges.labels.map(label => {
                  const newLabel = {...label};
                  delete newLabel.newValue;
                  return newLabel;
                })
              }
            },
            nodes: Object.values(editGraph.nodes)
              .reduce((nodes, node) => ({
                ...nodes,
                [node.key]: {
                  ...node,
                  labels: node.labels.map((label, index) => ({value: editGraph.config.nodes.labels[index].newValue.split('_VALUE_').join(label.value)}))
                }
              }), {}),
            edges: Object.values(editGraph.edges)
              .reduce((edges, edge) => ({
                ...edges,
                [edge.key]: {
                  ...edge,
                  labels: edge.labels.map((label, index) => ({value: editGraph.config.edges.labels[index].newValue.split('_VALUE_').join(label.value)}))
                }
              }), {})
          });
          onClose();
        }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
