import { connect } from 'react-redux';

import Status from '../../../components/Judge/Status';
import { Message } from '../../../store/types';
import { addNotice } from '../../../store/actions';
import { RootStateType } from '../../../store/types';

export const mapStateToProps = (state: RootStateType) => {
  return {
    user: state.user
  }
};

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    addNotice: (notice: Message) => {
      dispatch(addNotice(notice));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Status);

