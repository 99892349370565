import React from 'react';
import { Button, Grid, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';

import ProblemViewer from './ProblemViewer';
import { APP_ROUTES, ROLES } from '../../../../config/constans';
import { HeaderBar } from '../../../../containers/Shared';
import { ProblemViewerProps } from '../../../../containers/Judge/Problem';
import { Delete as DeleteIcon, Edit as EditIcon, Queue as QueueIcon } from '@material-ui/icons';
import { Modal } from '../../../Shared/Modal';

export default ({user, problem, deleteProblem}: ProblemViewerProps) => {
  const actions = [];
  if (user.isLogged) {
    actions.push(
      <Link to={APP_ROUTES.ROOT.JUDGE.PROBLEM.SUBMIT(problem.id)}>
        <Button variant="outlined" key="add-user-action">
          Submit Attempt
        </Button>
      </Link>
    );
  }
  if (user.role === ROLES.SUPER_ADMIN || user.role === ROLES.ADMIN) {
    actions.push(
      <Link to={APP_ROUTES.ROOT.JUDGE.PROBLEM.EDIT(problem.id)}>
        <IconButton title={'Edit Problem'}><EditIcon/></IconButton>
      </Link>,
      <Link to={APP_ROUTES.ROOT.JUDGE.PROBLEM.TESTS(problem.id)}>
        <IconButton title={'Edit Tests'}><QueueIcon/></IconButton>
      </Link>,
      <Modal
        button={<IconButton title={'Delete Problem'}><DeleteIcon/></IconButton>}
        title="Deleting Code"
        actions={[
          <Button key="delete-source-action" onClick={() => deleteProblem()}>
            Delete
          </Button>]}>
        <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
          <Grid item>
            Are you sure to delete it?
          </Grid>
        </Grid>
      </Modal>
    );
  }
  return (
    <>
      <HeaderBar title="View Problem" back={APP_ROUTES.ROOT.JUDGE.PROBLEM.LIST} actions={actions}/>
      <ProblemViewer problem={problem}/>
    </>
  );
}
