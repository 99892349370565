import { SCOPE_VISIBILITY } from '../../config/constans';
import { Graph } from '../../types';

export interface NewGraphState {
  name: string,
  description: string,
  type: SCOPE_VISIBILITY,
  graph: Array<Graph>,
  tags: Array<string>
}

export interface GraphState {
  date: Date,
  id: string,
  name: string,
  description: string,
  ownerNickname: string,
  type: SCOPE_VISIBILITY,
  graph: Array<Graph>,
  tags: Array<string>,
  password: string
}

export interface GraphMetaState {
  date: Date,
  id: string,
  ownerNickname: string,
  type: SCOPE_VISIBILITY,
  tags: Array<string>
}

export enum GRAPHS_ACTIONS {
  UPDATE_GRAPH = 'UPDATE_GRAPH',
  REPLACE_GRAPHS = 'REPLACE_GRAPHS',
  UPDATE_PASSWORD_GRAPH = 'UPDATE_PASSWORD_GRAPH',
}

interface UpdateGraphAction {
  type: GRAPHS_ACTIONS.UPDATE_GRAPH,
  graph: GraphState,
}

interface ReplaceGraphsAction {
  type: GRAPHS_ACTIONS.REPLACE_GRAPHS,
  graphs: Array<GraphMetaState>,
}

interface UpdatePasswordGraphsAction {
  type: GRAPHS_ACTIONS.UPDATE_PASSWORD_GRAPH,
  graphId: string,
  password: string,
}

export type GraphsActionTypes = UpdateGraphAction | ReplaceGraphsAction | UpdatePasswordGraphsAction;
