import React from 'react';
import {
  People as PeopleIcon,
  Create as CreateIcon,
  Settings as SettingsIcon
} from '@material-ui/icons';

import NavBarList from './NavBarList';
import { NavbarItem } from '../../../types/navbar';
import { APP_ROUTES } from '../../../config/constans';

export default ({visible}: { visible: boolean }): JSX.Element => {
  const items: Array<NavbarItem> = [
    {
      icon: <CreateIcon/>,
      title: 'Blog',
      link: APP_ROUTES.ROOT.ADMIN.BLOG
    },
    {
      icon: <PeopleIcon/>,
      title: 'Roles and Users',
      link: APP_ROUTES.ROOT.ADMIN.USERS
    }
  ];
  const head = {
    icon: <SettingsIcon/>,
    title: 'Admin'
  };
  return <NavBarList visible={visible} head={head} items={items}/>;
}
