export enum LABEL_NODE_POSITIONS {
  CENTER = 'CENTER',
  UP = 'UP',
  RIGHT = 'RIGHT',
  DOWN = 'DOWN',
  LEFT = 'LEFT'
}

export const PRINT_LABEL_NODE_POSITIONS = {
  [LABEL_NODE_POSITIONS.CENTER]: 'Center',
  [LABEL_NODE_POSITIONS.UP]: 'Up',
  [LABEL_NODE_POSITIONS.RIGHT]: 'Right',
  [LABEL_NODE_POSITIONS.DOWN]: 'Down',
  [LABEL_NODE_POSITIONS.LEFT]: 'Left'
};

export enum GRAPH_EDGE_TYPE {
  DIRECTED = 'DIRECTED',
  NO_DIRECTED = 'NO_DIRECTED'
}

export const PRINT_GRAPH_EDGE_TYPE = {
  [GRAPH_EDGE_TYPE.DIRECTED]: 'Directed',
  [GRAPH_EDGE_TYPE.NO_DIRECTED]: 'No directed',
};

export enum ENTITY {
  GRAPH = 'GRAPH',
  NODE = 'NODE',
  EDGE = 'EDGE',
  NONE = 'NONE'
}

type Config = {
  edgeType: GRAPH_EDGE_TYPE,
  nodes: {
    background: string,
    borderColor: string,
    borderWidth: number,
    radio: number,
    labels: Array<{ color: string, position: LABEL_NODE_POSITIONS, size: number, default: string, name: string }>
  },
  edges: {
    color: string,
    width: number,
    labels: Array<{ color: string, size: number, default: string, name: string }>
  }
}

type LabelNode = {
  value: string,
}

export type Node = {
  key: number,
  x: number,
  y: number,
  labels: Array<LabelNode>
}

type LabelEdge = {
  value: string,
}

export type Edge = {
  key: number,
  from: number, // node key
  to: number, // node key
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  labels: Array<LabelEdge>
}

export type Graph = {
  config: Config,
  nodes: { [key: number]: Node },
  edges: { [key: number]: Edge },
}
