import React from 'react';
import {
  SportsMma as SportsMmaIcon,
  Group as GroupIcon,
  Dehaze as DehazeIcon,
  MenuBook as MenuBookIcon
} from '@material-ui/icons';

import NavBarList from './NavBarList';
import { APP_ROUTES } from '../../../config/constans';

export default ({visible}: { visible: boolean }) => {
  const items = [
    {
      icon: <MenuBookIcon/>,
      title: 'Category',
      link: APP_ROUTES.ROOT.TRAIN.CATEGORY.LIST
    },
    {
      icon: <DehazeIcon/>,
      title: 'Difficulty',
      link: APP_ROUTES.ROOT.TRAIN.DIFFICULTY
    },
    {
      icon: <GroupIcon/>,
      title: 'Past Contests',
      link: APP_ROUTES.ROOT.TRAIN.CONTEST
    },
    {
      icon: <GroupIcon/>,
      title: 'Rooms',
      link: APP_ROUTES.ROOT.TRAIN.ROOM.LIST
    }
  ];
  const head = {
    icon: <SportsMmaIcon/>,
    title: 'Train'
  };
  return <NavBarList visible={visible} head={head} items={items}/>;
}
