import React, { useState } from 'react';
import { Collapse, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import {
  ExpandLess, ExpandMore
} from '@material-ui/icons';
import { Link } from 'react-router-dom';

import { useStyles } from '../../../config/styles';
import { NavbarItem } from '../../../types/navbar';

type Props = {
  visible: boolean,
  items: Array<NavbarItem>,
  head: { icon: JSX.Element, title: string }
}

export default ({visible, items, head}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  return (
    <>
      <ListItem
        button
        onClick={() => {
          // if (visible) {
            setOpen(prevState => !prevState);
          // }
        }}
        component={'div'}
        className={classes.topList}>
        <ListItemIcon>{head.icon}</ListItemIcon>
        {visible ? <>
          <ListItemText primary={head.title} className={classes.title}/>
          <ListItemIcon>
            {open ? <ExpandLess/> : <ExpandMore/>}
          </ListItemIcon>
        </> : null}
      </ListItem>
      <Collapse component="ul" in={open} timeout="auto" unmountOnExit className={classes.drawerItem}>
        {items.map((item: NavbarItem, index: Number) => item.link ?
          <Link
            to={{pathname: item.link}}
            onClick={(event) => (visible ? null : event.preventDefault())}
            key={item.link + '-' + index}
          >
            <ListItem button component={'li'}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              {visible ? <ListItemText primary={item.title}/> : null}
            </ListItem>
          </Link> :
          <ListItem
            button
            component={'li'}
            key={item.link + '-' + index}
            onClick={item.onClick ? item.onClick : undefined}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            {visible ? item.title : null}
          </ListItem>
        )}
      </Collapse>
    </>
  );
}
