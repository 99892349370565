import { GraphMetaState, GRAPHS_ACTIONS, GraphsActionTypes, GraphState, NewGraphState } from '../types/graphs';
import {
  serverCanUpdateGraph,
  serverChangePasswordGraph, serverCreateNewGraph,
  serverDeleteGraph, serverGetGraph, serverGetGraphs, serverUpdateGraph
} from '../../config/services/backend';
import { OK, ALERT_TYPES } from '../../types';
import { addNotice } from './notices';
import { history } from '../reducers';
import { addBlocking, addLoading, subBlocking, subLoading } from './flags';
import { APP_ROUTES } from '../../config/constans';

export const updateGraph = (graph: GraphState): GraphsActionTypes => {
  return {
    type: GRAPHS_ACTIONS.UPDATE_GRAPH,
    graph
  }
};

export const replaceGraphs = (graphs: Array<GraphMetaState>): GraphsActionTypes => {
  return {
    type: GRAPHS_ACTIONS.REPLACE_GRAPHS,
    graphs
  }
};

export const updatePasswordGraph = (graphId: string, password: string): GraphsActionTypes => {
  return {
    type: GRAPHS_ACTIONS.UPDATE_PASSWORD_GRAPH,
    graphId,
    password
  }
};

export const createNewGraph = (newGraph: NewGraphState) => {
  return async (dispatch: Function) => {
    dispatch(addBlocking());
    dispatch(addLoading());
    const request = await serverCreateNewGraph(newGraph);
    if (request.ok === OK) {
      dispatch(updateGraph({
        ...request.result,
        graph: request.result.graph.map(graph => ({
          ...graph,
          nodes: graph.nodes || {},
          edges: graph.edges || {}
        }))
      }));
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `Graph ${request.result.name} created`}));
      history.push(APP_ROUTES.ROOT.GRAPH.VIEW(request.result.id));
    } else {
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
    dispatch(subLoading());
    dispatch(subBlocking());
  }
};

export const getGraph = (graphId: string, blocking: boolean) => {
  return async (dispatch: Function) => {
    if (blocking) {
      dispatch(addBlocking());
    }
    dispatch(addLoading());
    const request = await serverGetGraph(graphId);
    if (request.ok === OK) {
      dispatch(updateGraph({
        ...request.result,
        graph: request.result.graph.map(graph => ({
          ...graph,
          nodes: graph.nodes || {},
          edges: graph.edges || {}
        }))
      }));
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `Graph ${request.result.name} loaded`}));
    } else {
      history.push(APP_ROUTES.ROOT.GRAPH.LIST);
      dispatch(addNotice({type: ALERT_TYPES.INFO, label: 'Redirect to list'}));
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
    dispatch(subLoading());
    if (blocking) {
      dispatch(subBlocking());
    }
  }
};

export const putGraph = (graph: GraphState) => {
  return async (dispatch: Function) => {
    dispatch(addBlocking());
    dispatch(addLoading());
    const request = await serverUpdateGraph(graph);
    if (request.ok === OK) {
      dispatch(updateGraph(graph));
      history.push(APP_ROUTES.ROOT.GRAPH.VIEW(graph.id));
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `Graph ${graph.name} updated`}));
    } else {
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
    dispatch(subLoading());
    dispatch(subBlocking());
  }
};

export const changePasswordGraph = (graphId: string, password: string) => {
  return async (dispatch: any) => {
    dispatch(addLoading());
    const request = await serverChangePasswordGraph(graphId, password);
    if (request.ok === OK) {
      dispatch(getGraph(graphId, false));
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `Password changed`}));
    } else {
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
    dispatch(subLoading());
  }
};

export const deleteGraph = (graphId: string) => {
  return async (dispatch: any) => {
    dispatch(addBlocking());
    dispatch(addLoading());
    const request = await serverDeleteGraph(graphId);
    if (request.ok === OK) {
      history.push(APP_ROUTES.ROOT.GRAPH.LIST);
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `Graph ${graphId} deleted`}));
    } else {
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
    dispatch(subLoading());
    dispatch(subBlocking());
  }
};

export const editGraph = (graphId: string, password: string) => {
  return async (dispatch: any) => {
    dispatch(addBlocking());
    dispatch(addLoading());
    const request = await serverCanUpdateGraph(graphId, password);
    if (request.ok === OK) {
      dispatch(updatePasswordGraph(graphId, password));
    } else {
      history.push(APP_ROUTES.ROOT.GRAPH.VIEW(graphId));
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
    dispatch(subLoading());
    dispatch(subBlocking());
  }
};

export const getAllGraphs = () => {
  return async (dispatch: any) => {
    dispatch(addLoading());
    const request = await serverGetGraphs();
    if (request.ok === OK) {
      dispatch(replaceGraphs(request.result));
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `Get all graphs`}));
    } else {
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
    dispatch(subLoading());
  }
};
