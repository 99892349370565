import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';

import ViewerPage from './Train/Material/ViewerPage/ViewerPage';
import Account from './Account/Account';
import User from './Account/User';
import Room from './Train/Rooms/Room/Room';
import Rooms from './Train/Rooms/List/Rooms';
import Category from './Train/Category/Category';
import Page from './Train/Category/Page';
import Users from './Admin/Users';
import Status from './Judge/Status/Status';
import ViewAttempt from './Judge/Status/ViewAttempt';
import { APP_ROUTES, secRoutes } from '../config/constans';
import { isSuperAdmin } from '../helpers/functions';
import Contests from './Train/Contests/Contests';
import { ProblemEditor, ProblemSubmit, ProblemNew, ProblemTests } from './Judge/Problem';
import Rank from './Judge/Rank/Rank';
import { GraphList, GraphNew, GraphViewer, GraphEditor } from './Graph';
import { RootStateType, UserState } from '../store/types';

const PrivateRoutes = (props: { user: UserState, location: any }) => {
  const {user} = props;
  if (user.isLogged) {
    return <Switch>
      {/*ADMIN*/}
      {isSuperAdmin(user) ? <Route exact path={`/admin/users`} component={Users}/> : null}
      {/*JUDGE*/}
      <Route exact path={APP_ROUTES.ROOT.JUDGE.PROBLEM.NEW} component={ProblemNew}/>
      <Route exact path={APP_ROUTES.ROOT.JUDGE.PROBLEM.EDIT(':problemId')} component={ProblemEditor}/>
      <Route exact path={APP_ROUTES.ROOT.JUDGE.PROBLEM.TESTS(':problemId')} component={ProblemTests}/>
      <Route exact path={APP_ROUTES.ROOT.JUDGE.PROBLEM.SUBMIT(':problemId')} component={ProblemSubmit}/>
      <Route exact path={APP_ROUTES.ROOT.JUDGE.RANK.LIST} component={Rank}/>
      <Route exact path={APP_ROUTES.ROOT.JUDGE.STATUS.LIST} component={Status}/>
      <Route exact path={APP_ROUTES.ROOT.JUDGE.ATTEMPT.VIEW(':attemptId')} component={ViewAttempt}/>
      {/*TRAIN*/}
      <Route exact path={APP_ROUTES.ROOT.TRAIN.CATEGORY.LIST} component={Category}/>
      <Route exact path={APP_ROUTES.ROOT.TRAIN.CATEGORY.TAG(':tag')} component={Page}/>
      <Route exact path={APP_ROUTES.ROOT.TRAIN.ROOM.LIST} component={Rooms}/>
      <Route exact path={APP_ROUTES.ROOT.TRAIN.ROOM.VIEW(':id')} component={Room}/>
      <Route exact path={APP_ROUTES.ROOT.TRAIN.CONTEST} component={Contests}/>
      <Route exact path={`/train/material/:${secRoutes.join('?/:')}?`} component={ViewerPage}/>
      {/*GRAPH*/}
      <Route exact path={APP_ROUTES.ROOT.GRAPH.LIST} component={GraphList}/>
      <Route exact path={APP_ROUTES.ROOT.GRAPH.NEW} component={GraphNew}/>
      <Route exact path={APP_ROUTES.ROOT.GRAPH.EDIT(':graphId')} component={GraphEditor}/>
      <Route exact path={APP_ROUTES.ROOT.GRAPH.VIEW(':graphId')} component={GraphViewer}/>
      {/*ACCOUNT*/}
      <Route exact path={APP_ROUTES.ROOT.PROFILE.SELF} component={Account}/>
      <Route exact path={APP_ROUTES.ROOT.PROFILE.USER(':userNickname')} component={User}/>
      {/*ROOT*/}
      <Redirect from={`*`} to={`/`}/>
    </Switch>
  }
  return (
    <Redirect
      to={{
        pathname: '/login',
        state: {from: props.location}
      }}
    />
  );
};

export const mapStateToProps = (state: RootStateType) => {
  return {
    user: state.user
  }
};

export default connect(mapStateToProps)(PrivateRoutes);

