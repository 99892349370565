import React, { useCallback, useState } from 'react';
import { Button } from '@material-ui/core';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import socketIOClient from 'socket.io-client';

import { serverSubmitProblemAttempt } from '../../../../config/services/backend';
import { useStyles } from '../../../../config/styles';
import { history } from '../../../../store/reducers';
import {
  LANGUAGE_TYPE_SELECTOR,
  EDITABLE,
  LANGUAGE_TYPES,
  ALERT_TYPES,
  OK
} from '../../../../types';
import { AceEditor, SelectorEditable } from '../../../Shared';
import { TitleBox } from '../../../Shared';
import { HeaderBar } from '../../../../containers/Shared';
import { APP_ROUTES } from '../../../../config/constans';
import { URL } from '../../../../config/services/server.config';

interface Props {
  // dispatch
  addNotice: Function
}

export default ({addNotice}: Props) => {
  const params = useParams<{ problemId: string }>();
  const [code, setCode] = useState<string>('');
  const [lang, setLang] = useState<LANGUAGE_TYPES>(LANGUAGE_TYPES.CPP);
  const submitProblem = useCallback(async () => {
    if (code) {
      const request = await serverSubmitProblemAttempt({
        belong: {problem: +params.problemId},
        language: lang,
        source: code
      });
      if (request.ok === OK) {
        history.push(APP_ROUTES.ROOT.JUDGE.STATUS.LIST);
        const socket = socketIOClient(`${URL}/attempt/${request.result.attemptId}`);
        socket.on('verdict', (data: any) => {
          addNotice({type: ALERT_TYPES.INFO, label: data.verdict});
          socket.close();
        });
        setTimeout(() => {
          socket.close();
        }, 1000 * 60);
        addNotice({type: ALERT_TYPES.SUCCESS, label: 'Successfully submit'})
      } else {
        addNotice({type: ALERT_TYPES.ERROR, label: request.message})
      }
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: 'Code Empty!'})
    }
  }, [addNotice, code, lang, params.problemId]);

  const actions = [
    <Link to={APP_ROUTES.ROOT.JUDGE.PROBLEM.VIEW(+params.problemId)}>
      <Button variant="outlined" key="view-problem-action">
        View Problem
      </Button>
    </Link>,
    <Button variant="outlined" key="submit-problem-action" onClick={submitProblem}>
      Submit!
    </Button>
  ];
  const classes = useStyles();
  return (
    <>
      <HeaderBar title={`Submit Problem ${params.problemId}`} actions={actions}/>
      <div className={clsx(classes.pageMaxHeight, classes.boxShadow)}>
        <TitleBox
          title={
            <div>
              <SelectorEditable
                editable={EDITABLE}
                selectorData={LANGUAGE_TYPE_SELECTOR}
                value={lang}
                onChange={(value: { value: LANGUAGE_TYPES }) => setLang(value.value)}
              />
            </div>
          }
          body={
            <div style={{height: 'calc(100% - 50px)'}}>
              <AceEditor
                value={code}
                language={lang}
                onChange={(value: string) => setCode(value)}/>
            </div>
          }
        />
      </div>
    </>
  );
}
