import React from 'react';

import { Alert } from '../';
import { NoticeState } from '../../../store/types';

export const NoticesComponent = ({notices}: { notices: Array<NoticeState> }) => {
  return (
    <div style={{
      position: 'fixed',
      left: 0,
      zIndex: 99999,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column'
    }}>
      {notices.map((notice: NoticeState, index: number) => (
        <div style={{padding: '5px'}} key={index}>
          <Alert
            type={notice.type}
            label={notice.label}
          />
        </div>)
      )}
    </div>
  );
};
