import React, { useCallback, useEffect, useState } from 'react';
import { Button, IconButton } from '@material-ui/core';

import GraphViewer from './GraphViewer';
import { Graph, GRAPH_EDGE_TYPE, LABEL_NODE_POSITIONS } from '../../../types/graph';
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Delete as DeleteIcon,
  Queue as QueueIcon,
  CenterFocusStrong as CenterFocusStrongIcon
} from '@material-ui/icons';
import { useStyles } from '../../../config/styles';

type Props = {
  graphs: Array<Graph>,
  setGraphs?: Function
}
export default ({graphs, setGraphs}: Props) => {
  const calcMaxBox = useCallback((graphs: Array<Graph>) => {
    return graphs.reduce((allResult, graph) => {
      return Object.values(graph.nodes).reduce((result, node) => {
        return {
          xLeft: Math.min(result.xLeft, node.x),
          xRight: Math.max(result.xRight, node.x),
          yTop: Math.min(result.yTop, node.y),
          yBottom: Math.max(result.yBottom, node.y)
        }
      }, allResult);
    }, {
      xLeft: Number.MAX_SAFE_INTEGER,
      xRight: -Number.MAX_SAFE_INTEGER,
      yTop: Number.MAX_SAFE_INTEGER,
      yBottom: -Number.MAX_SAFE_INTEGER
    });
  }, []);
  const [maxBox, setMaxBox] = useState(calcMaxBox(graphs));
  const [activeGraph, setActiveGraph] = useState<number>(0);
  useEffect(() => {
    if (!graphs[activeGraph]) {
      setActiveGraph(0);
    }
  }, [activeGraph, graphs]);
  const handleNext = () => {
    setActiveGraph((prevActiveGraph) => prevActiveGraph + 1);
  };
  const handleBack = () => {
    setActiveGraph((prevActiveGraph) => prevActiveGraph - 1);
  };
  const points = [];
  const classes = useStyles();
  for (let i = 0; i < graphs.length; i++) {
    points.push(
      <div
        className={classes.backgroundLight}
        style={{
          borderRadius: '50%',
          height: '20px',
          width: '20px',
          margin: '5px auto',
          opacity: i === activeGraph ? 'unset' : '0.1'
        }}
        onClick={() => {
          setActiveGraph(i);
        }}
        key={i}
      />
    );
  }
  return (
    <div style={{height: '100%'}}>
      <div style={{height: 'calc(100% - 30px)'}}>
        {graphs[activeGraph] &&
        <GraphViewer
            graph={graphs[activeGraph]}
            setGraph={setGraphs ? (newGraph: Graph) => {
              const newGraphs = [...graphs];
              newGraphs[activeGraph] = newGraph;
              setGraphs(newGraphs);
            } : undefined}
            maxBox={maxBox}
        />}
      </div>
      <div style={{height: '30px', display: 'flex'}} className={classes.backgroundDarkest}>
        <IconButton aria-label="delete" onClick={() => setMaxBox(calcMaxBox(graphs))}>
          <CenterFocusStrongIcon/>
        </IconButton>
        <Button
          onClick={handleBack}
          disabled={activeGraph === 0}
          style={{height: '30px', width: '80px'}}>
          <KeyboardArrowLeftIcon/>
          Back
        </Button>
        <div style={{width: `calc(100% - ${setGraphs ? 210 : 160}px)`, display: 'flex'}}>
          {points}
        </div>
        <Button
          onClick={handleNext}
          disabled={activeGraph === graphs.length - 1}
          style={{height: '30px', width: '80px'}}>
          Next
          <KeyboardArrowRightIcon/>
        </Button>
        {setGraphs && <div>
            <IconButton aria-label="delete" onClick={() => {
              const stamp = JSON.parse(JSON.stringify(graphs[activeGraph]));
              const newGraphs = [];
              let pushed = false;
              for (let i = 0; i <= graphs.length; i++) {
                if (i === activeGraph + 1) {
                  newGraphs.push(stamp);
                  pushed = true
                } else {
                  if (pushed) {
                    newGraphs.push(graphs[i - 1]);
                  } else {
                    newGraphs.push(graphs[i]);
                  }
                }
              }
              setGraphs(newGraphs);
              handleNext();
            }}>
                <QueueIcon/>
            </IconButton>
        </div>}
        {setGraphs && <div>
            <IconButton aria-label="delete" onClick={() => {
              if (graphs.length === 1) {
                setGraphs([
                  {
                    nodes: {}, edges: {},
                    config: {
                      nodes: {
                        background: 'white',
                        borderColor: 'black',
                        borderWidth: 2,
                        radio: 25,
                        labels: [
                          {
                            color: 'black',
                            position: LABEL_NODE_POSITIONS.CENTER,
                            default: '_INDEX_',
                            size: 20,
                            name: 'index'
                          }
                        ]
                      },
                      edgeType: GRAPH_EDGE_TYPE.NO_DIRECTED,
                      edges: {
                        color: 'black',
                        width: 3,
                        labels: []
                      }
                    }
                  }
                ]);
              } else {
                const newGraphs = [];
                for (let i = 0; i < graphs.length; i++) {
                  if (i !== activeGraph) {
                    newGraphs.push(graphs[i]);
                  }
                }
                if (activeGraph === graphs.length - 1) {
                  handleBack()
                }
                setGraphs(newGraphs);
              }
            }}>
                <DeleteIcon/>
            </IconButton>
        </div>}
      </div>
    </div>
  );
}
