import React from 'react';

import EnhancedTable from '../../Shared/TableDefault';
import { HeaderBar } from '../../../containers/Shared';

export default () => {
  return (
    <>
      <HeaderBar title="Contests" actions={[]}/>
      <EnhancedTable/>
    </>
  );
}
