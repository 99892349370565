import React, { useCallback, useEffect, useState } from 'react';
import { Chip, Grid, IconButton, Typography } from '@material-ui/core';
import { useParams } from 'react-router';
import {
  AddCircle as AddCircleIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon
} from '@material-ui/icons';
import { Link } from 'react-router-dom';

import './ListSubPages.scss';
import AddCategory from '../../../../containers/Train/Category/AddCategory';
import EditCategory from '../Category/EditCategory';
import DeleteCategory from '../Category/DeleteCategory';
import {
  serverDeleteCategory,
  serverGetPage,
  serverGetPages,
  serverUpdateCategory
} from '../../../../config/services/backend';
import { OK, ALERT_TYPES, PAGE_STATUS, PageStateType } from '../../../../types';
import { APP_ROUTES, ROLES } from '../../../../config/constans';
import { UserState } from '../../../../store/types';

type Props = {
  // state
  user: UserState,
  // dispatch
  addNotice: Function
}

export default ({user, addNotice}: Props) => {
  const params = useParams<{ tag: string }>();
  const [categories, setCategories] = useState<Array<PageStateType>>([]);
  const [category, setCategory] = useState<PageStateType>({
    date: new Date(),
    title: '',
    tag: '',
    parent: '',
    description: '',
    status: PAGE_STATUS.PRIVATE,
    ownerNickname: ''
  });
  const getData = useCallback(async () => {
    const request = await serverGetPages(params.tag);
    if (request.ok === OK) {
      setCategories(request.result.map((result: any) => ({
        date: result.date,
        title: result.title,
        tag: result.tag,
        parent: result.parent,
        description: result.description,
        status: result.status,
        ownerNickname: result.ownerNickname
      })));
      addNotice({type: ALERT_TYPES.SUCCESS, label: `Sub categories loaded`});
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: request.message});
    }
    const pageRequest = await serverGetPage(params.tag);
    if (pageRequest.ok === OK) {
      setCategory({
        date: pageRequest.result.date,
        title: pageRequest.result.title,
        tag: pageRequest.result.tag,
        parent: pageRequest.result.parent,
        description: pageRequest.result.description,
        status: pageRequest.result.status,
        ownerNickname: pageRequest.result.ownerNickname
      });
      addNotice({type: ALERT_TYPES.SUCCESS, label: `Category loaded`});
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: pageRequest.message});
    }
  }, [addNotice, params.tag]);
  const editCategory = async (category: PageStateType, oldCategory: PageStateType) => {
    const request = await serverUpdateCategory({
      title: category.title,
      tag: category.tag,
      parent: category.parent,
      description: category.description,
      status: category.status
    }, oldCategory.tag);
    if (request.ok === OK) {
      await getData();
      addNotice({type: ALERT_TYPES.SUCCESS, label: `Category ${category.title} modified`});
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: request.message});
    }
  };
  const deleteCategory = async (category: PageStateType) => {
    const request = await serverDeleteCategory(category.tag);
    if (request.ok === OK) {
      await getData();
      addNotice({type: ALERT_TYPES.SUCCESS, label: `Category ${category.title} deleted`});
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: request.message});
    }
  };
  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="flex-start"
    >
      <Grid item>
        <Typography variant="h5">
          <Link
            to={category.parent === 'root' ? APP_ROUTES.ROOT.TRAIN.CATEGORY.LIST : APP_ROUTES.ROOT.TRAIN.CATEGORY.TAG(category.parent)}>
            <IconButton aria-label="add-category">
              <ArrowBackIcon/>
            </IconButton>
          </Link>
          {category.title}
        </Typography>
        <div className="list-container">
          <div className="item-list-description">
            {category.description}
          </div>
        </div>
      </Grid>
      <Grid item>
        <Typography variant="h6">
          Index
        </Typography>
        <div className="list-container">
          {categories.map((category, index) => {
            return <div className="item-list-box">
              <div key={category.tag} className="item-list">
                <div>
                  <Typography variant="subtitle1">
                    {index + 1}.
                  </Typography>
                </div>
                <div>
                  <Link to={APP_ROUTES.ROOT.TRAIN.CATEGORY.TAG(category.tag)}>
                    <Typography variant="subtitle1">
                      {category.title}
                    </Typography>
                  </Link>
                </div>
                <div>
                  <Typography gutterBottom variant="caption" display="block">
                    {category.status}
                  </Typography>
                </div>
                <div>
                  <Chip
                    size={'small'}
                    label={category.tag}
                  />
                </div>
                <div>
                  <EditCategory
                    button={<IconButton><EditIcon/></IconButton>}
                    category={category}
                    action={editCategory}
                  />
                </div>
                <div>
                  <DeleteCategory
                    button={<IconButton><DeleteIcon/></IconButton>}
                    category={category}
                    action={deleteCategory}
                  />
                </div>
              </div>
              <div className="item-list-description">
                {category.description}
              </div>
            </div>
          })}
          {(user.role === ROLES.SUPER_ADMIN || user.role === ROLES.ADMIN) && <Grid item>
              <AddCategory button={<IconButton aria-label="add-category">
                <AddCircleIcon fontSize="small"/>
              </IconButton>} getData={getData} parent={params.tag}/>
          </Grid>}
        </div>
      </Grid>
    </Grid>
  );
}
