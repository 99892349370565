import {ALERT_TYPES} from '../../types';

export interface NoticeState {
  index: number,
  type: ALERT_TYPES,
  label: string
}

export interface Message {
  type: ALERT_TYPES.ERROR | ALERT_TYPES.INFO | ALERT_TYPES.WARNING | ALERT_TYPES.SUCCESS,
  label: string
}

export enum NOTICES_ACTIONS {
  ADD_NOTICE = 'ADD_NOTICE',
  REMOVE_NOTICE = 'REMOVE_NOTICE'
}

interface AddNoticeAction {
  type: NOTICES_ACTIONS.ADD_NOTICE,
  notice: NoticeState,
}

interface RemoveNoticeAction {
  type: NOTICES_ACTIONS.REMOVE_NOTICE,
  index: number,
}

export type NoticesActionTypes = AddNoticeAction | RemoveNoticeAction;
