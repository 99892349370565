import React from 'react';
import { useStyles } from '../../../config/styles';

import './TitleBox.scss';

type Props = {
  title: JSX.Element,
  body: JSX.Element
}
export const TitleBox = ({title, body}: Props) => {
  const classes = useStyles();
  return (
    <div className={'title-box ' + classes.boxShadow}>
      <div className={'title-box-title ' + classes.backgroundDarkest}>
        {title}
      </div>
      {body}
    </div>
  );
}
