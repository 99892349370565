import { connect } from 'react-redux';

import Login from '../../components/Login/Login';
import { toReturnPath } from '../../helpers/functions';
import { RootStateType } from '../../store/types';

const mapStateToProps = (state: RootStateType) => {
  return {
    user: state.user,
    toReturn: toReturnPath(state)
  }
};

export default connect(mapStateToProps)(Login);

