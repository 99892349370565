import { USERS_ACTIONS, UsersActionTypes, UserAccountState } from '../types/users';

export const users = (state = {}, action: UsersActionTypes): { [key: string]: UserAccountState } => {
  const newState: { [key: string]: UserAccountState } = {...state};
  switch (action.type) {
    case USERS_ACTIONS.UPDATE_USER:
      newState[action.user.nickname] = action.user;
      return newState;
    default:
      return state;
  }
};
