import {connect} from 'react-redux';

import ListSubPages from "../../../components/Train/Category/Section/ListSubPages";
import {Message} from "../../../store/types/notices";
import {addNotice} from "../../../store/actions";
import { RootStateType } from '../../../store/types';

export const mapStateToProps = (state: RootStateType, ownProps: any) => {
  return {
    user: state.user,
  }
};

export const mapDispatchToProps = (dispatch: Function, ownProps: any) => {
  return {
    addNotice: (notice: Message) => {
      dispatch(addNotice(notice));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListSubPages);

