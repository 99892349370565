import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Editor from '../../../components/Judge/Problem/Editor';
import { putProblem } from '../../../store/actions';
import Loader from '../../../components/HOC/Loader';
import { APP_ROUTES } from '../../../config/constans';
import { ProblemState, UserState } from '../../../store/types';
import { ROLES } from '../../../config/constans';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    user: state.user,
    problem: state.problems[ownProps.match.params.problemId]
  }
};

const mapDispatchToProps = (dispatch: Function, ownProps: any) => {
  return {
    updateProblem: async (problem: ProblemState) => {
      await dispatch(putProblem(problem));
    }
  }
};

export type ProblemEditorProps = {
  // state
  user: UserState,
  problem: ProblemState,
  // dispatch
  updateProblem: Function,
  //
  history: any,
  location: any,
  match: any
}

export const ProblemEditor = connect(mapStateToProps, mapDispatchToProps)(withRouter(Loader(Editor,
  async (props: ProblemEditorProps) => {
    if (!props.problem || (props.user.role !== ROLES.SUPER_ADMIN && props.user.role !== ROLES.ADMIN)) {
      props.history.push(APP_ROUTES.ROOT.JUDGE.PROBLEM.VIEW(props.match.params.problemId));
    }
  }, (props: ProblemEditorProps) => {
    return !!props.problem
  })));
