import React, { useState } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Delete as DeleteIcon,
  Queue as QueueIcon
} from '@material-ui/icons';

import '../New/AddProblem.scss';
import { useStyles } from '../../../../config/styles';
import {
  PROBLEM_INPUT_TYPE_SELECTOR,
  PROBLEM_OUTPUT_TYPE_SELECTOR,
  PROBLEM_STATUS_SELECTOR,
  PROBLEM_INPUT_TYPE, PROBLEM_OUTPUT_TYPE, PROBLEM_STATUS,
  EDITABLE
} from '../../../../types';
import { SelectorEditable, TextAreaEditable, TextFieldEditable } from '../../../Shared';
import ProblemViewer from '../Viewer/ProblemViewer';
import { NewProblemState, ProblemState } from '../../../../store/types';

let keySamples = 1;

type ProblemBodyEditorProps = {
  problem: ProblemState | NewProblemState,
  setProblem: Function
}

export default ({problem, setProblem}: ProblemBodyEditorProps) => {
  const EDIT = 'EDIT', PREVIEW = 'PREVIEW', EDIT_AND_PREVIEW = 'EDIT_AND_PREVIEW';
  const [side, setSide] = useState(EDIT_AND_PREVIEW);
  const classes = useStyles();
  return (
    <div className="add-problem-page">
      <div
        className="edit-side"
        style={side === PREVIEW ? {display: 'none', width: '100%'} : side === EDIT ? {width: '100%'} : undefined}
      >
        <div style={{display: 'inline-flex'}}>
          <div>
            <TextFieldEditable
              label="Problem Name"
              value={problem.name}
              onChange={({target}: React.ChangeEvent<HTMLInputElement>) => setProblem((prevState: ProblemState) => ({
                ...prevState,
                name: target.value
              }))}
              rowsMax={1}
            />
          </div>
          <div>
            <SelectorEditable
              editable={EDITABLE}
              selectorData={PROBLEM_STATUS_SELECTOR}
              value={problem.status}
              onChange={(value: { value: PROBLEM_STATUS }) => setProblem((prevState: ProblemState) => ({
                ...prevState,
                status: value.value
              }))}
            />
          </div>
        </div>
        <div style={{display: 'inline-flex'}}>
          <div>
            <TextFieldEditable
              label="Limit ms"
              value={'' + problem.timeLimitPerTest}
              onChange={({target}: React.ChangeEvent<HTMLInputElement>) => setProblem((prevState: ProblemState) => ({
                ...prevState,
                timeLimitPerTest: !Number.isNaN(+target.value) ? +target.value : 0
              }))}
              rowsMax={1}
            />
          </div>
          <div>
            <TextFieldEditable
              label="Limit kB"
              value={'' + problem.memoryLimitPerTest}
              onChange={({target}: React.ChangeEvent<HTMLInputElement>) => setProblem((prevState: ProblemState) => ({
                ...prevState,
                memoryLimitPerTest: !Number.isNaN(+target.value) ? +target.value : 0
              }))}
              rowsMax={1}
            />
          </div>
          <div>
            <SelectorEditable
              editable={EDITABLE}
              selectorData={PROBLEM_INPUT_TYPE_SELECTOR}
              value={problem.typeInput}
              onChange={(value: { value: PROBLEM_INPUT_TYPE }) => setProblem((prevState: ProblemState) => ({
                ...prevState,
                typeInput: value.value
              }))}
            />
          </div>
          <SelectorEditable
            editable={EDITABLE}
            selectorData={PROBLEM_OUTPUT_TYPE_SELECTOR}
            value={problem.typeOutput}
            onChange={(value: { value: PROBLEM_OUTPUT_TYPE }) => setProblem((prevState: ProblemState) => ({
              ...prevState,
              typeOutput: value.value
            }))}
          />
        </div>
        <TextAreaEditable
          body={problem.description}
          placeholder="Put here the problem description"
          onChangeBody={({target}: React.ChangeEvent<HTMLTextAreaElement>) => setProblem((prevState: ProblemState) => ({
            ...prevState,
            description: target.value
          }))}/>
        <TextAreaEditable
          body={problem.inputDescription}
          placeholder="Put here the problem input description"
          onChangeBody={({target}: React.ChangeEvent<HTMLTextAreaElement>) => setProblem((prevState: ProblemState) => ({
            ...prevState,
            inputDescription: target.value
          }))}/>
        <TextAreaEditable
          body={problem.outputDescription}
          placeholder="Put here the problem output description"
          onChangeBody={({target}: React.ChangeEvent<HTMLTextAreaElement>) => setProblem((prevState: ProblemState) => ({
            ...prevState,
            outputDescription: target.value
          }))}/>
        <div style={{display: 'inline-flex', width: '100%'}}>
          <Typography variant="h6" gutterBottom style={{width: '50%'}}>
            Input Description
          </Typography>
          <Typography variant="h6" gutterBottom style={{width: '50%'}}>
            Output Description
          </Typography>
          <IconButton aria-label="left" onClick={() => setProblem((prevState: ProblemState) => ({
            ...prevState,
            samples: [...prevState.samples, {input: '', output: '', id: keySamples++}]
          }))}>
            <QueueIcon/>
          </IconButton>
        </div>
        {problem.samples.map(problemSample => {
          return (
            <div style={{display: 'inline-flex', width: '100%'}} key={problemSample.id}>
              <TextAreaEditable
                placeholder="Put here the sample input"
                body={problemSample.input}
                style={{width: 'calc(50% - 29px)'}}
                onChangeBody={({target}: React.ChangeEvent<HTMLTextAreaElement>) => setProblem((prevState: ProblemState) => ({
                  ...prevState,
                  samples: prevState.samples.map(sample => {
                    if (sample.id === problemSample.id) {
                      return {...sample, input: target.value};
                    }
                    return sample;
                  })
                }))}
              />
              <TextAreaEditable
                placeholder="Put here the sample output"
                body={problemSample.output}
                style={{width: 'calc(50% - 29px)'}}
                onChangeBody={({target}: React.ChangeEvent<HTMLTextAreaElement>) => setProblem((prevState: ProblemState) => ({
                  ...prevState,
                  samples: prevState.samples.map(sample => {
                    if (sample.id === problemSample.id) {
                      return {...sample, output: target.value};
                    }
                    return sample;
                  })
                }))}
              />
              <IconButton
                aria-label="left"
                onClick={() => setProblem((prevState: ProblemState) => ({
                  ...prevState,
                  samples: prevState.samples.filter(sample => sample.id !== problemSample.id)
                }))}
              >
                <DeleteIcon/>
              </IconButton>
            </div>
          );
        })}
      </div>
      <div className="options">
        <div className="options-div">
          {side !== PREVIEW && <div
              className={'left-icon ' + classes.backgroundDarkest}
              onClick={() => {
                setSide(side === EDIT ? EDIT_AND_PREVIEW : PREVIEW)
              }}
          >
              <IconButton aria-label="left">
                  <ChevronLeftIcon/>
              </IconButton>
          </div>}
          {side !== EDIT && <div
              className={'right-icon ' + classes.backgroundDarkest}
              onClick={() => setSide(side === PREVIEW ? EDIT_AND_PREVIEW : EDIT)}
          >
              <IconButton aria-label="right">
                  <ChevronRightIcon/>
              </IconButton>
          </div>}
        </div>
      </div>
      <div style={side === EDIT ? {display: 'none'} : side === PREVIEW ? {width: '100%'} : {width: '50%'}}>
        <ProblemViewer problem={problem}/>
      </div>
    </div>
  );
}
