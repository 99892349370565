import React from 'react';
import { Card, CardMedia, Divider, Grid, Typography } from '@material-ui/core';

import { LabelField } from '../Shared';
import { RouteComponentProps } from 'react-router-dom';
import { UserAccountState } from '../../store/types';
import { HeaderBar } from '../../containers/Shared';

type OwnProps = {
  user: UserAccountState,
  addNotice: Function,
  getUserAccount: Function,
}

export interface Props extends RouteComponentProps, OwnProps {
}

export default ({user}: Props): JSX.Element => {
  return (
    <>
      <HeaderBar title={user.givenName} actions={[]}/>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid item lg={6} md={10} sm={12}>
          <Card elevation={3}>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <CardMedia
                  component="img"
                  alt={`${user.givenName} ${user.familyName}`}
                  height="150"
                  image={user.imageUrl}
                  title="Contemplative Reptile"
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justify="space-around"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography variant="h6" align={'center'}>
                      {`${user.givenName} ${user.familyName}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom align={'center'}>
                      {user.nickname}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider/>
            <Divider/>
            <Grid
              container
              direction="column"
              justify="space-around"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <LabelField
                  text={user.codeforcesUserName ? user.codeforcesUserName : '-'}
                  clarification={'Codeforces nickname'}
                  titleTop/>
              </Grid>
              <Grid item>
                <LabelField
                  text={user.topcoderUserName ? user.topcoderUserName : '-'}
                  clarification={'Topcoder nickname'}
                  titleTop/>
              </Grid>
              <Grid item>
                <LabelField
                  text={user.codechefUserName ? user.codechefUserName : '-'}
                  clarification={'Codechef nickname'}
                  titleTop/>
              </Grid>
              <Grid item>
                <LabelField
                  text={user.uvaUserName ? user.uvaUserName : '-'}
                  clarification={'UVA nickname'}
                  titleTop/>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>)
}
