import { makeStyles } from '@material-ui/core';

const drawerOpenWidth = 200;
const drawerCloseWidth = 60;
const appBarHeight = 45;

// @ts-ignore
export const useStyles = makeStyles((theme: any) => {
  const scrollbarWidth = 'thin';
  const scrollbarColor = `${theme.palette.primary.light} transparent`;
  return {
    // UTIILS
    backgroundDarkest: {
      background: theme.palette.primary.darkest,
      color: theme.palette.primary.light
    },
    backgroundLight: {
      color: theme.palette.primary.darkest,
      background: theme.palette.primary.light
    },
    sizeViewPage: {
      overflow: 'auto',
      padding: '0 15px',
      marginTop: appBarHeight + 'px',
      height: `calc(100% - ${appBarHeight}px)`,
      scrollbarWidth,
      scrollbarColor
    },
    disable: {
      opacity: '0.5',
      pointerEvents: 'none',
    },
    sizeViewPageWithoutDrawer: {
      overflow: 'auto',
      padding: '65px 15px 15px 15px',
      height: `calc(100% - ${appBarHeight + 30}px)`
    },
    maxPageWidthDrawOpen: {
      width: `calc(100% - ${drawerOpenWidth + 30}px)`
    },
    maxPageWidthDrawClose: {
      width: `calc(100% - ${drawerCloseWidth + 30}px)`
    },
    flexGrow1: {
      flexGrow: '1'
    },
    pageMaxHeight: {
      height: `calc(100vh - ${appBarHeight + 30}px)`
    },
    boxShadow: {
      boxShadow: `0 0 5px ${theme.palette.primary.boxShadow}`
    },
    maxDivSize: {
      width: '100%',
      height: '100%'
    },
    topLeftPosition: {
      top: '0px',
      left: '0',
      padding: '0 10px',
      position: 'absolute',
      borderRadius: '4px 0'
    },
    // WEB PAGE
    webPageBody: {
      display: 'flex', height: '100%',
      background: theme.palette.primary.dark,
      color: theme.palette.primary.light
    },
    // TEXT AREA
    textarea: {
      width: 'calc(100% - 14px)',
      color: 'inherit',
      background: 'inherit',
      border: `1px solid ${theme.palette.primary.light}`,
      padding: '7px'
    },
    // TABLE
    boxTable: {
      height: '100%',
      width: '100%',
      boxShadow: `0 0 6px ${theme.palette.primary.boxShadow}`
    },
    // ACCOUNT
    userCard: {
      maxWidth: 150
    },
    // BEGIN LOGIN
    loginCard: {
      minWidth: 275,
      height: '200px'
    },
    loginTitle: {
      fontSize: 14
    },
    // END LOGIN
    title: { // Account.js
      flexGrow: 1,
      textAlign: 'center',
      fontWeight: '800'
    },
    topList: {
      borderTop: `2px solid ${theme.palette.primary.dark}`
    },
    // BEGIN Navigation Bar
    drawer: {
      width: drawerOpenWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerPaper: {
      background: theme.palette.primary.darkest,
      boxShadow: `0 0 6px ${theme.palette.primary.boxShadow}`,
      border: 'none',
      color: theme.palette.primary.light
    },
    drawerItem: {
      background: theme.palette.primary.dark,
      boxShadow: `inset 0px 5px 10px -5px ${theme.palette.primary.boxShadow}`
    },
    drawerOpen: {
      width: drawerOpenWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      overflowX: 'hidden'
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: drawerCloseWidth
    },
    drawerMenuIcon: {
      height: `${appBarHeight}px`
    },
    drawerMenuList: {
      overflowY: 'auto',
      height: 'calc(100% - 95px)',
      overflowX: 'hidden',
      scrollbarWidth,
      scrollbarColor,
      direction: 'rtl'
    },
    // BEGIN App Bar
    appBar: {
      left: `${drawerCloseWidth}px`,
      zIndex: '1300',
      width: `calc(100% - ${drawerCloseWidth}px)`,
      height: `${appBarHeight}px`,
      background: theme.palette.primary.darkest,
      boxShadow: 'none',
      border: 'none',
      color: theme.palette.primary.light
    },
    appBarBorderBottom: {
      boxShadow: `0px 3px 6px ${theme.palette.primary.boxShadow}`,
      width: `calc(100% + ${drawerCloseWidth}px)`,
      height: '2px',
      left: `-${drawerCloseWidth}px`,
      position: 'fixed',
      zIndex: '1200',
      background: theme.palette.primary.dark,
      top: `${appBarHeight}px`
    },
    appBarSpace: {
      height: `${appBarHeight}px`
    },
    // END App Bar
    googleLogoutButton: {
      background: 'transparent !important',
      borderColor: 'inherit !important',
      color: 'inherit !important',
      width: `${drawerOpenWidth - 55}px`,
      textAlign: 'center',
      alignContent: 'center'
    },
    // Bitbucket
    bitbucketAlertDot: {
      position: 'absolute',
      top: '7px',
      height: '10px',
      width: '10px',
      background: 'red',
      borderRadius: '50%',
      left: '14px'
    },
    // PROBLEM VIEW
    problemSamplesHeadTitle: {
      background: theme.palette.primary.darkest,
      padding: '5px 10px',
      width: '100%',
      marginBottom: '0'
    }
  }
});
