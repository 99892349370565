import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { GraphListProps } from '../../../containers/Graph';
import { APP_ROUTES } from '../../../config/constans';
import { AppTable } from '../../Shared';
import { HeaderBar } from '../../../containers/Shared'

export default ({graphs}: GraphListProps) => {
  const headCells: Array<{ type: 'numeric' | 'date' | 'string' | 'array', align: 'left' | 'center' | 'right', label: string }> =
    [
      {type: 'string', align: 'center', label: 'Name'},
      {type: 'date', align: 'center', label: 'Creation Date'},
      {type: 'string', align: 'center', label: 'Owner'},
      {type: 'string', align: 'center', label: 'Visibility'},
      {type: 'array', align: 'center', label: 'Tags'}
    ];
  const bodyRows: Array<{ rows: Array<{ value: any }>, id: string }> = graphs.map(graph => ({
    rows: [
      {value: graph.name || graph.id, link: APP_ROUTES.ROOT.GRAPH.VIEW(graph.id)},
      {value: new Date(graph.date)},
      {value: graph.ownerNickname, link: APP_ROUTES.ROOT.PROFILE.USER(graph.ownerNickname)},
      {value: graph.type},
      {value: graph.tags.join(',')}
    ],
    id: graph.id
  }));
  return (
    <>
      <HeaderBar title="Graphs" actions={[
        <Link to={APP_ROUTES.ROOT.GRAPH.NEW}>
          <Button variant="outlined" key="add-user-action">
            New Graph
          </Button>
        </Link>]}/>
      <AppTable headCells={headCells} bodyRows={bodyRows} config={{order: 2}}/>
    </>
  )
}
