import React from 'react';
import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Add as AddIcon } from '@material-ui/icons';

import { APP_ROUTES, ROLES } from '../../../../config/constans';
import { AppTable, BodyRowsType, HeadCellsType } from '../../../Shared';
import { PRINT_PROBLEM_STATUS } from '../../../../types';
import { HeaderBar } from '../../../../containers/Shared/HeaderBar';
import { ProblemListProps } from '../../../../containers/Judge/Problem';

export default ({user, problems}: ProblemListProps) => {
  const actions = [];
  if (user.role === ROLES.ADMIN || user.role === ROLES.SUPER_ADMIN) {
    actions.push(
      <Link to={APP_ROUTES.ROOT.JUDGE.PROBLEM.NEW}>
        <IconButton title={'Add Problem'}><AddIcon/></IconButton>
      </Link>
    );
  }
  const headCells: HeadCellsType = [
    {type: 'numeric', align: 'center', label: '#'},
    {type: 'date', align: 'center', label: 'Creation Date'}
  ];
  if (user.isLogged) {
    headCells.push({type: 'string', align: 'center', label: 'Status'});
  }
  headCells.push(
    {type: 'string', align: 'center', label: 'Name'},
    {type: 'string', align: 'right', label: 'Setter'},
    {type: 'array', align: 'right', label: 'Used On'},
    {type: 'numeric', align: 'right', label: 'Solved'},
    {type: 'numeric', align: 'right', label: 'Attempts'},
    {type: 'array', align: 'right', label: 'Tags'}
  );
  const bodyRows: BodyRowsType = problems.map(problem => {
    const rows = [
      {value: problem.id, link: APP_ROUTES.ROOT.JUDGE.PROBLEM.VIEW(problem.id)},
      {value: new Date(problem.date)}];
    if (user.isLogged) {
      // @ts-ignore
      rows.push({value: PRINT_PROBLEM_STATUS[problem.status]});
    }
    rows.push(
      // @ts-ignore
      {value: problem.name, link: APP_ROUTES.ROOT.JUDGE.PROBLEM.VIEW(problem.id)},
      {value: problem.ownerNickname, link: APP_ROUTES.ROOT.PROFILE.USER(problem.ownerNickname)},
      {value: []},
      {value: 0},
      {value: 0},
      {value: problem.tags}
    );
    return {
      rows,
      id: problem.id
    }
  });
  return (
    <>
      <HeaderBar title="Problems" actions={actions}/>
      <AppTable headCells={headCells} bodyRows={bodyRows}/>
    </>
  );
}
