import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';

import '../New/AddProblem.scss';
import { HeaderBar } from '../../../../containers/Shared';
import { ProblemEditorProps } from '../../../../containers/Judge/Problem';
import { ProblemState } from '../../../../store/types';
import Body from './Body';
import { APP_ROUTES } from '../../../../config/constans';
import { Cancel as CancelIcon, Save as SaveIcon } from '@material-ui/icons';

export default ({problem, updateProblem}: ProblemEditorProps) => {
  const [editProblem, setEditProblem] = useState<ProblemState>(problem);
  console.log(editProblem);
  return (
    <>
      <HeaderBar
        title="Editing Problem"
        actions={[
          <IconButton title={'Save'} onClick={() => updateProblem(editProblem)}><SaveIcon/></IconButton>,
          <Link to={APP_ROUTES.ROOT.JUDGE.PROBLEM.VIEW(problem.id)}>
            <IconButton title={'Cancel'}><CancelIcon/></IconButton>
          </Link>
        ]}
      />
      <Body problem={editProblem} setProblem={setEditProblem}/>
    </>
  );
}
