import { connect } from 'react-redux';

import EditUser from '../../components/Admin/Users/EditUser';
import { updateUser } from '../../store/actions';
import { UserState } from '../../store/types/user';

export const mapStateToProps = () => {
  return {}
};

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    updateUser(user: UserState) {
      dispatch(updateUser(user));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);

