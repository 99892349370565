import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import './index.css';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { history } from './store/reducers';
import Login from './containers/Login/Login';
import BitbucketAuth from './containers/BitbucketAuth';
import PrivateRoutes from './containers/PrivateRoutes';
import { WebPage } from './containers/Shared';
import Cover from './containers/Cover';
import test from './components/Test';
import ViewSource from './containers/CodeEditor/ViewCode';
import Editor from './containers/CodeEditor/List';
import NewSource from './containers/CodeEditor/NewCode';
import { ProblemList, ProblemViewer } from './containers/Judge/Problem';
import { APP_ROUTES } from './config/constans';

console.info('VERSION ' + process.env.REACT_APP_VERSION);

document.title = 'Oscar Gauss';

/*
font-size: 15px,
line-height: 20px
 */
const ThemeProviderComponent = ({user}) => {
  const [theme, setTheme] = useState(createMuiTheme());
  useEffect(() => {
    const colors = [
      {
        // contrastText: '#e3f2fd',
        light: '#303e46',
        main: '#90a4ae', // no used
        // accent: '#647279',
        dark: '#eceff1',
        darkest: '#a6b6be',
        boxShadow: 'rgba(0, 0, 0, 0.3)'
      },
      {
        // contrastText: '#e3f2fd',
        light: '#E3F2FE',
        main: '#ffffff', // no used
        // accent: '#647279',
        dark: '#4dabf5',
        darkest: '#1769aa',
        boxShadow: 'rgba(0, 0, 0, 0.3)'
      },
      {
        // contrastText: '#e3f2fd',
        light: '#a6b6be',
        main: '#90a4ae', // no used
        // accent: '#647279',
        dark: '#455a64',
        darkest: '#303e46',
        boxShadow: 'rgba(0, 0, 0, 0.3)'
      }
    ];
    setTheme(createMuiTheme({
      palette: {
        primary: colors[user.theme]
      },
      overrides: {
        MuiIconButton: {
          root: {
            color: 'inherit',
            padding: '5px',
            fontSize: 'small'
          }
        },
        MuiSvgIcon: {
          root: {
            fontSize: '20px'
          }
        },
        MuiToolbar: {
          root: {
            height: 'inherit',
            minHeight: 'unset !important',
            paddingLeft: '0 !important'
          }
        },
        MuiButton: {
          root: {
            color: 'inherit',
            height: '32px',
            padding: '6px 12px',
            margin: '0'
          },
          outlined: {
            borderColor: `${colors[user.theme].light}`
          },
          label: {
            fontSize: '16px',
            lineHeight: '20px'
          }
        },
        MuiListItemIcon: {
          root: {
            color: 'inherit !important',
            minWidth: 'auto !important',
            padding: '4px 10px'
          }
        },
        MuiFilledInput: {
          root: {
            color: 'inherit'
          }
        },
        MuiInputLabel: {
          root: {
            color: 'inherit'
          },
          formControl: {
            top: '-5px',
            opacity: '0.5'
          },
          shrink: {
            top: 'unset',
            opacity: '0.7'
          },
          outlined: {
            transform: 'translate(15px, 15px) scale(1) !important',
            fontSize: '18px'
          }
        },
        MuiInputBase: {
          root: {
            color: 'inherit'
          }
        },
        MuiSelect: {
          icon: {
            color: 'inherit'
          },
          iconOutlined: {
            right: '0px',
            top: '4px'
          }
        },
        MuiFormControl: {
          root: {
            margin: '0 !important',
            padding: '8px 6px 4px 6px'
          }
        },
        MuiOutlinedInput: {
          notchedOutline: {
            borderColor: `${colors[user.theme].light}`
          },
          input: {
            height: '20px',
            lineHeight: '20px',
            fontSize: '16px',
            padding: '4px !important'
          }
        },
        MuiListItem: {
          root: {
            paddingTop: '3px',
            paddingBottom: '3px'
          }
        },
        MuiListItemText: {
          root: {
            margin: 'none'
          }
        },
        MuiTableHead: {
          root: {
            boxShadow: `0 0 6px ${colors[user.theme].boxShadow}`,
            backgroundColor: `${colors[user.theme].darkest}`
          }
        },
        MuiTableCell: {
          root: {
            borderBottom: `1px solid ${colors[user.theme].darkest}`
          }
        },
        MuiTablePagination: {
          root: {
            color: 'inherit'
          }
        },
        MuiPaper: {
          root: {
            color: `${colors[user.theme].light}`,
            backgroundColor: `${colors[user.theme].darkest}`
          }
        },
        MuiDialog: {
          root: {
            color: `${colors[user.theme].light}`
          }
        },
        MuiChip: {
          label: {
            padding: '0 7px'
          }
        },
        MuiTypography: {
          h1: {
            fontSize: '25px',
            lineHeight: '25px'
          }
        }
      }
    }));
  }, [user.theme]);

  return (
    <ThemeProvider theme={theme}>
      <WebPage>
        <Switch>
          <Route exact path={'/'} component={Cover}/>
          <Route exact path={APP_ROUTES.ROOT.CODE.LIST} component={Editor}/>
          <Route exact path={APP_ROUTES.ROOT.CODE.NEW} component={NewSource}/>
          <Route exact path={APP_ROUTES.ROOT.CODE.VIEW(':codeId')} component={ViewSource}/>
          <Route exact path={APP_ROUTES.ROOT.JUDGE.PROBLEM.LIST} component={ProblemList}/>
          <Route exact path={APP_ROUTES.ROOT.JUDGE.PROBLEM.VIEW(':problemId')} component={ProblemViewer}/>
          <Route exact path={APP_ROUTES.ROOT.LOGIN} component={Login}/>
          <Route exact path={`/bitbucketAuth`} component={BitbucketAuth}/>
          <Route exact path={`/test`} component={test}/>
          <Route component={PrivateRoutes}/>
        </Switch>
      </WebPage>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
};

const ThemeProviderContainer = connect(mapStateToProps)(ThemeProviderComponent);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProviderContainer/>
    </ConnectedRouter>
  </Provider>
  , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
