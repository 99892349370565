import React from 'react';
import { Grid } from '@material-ui/core';

import { PAGE_STATUS, PageStateType, PAGE_STATUS_SELECTOR } from '../../../../types';
import { SelectorEditable, TextFieldEditable, LabelField } from '../../../Shared';
import { EDITABLE } from '../../../../types';

type Props = {
  category: PageStateType,
  setCategory: Function,
}

export default ({category, setCategory}: Props) => {
  return (
    <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
      <Grid item>
        <TextFieldEditable
          label="Tag"
          value={category.tag}
          onChange={(event: any) => setCategory({...category, tag: event.target.value})}
          rowsMax={1}
        />
      </Grid>
      <Grid item>
        <LabelField text={category.parent} clarification={'Parent'} titleTop/>
      </Grid>
      <Grid item>
        <TextFieldEditable
          label="Title"
          value={category.title}
          onChange={(event: any) => setCategory({...category, title: event.target.value})}
          rowsMax={1}
        />
      </Grid>
      <Grid item>
        <TextFieldEditable
          label="Description"
          value={category.description}
          onChange={(event: any) => setCategory({...category, description: event.target.value})}
          rowsMax={1}
        />
      </Grid>
      <Grid item>
        <SelectorEditable
          editable={EDITABLE}
          selectorData={PAGE_STATUS_SELECTOR}
          value={category.status}
          onChange={(value: { value: PAGE_STATUS }) => setCategory({...category, status: value.value})}
        />
      </Grid>
    </Grid>
  );
}
