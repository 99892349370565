import {connect} from 'react-redux';
import {withRouter} from "react-router";

import ViewerPage from "../../../../components/Train/Material/ViewerPage/ViewerPage";

export const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user
  }
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewerPage));

