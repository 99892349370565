import React, { useEffect, useState } from 'react';

import NavigationBar from '../../../containers/Navbar/NavigationBar';
import { Notices } from '../../../containers/Shared';
import { serverWhoIs } from '../../../config/services/backend';
import { OK, ALERT_TYPES } from '../../../types';
import { useStyles } from '../../../config/styles';

interface WebPageProps {
  blocking: number,
  children: JSX.Element,
  //dispatch
  addNotice: Function,
  loginGoogle: Function,
}

export const WebPageComponent = ({blocking, addNotice, loginGoogle, children}: WebPageProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const responseGoogle = async () => {
      setLoading(true);
      const request = await serverWhoIs();
      if (request.ok === OK) {
        loginGoogle(request.result);
        addNotice({type: ALERT_TYPES.SUCCESS, label: 'Welcome back'});
      } else {
        addNotice({type: ALERT_TYPES.ERROR, label: request.message});
      }
      setLoading(false);
    };
    responseGoogle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Notices/>
      {loading ? <div> ... </div> :
        <div className={classes.webPageBody}>
          <NavigationBar open={open} setOpen={setOpen}/>
          <div
            className={classes.sizeViewPage + ' ' + (open ? classes.maxPageWidthDrawOpen : classes.maxPageWidthDrawClose)}>
            <div
              style={{margin: '15px 0'}}
              className={blocking ? classes.disable : ''}
            >
              {children}
            </div>
          </div>
        </div>
      }
    </>
  );
}
