import { Button, Grid, TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import { Modal } from '../../Shared';
import { GraphState } from '../../../store/types';

type Props = {
  // store
  graph: GraphState,
  // dispatch
  changePasswordGraph: Function,
};

export default ({graph, changePasswordGraph}: Props) => {
  const [passwordNew, setPasswordNew] = useState<string>(graph.password);
  const changePassword = useCallback(() => {
    changePasswordGraph(passwordNew);
  }, [changePasswordGraph, passwordNew]);
  useEffect(() => {
    setPasswordNew(graph.password);
  }, [graph]);
  return <Modal button="Change Password" title="Changing Password" actions={[
    <Button key="change-password-graph-action" onClick={changePassword}
            disabled={!passwordNew}>
      Change
    </Button>]} onCancel={() => setPasswordNew(graph.password)}>
    <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
      <Grid item>
        <TextField id="outlined-basic-new-user-name" label="New Password" variant="outlined"
                   value={passwordNew}
                   onChange={(event) => setPasswordNew(event.target.value)}/>
      </Grid>
    </Grid>
  </Modal>
}
