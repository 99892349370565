import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import * as DateFns from 'date-fns';
import { Link } from 'react-router-dom';

import './Table.scss';
import { useStyles } from '../../../config/styles';
import { useEventListener } from '../../../helpers/customHooks';

type RowType = Array<{ value: any, link?: string, print?: any, style?: object }>;
type BodyCellType = { rows: RowType, id: number | string };
export type BodyRowsType = Array<BodyCellType>;
export type HeadCellsType = Array<{ type: 'numeric' | 'date' | 'string' | 'array', align: 'left' | 'center' | 'right', label: string }>;
type ConfigType = { order?: number }

export const AppTable = ({headCells, bodyRows, config}: { headCells: HeadCellsType, bodyRows: BodyRowsType, config?: ConfigType }) => {
  const [order, setOrder] = useState<number>(config && config.order ? config.order : 0);
  const [page, setPage] = React.useState(0);
  const rowHeight = 53;
  const calcRowsPerPage = () => Math.max(Math.floor((window.innerHeight - 180) / rowHeight), 5);
  const [rowsPerPage, setRowsPerPage] = useState<number>(calcRowsPerPage());
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState<Array<number>>([rowsPerPage, rowsPerPage * 2, rowsPerPage * 3]);
  useEventListener('resize', () => {
    const newCount = calcRowsPerPage();
    setRowsPerPage(newCount);
    setRowsPerPageOptions([newCount, newCount * 2, newCount * 3]);
  });
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, bodyRows.length - page * rowsPerPage);
  const stableSort = (array: BodyRowsType) => {
    if (order === 0) {
      return array;
    }
    const byColumn = Math.abs(order) - 1;
    const asc = order < 0 ? 1 : -1;
    array.sort((a: any, b: any) => {
      if (headCells[byColumn].type === 'numeric') {
        return asc * (a.rows[byColumn].value - b.rows[byColumn].value);
      }
      if (headCells[byColumn].type === 'string') {
        return asc * (a.rows[byColumn].value.localeCompare(b.rows[byColumn].value));
      }
      if (headCells[byColumn].type === 'array') {
        return asc * (a.rows[byColumn].value.lenght - b.rows[byColumn].value.lenght);
      }
      if (headCells[byColumn].type === 'date') {
        return asc * (a.rows[byColumn].value - b.rows[byColumn].value);
      }
      return 0
    });
    return array;
  };
  const classes = useStyles();
  return (
    <div className={classes.boxTable}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={bodyRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size={'small'}
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow>
              {headCells.map((headCell, index) => (
                <TableCell
                  key={headCell.label}
                  align={headCell.align}
                  padding="default"
                  onClick={() => setOrder(order === 0 ? index + 1 :
                    index + 1 === Math.abs(order) ? -order : index + 1)}
                >
                  <div>
                    {headCell.label}
                    {headCell.type === 'string' &&
                    <input type="text" placeholder="Search" className="search-input"/>}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(bodyRows)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: BodyCellType, index: number) => {
                return <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  style={{height: rowHeight + 'px'}}
                >
                  {row.rows.map((cell, indexCell) => (cell.link ?
                      <TableCell align={headCells[indexCell].align} key={row.id + headCells[indexCell].label}
                                 style={cell.style}>
                        <Link to={cell.link}>
                          {headCells[indexCell].type === 'date' ? DateFns.format(cell.value, 'YYY/MMM/dd HH:mm:ss') : cell.print ? cell.print : cell.value}
                        </Link>
                      </TableCell>
                      :
                      <TableCell align={headCells[indexCell].align} key={row.id + headCells[indexCell].label}
                                 style={cell.style}>
                        {headCells[indexCell].type === 'date' ? DateFns.format(cell.value, 'YYY/MMM/dd HH:mm:ss') : cell.print ? cell.print : cell.value}
                      </TableCell>
                  ))}
                </TableRow>
              })}
            {emptyRows > 0 && (
              <TableRow style={{height: rowHeight * emptyRows}}>
                <TableCell colSpan={6}/>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

    </div>
  );
}
