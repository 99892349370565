import { GraphMetaState, GRAPHS_ACTIONS, GraphsActionTypes, GraphState } from '../types/graphs';

export const graphs = (state: { [key: string]: GraphState } = {}, action: GraphsActionTypes) => {
  const newState = {...state};
  switch (action.type) {
    case GRAPHS_ACTIONS.UPDATE_GRAPH:
      newState[action.graph.id] = {...newState[action.graph.id], ...action.graph};
      return newState;
    case GRAPHS_ACTIONS.REPLACE_GRAPHS:
      const newEmptyState: { [key: string]: GraphState } = {};
      action.graphs.forEach((graph: GraphMetaState) => {
        newEmptyState[graph.id] = {...newState[graph.id], ...graph};
      });
      return newEmptyState;
    case GRAPHS_ACTIONS.UPDATE_PASSWORD_GRAPH:
      newState[action.graphId] = {...newState[action.graphId], password: action.password};
      return newState;
    default:
      return state;
  }
};
