import React from 'react';
import {
  Button
} from '@material-ui/core';

import { Modal } from '../../../Shared';
import { PageStateType } from '../../../../types';

type Props = {
  // props
  button: JSX.Element,
  category: PageStateType,
  action: Function,
}

export default ({button, category, action}: Props) => {
  return (
    <Modal button={button}
           title={`Are you sure to remove the ${category.title} category?`} actions={[
      <Button key="delete-category-action" onClick={() => action(category)}>
        Delete
      </Button>]}>
      <></>
    </Modal>
  );
}
