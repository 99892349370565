import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';

import { useStyles } from '../../../config/styles';
import { Graph, EDITABLE, SCOPE_VISIBILITY_LOGGED_IN_SELECTOR, SCOPE_VISIBILITY_SELECTOR } from '../../../types';
import { TitleBox, SelectorEditable, TextFieldEditable } from '../../Shared';
import { SCOPE_VISIBILITY } from '../../../config/constans';
import { GraphState, NewGraphState, UserState } from '../../../store/types';
import GraphAnimation from '../Viewer/GraphAnimation';
import { useEventListener } from '../../../helpers/customHooks';

type GraphBodyEditorProps = {
  user: UserState,
  graphic: GraphState | NewGraphState,
  setGraphic: Function
}
export default ({user, graphic, setGraphic}: GraphBodyEditorProps) => {
  const classes = useStyles();
  const titleRef = useRef(null);
  const [titleHeight, setTitleHeight] = useState(0);
  useEffect(() => {
    if (titleRef && titleRef.current) {
      // @ts-ignore
      setTitleHeight(+titleRef.current.clientHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEventListener('resize', () => {
    if (titleRef && titleRef.current) {
      // @ts-ignore
      setTitleHeight(+titleRef.current.clientHeight);
    }
  });
  console.log('graphhh:', graphic);
  return (
    <div className={clsx(classes.pageMaxHeight, classes.boxShadow)}>
      <TitleBox
        body={
          <div style={{height: `calc(100% - ${titleHeight}px)`}}>
            <GraphAnimation
              graphs={graphic.graph}
              setGraphs={(graph: Array<Graph>) => setGraphic({
                ...graphic,
                graph: graph
              })}/>
          </div>
        }
        title={
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.maxDivSize}
            ref={titleRef}
          >
            <Grid item>
              <TextFieldEditable
                label="Title"
                value={graphic.name}
                onChange={(event: any) => setGraphic({
                  ...graphic,
                  name: event.target.value
                })}
                rowsMax={1}
              />
            </Grid>
            <Grid item>
              <SelectorEditable
                editable={EDITABLE}
                selectorData={user.isLogged ? SCOPE_VISIBILITY_LOGGED_IN_SELECTOR : SCOPE_VISIBILITY_SELECTOR}
                value={graphic.type}
                onChange={(value: { value: SCOPE_VISIBILITY }) => {
                  setGraphic({
                    ...graphic,
                    type: value.value
                  })
                }}
              />
            </Grid>
            <Grid item>
              <TextFieldEditable
                label="Tags"
                value={graphic.tags.join(', ')}
                onChange={(event: any) => setGraphic({
                  ...graphic,
                  tags: event.target.value.split(',').map((tag: string) => tag.trim())
                })}
                rowsMax={1}
              />
            </Grid>
          </Grid>
        }
      />
    </div>
  )
};
