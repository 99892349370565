import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as D3 from 'd3';
import {
  Edit as EditIcon
} from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import './GraphViwer.scss';
import { Edge, ENTITY, Graph, GRAPH_EDGE_TYPE, LABEL_NODE_POSITIONS, Node } from '../../../types/graph';
import EditLabels from './EditLabels';
import { useEventListener } from '../../../helpers/customHooks';
import { mex } from '../../../helpers/functions';

export const printValueLabel = (value: string, index: number): string => {
  return value.split('_INDEX_').join('' + index)
    .split('_INF_').join('∞')
    .split('_NEG_INF_').join('-∞');
};

const transformToXY = (transform: string): { x: number, y: number } => {
  const coords = transform.substr(10, transform.length - 11).split(',');
  return {x: +coords[0], y: +coords[1]};
};

type Props = {
  graph: Graph,
  setGraph?: Function,
  maxBox: {
    xLeft: number,
    xRight: number,
    yTop: number,
    yBottom: number
  }
}
export default ({graph, setGraph, maxBox}: Props) => {
  const d3Container = useRef(null);
  const [container, setContainer] = useState({
    originX: 0,
    originY: 0,
    zoom: 1,
    height: -1,
    width: -1,
    initialZoom: 1
  });
  const [selectedNodes, setSelectedNodes] = useState<Array<number>>([]);
  const [editProps, setEditProps] = useState<{ entity: ENTITY, key: number }>({entity: ENTITY.NONE, key: 0});
  const arrowCoordinates = useCallback((u, v) => {
    const vector = {
      x: v.x - u.x,
      y: v.y - u.y
    };
    const mod = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
    vector.x /= mod;
    vector.y /= mod;
    const finalRadio = (graph.config.nodes.radio + graph.config.nodes.borderWidth / 2);
    const newMod = (mod - finalRadio - (graph.config.edgeType === GRAPH_EDGE_TYPE.DIRECTED ? 2 * graph.config.edges.width : 0));
    return [
      {
        x: u.x + vector.x * finalRadio,
        y: u.y + vector.y * finalRadio
      },
      {
        x: u.x + vector.x * newMod,
        y: u.y + vector.y * newMod
      }
    ];
  }, [graph.config.edgeType, graph.config.edges.width, graph.config.nodes.borderWidth, graph.config.nodes.radio]);
  const arrowLabelCoordinates = useCallback((u, v) => {
    if (graph.config.edgeType === GRAPH_EDGE_TYPE.NO_DIRECTED) {
      return {
        x: (v.x + u.x) / 2,
        y: (v.y + u.y) / 2
      }
    }
    const vector = {
      x: v.x - u.x,
      y: v.y - u.y
    };
    const mod = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
    vector.x /= mod;
    vector.y /= mod;
    return {
      x: u.x + vector.x * (mod * 0.75),
      y: u.y + vector.y * (mod * 0.75)
    };
  }, [graph.config.edgeType]);
  useEffect(() => {
    if (setGraph) {
      if (selectedNodes.length >= 2 && graph.nodes[selectedNodes[0]] && graph.nodes[selectedNodes[1]]) {
        const fromKey = selectedNodes[0];
        const toKey = selectedNodes[1];
        const coors = arrowCoordinates(
          {
            x: graph.nodes[fromKey].x,
            y: graph.nodes[fromKey].y
          },
          {
            x: graph.nodes[toKey].x,
            y: graph.nodes[toKey].y
          }
        );
        const newEdge = {
          key: mex(Object.keys(graph.edges)),
          from: fromKey, // node key
          to: toKey, // node key
          x1: coors[0].x,
          y1: coors[0].y,
          x2: coors[1].x,
          y2: coors[1].y,
          labels: graph.config.edges.labels.map(label => ({
            value: label.default
          }))
        };
        const newEdges = {...graph.edges};
        newEdges[newEdge.key] = newEdge;
        setGraph({...graph, edges: newEdges});
        setSelectedNodes([]);
      }
      const newSelectedNodes = [];
      if (selectedNodes[0] && graph.nodes[selectedNodes[0]]) {
        newSelectedNodes.push(selectedNodes[0])
      }
      if (selectedNodes[1] && graph.nodes[selectedNodes[1]]) {
        newSelectedNodes.push(selectedNodes[1])
      }
      if (JSON.stringify(newSelectedNodes) !== JSON.stringify(selectedNodes)) {
        setSelectedNodes(newSelectedNodes);
      }
    }
  }, [arrowCoordinates, graph, selectedNodes, setGraph]);

  useEffect(() => {
    if (d3Container.current) {
      const svg = D3.select(d3Container.current);
      svg.selectAll('g').remove();
      svg
        .call(
          // @ts-ignore
          D3.zoom()
            .on('zoom', () => {
              setContainer(prevState => ({
                ...prevState,
                zoom: D3.event.transform.k * prevState.initialZoom
              }));

            }));
      // Bind D3 data
      const axis = svg
        .append('g');
      const xDomain = [0, container.width];
      const yDomain = [0, container.height];

      // Define the axes scales
      const xScale = D3.scaleLinear().domain(xDomain).range([0, container.width]);
      const yScale = D3.scaleLinear().domain(yDomain).range([0, container.height]);
      // Define the axes
      const xAxis = D3.axisTop(xScale);
      const yAxis = D3.axisLeft(yScale);

      const spacing = 70;
      // Add the x-axis lines/ticks
      const beginX = container.originX - (container.originX % spacing);
      const endX = container.originX + container.zoom * container.width + 1;
      const xAxisPlot = axis.append('g')
        .call(xAxis.tickValues(D3.range(beginX, endX, spacing)));
      xAxisPlot.selectAll('.domain').remove();
      xAxisPlot.selectAll('.tick text').remove();
      xAxisPlot.selectAll('.tick line')
        .attr('stroke', 'silver')
        .attr('stroke-width', container.zoom * 0.75)
        .attr('y1', container.originY)
        .attr('y2', container.originY + container.zoom * container.height);

      // Add the y-axis lines/ticks
      const beginY = container.originY - (container.originY % spacing);
      const endY = container.originY + container.zoom * container.height;
      const yAxisPlot = axis.append('g')
        .call(yAxis.tickValues(D3.range(beginY, endY, spacing)));
      yAxisPlot.selectAll('.domain').remove();
      yAxisPlot.selectAll('.tick text').remove();
      yAxisPlot.selectAll('.tick line')
        .attr('stroke', 'silver')
        .attr('stroke-width', container.zoom * 0.75)
        .attr('x1', container.originX)
        .attr('x2', container.originX + container.zoom * container.width);
      //
      const auxiliaryPlot = svg.append('g');
      const clickNewNode = (d: any, i: number, childNodes: any) => {
        if (setGraph) {
          const coords = D3.mouse(childNodes[i]);
          const newNode: Node = {
            x: Math.round(coords[0]),
            y: Math.round(coords[1]),
            key: mex(Object.keys(graph.nodes)),
            labels: graph.config.nodes.labels.map(label => ({
              value: label.default
            }))
          };
          const newNodes = {...graph.nodes};
          newNodes[newNode.key] = newNode;
          if (selectedNodes.length === 1 && graph.nodes[selectedNodes[0]]) {
            const fromKey = selectedNodes[0];
            const coors = arrowCoordinates(
              {
                x: graph.nodes[fromKey].x,
                y: graph.nodes[fromKey].y
              },
              {
                x: newNode.x,
                y: newNode.y
              }
            );
            const newEdge = {
              key: mex(Object.keys(graph.edges)),
              from: fromKey, // node key
              to: newNode.key, // node key
              x1: coors[0].x,
              y1: coors[0].y,
              x2: coors[1].x,
              y2: coors[1].y,
              labels: graph.config.edges.labels.map(label => ({
                value: label.default
              }))
            };
            const newEdges = {...graph.edges};
            newEdges[newEdge.key] = newEdge;
            setGraph({...graph, nodes: newNodes, edges: newEdges});
            setSelectedNodes([]);
          } else if (selectedNodes.length === 0) {
            setGraph({...graph, nodes: newNodes});
          }
        }
      };
      const newEdge = auxiliaryPlot
        .append('line')
        .attr('class', 'new-edge')
        .attr('stroke-width', graph.config.edges.width + 2)    // set the stroke width
        .attr('stroke', graph.config.edges.color)
        .attr('cursor', 'grabbing')
        .attr('style', 'display: none')
        .on('click', clickNewNode);
      const planeDrag = {x: 0, y: 0, dragging: false};
      const plane = svg.append('g');

      // CONTAINER
      plane
        .append('rect')
        .attr('height', container.zoom * container.height)
        .attr('width', container.zoom * container.width)
        .attr('x', container.originX)
        .attr('y', container.originY)
        .attr('fill', 'transparent')
        .attr('class', 'container');
      if (setGraph) {
        plane
          .on('click', clickNewNode)
          .on('mousemove', (d, i, childNodes) => {
            if (selectedNodes.length === 1) {
              const coords = D3.mouse(childNodes[i]);
              newEdge
                .attr('x2', xScale.invert(coords[0]))
                .attr('y2', yScale.invert(coords[1]))
            }
          });
      }
      plane
      // @ts-ignore
        .call(D3.drag()
          .on('start', () => {
            if (!selectedNodes.length) {
              plane
                .raise();
              planeDrag.x = D3.event.x;
              planeDrag.y = D3.event.y;
            }
          })
          .on('drag', () => {
            if (!selectedNodes.length) {
              plane
                .attr('cursor', 'move');
              planeDrag.dragging = true;
            }
          })
          .on('end', () => {
            if (planeDrag.dragging) {
              planeDrag.dragging = false;
              const newCoords = {
                x: -Math.round(-container.originX + D3.event.x - planeDrag.x),
                y: -Math.round(-container.originY + D3.event.y - planeDrag.y)
              };
              setContainer(prevState => ({...prevState, originX: newCoords.x, originY: newCoords.y}));
            }
          }));
      const defs = svg.append('g').append('defs');
      defs.append('marker')
        .attr('id', 'arrow')
        .attr('viewBox', '0 -5 10 10')
        .attr('refX', 5)
        .attr('refY', 0)
        .attr('markerWidth', 4)
        .attr('markerHeight', 4)
        .attr('orient', 'auto')
        .append('path')
        .attr('d', 'M0,-5L10,0L0,5')
        .attr('class', 'arrowHead')
        .attr('stroke', graph.config.edges.color)
        .attr('fill', graph.config.edges.color);
      // const edgeDrag = {x: 0, y: 0, x1: 0, y1: 0, x2: 0, y2: 0};
      const edgesPlot = svg.append('g');
      edgesPlot
        .selectAll('line')
        .data(Object.values(graph.edges))
        .enter();

      for (const edge of Object.values(graph.edges)) {
        const edgePlot = edgesPlot
          .append('g');
        const lineEdgePlot = edgePlot
          .append('line');
        lineEdgePlot
          .attr('x1', xScale(edge.x1))
          .attr('y1', yScale(edge.y1))
          .attr('x2', xScale(edge.x2))
          .attr('y2', yScale(edge.y2))
          .attr('class', 'from-' + edge.from + ' to-' + edge.to)
          .attr('stroke-width', graph.config.edges.width)
          .attr('stroke', graph.config.edges.color)
          .attr('marker-end', graph.config.edgeType === GRAPH_EDGE_TYPE.DIRECTED ? 'url(#arrow)' : '');
        const coors = arrowLabelCoordinates(
          {
            x: edge.x1,
            y: edge.y1
          },
          {
            x: edge.x2,
            y: edge.y2
          }
        );
        const labelEdgePlot = edgePlot.append('text');
        labelEdgePlot
          .attr('x', coors.x)
          .attr('y', coors.y)
          .attr('class', 'from-' + edge.from + ' to-' + edge.to + ' label')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'middle')
          .selectAll('tspan')
          .data(edge.labels)
          .enter()
          .append('tspan')
          .text((e: any) => printValueLabel(e.value, edge.key))
          .attr('fill', (e: { value: string }, index) => graph.config.edges.labels[index].color)
          .attr('font-size', (e: { value: string }, index) => graph.config.edges.labels[index].size + 'px');
        edgePlot
          .on('mouseover', () => {
            if (!selectedNodes.length) {
              lineEdgePlot
                .attr('stroke-width', graph.config.edges.width + 2);
              edgePlot
                .attr('cursor', 'grab')
                .attr('font-weight', 'bold');
            }
          })
          .on('mouseout', () => {
            lineEdgePlot
              .attr('stroke-width', graph.config.edges.width);
            edgePlot
              .attr('cursor', 'unset')
              .attr('font-weight', 'unset');
          })
          .on('contextmenu', () => {
            D3.event.preventDefault();
            setEditProps({entity: ENTITY.EDGE, key: edge.key})
          });
      }
      //NODES
      const nodesPlot = svg.append('g');
      const nodeDrag = {x: 0, y: 0};
      for (const node of Object.values(graph.nodes)) {
        const nodePlot = nodesPlot.append('g')
          .attr('transform', `translate(${node.x}, ${node.y})`)
          .on('contextmenu', () => {
            D3.event.preventDefault();
            setEditProps({entity: ENTITY.NODE, key: node.key})
          });
        const circleNodePlot = nodePlot
          .append('circle')
          .attr('r', graph.config.nodes.radio)
          .attr('stroke-width', graph.config.nodes.borderWidth)
          .attr('stroke', graph.config.nodes.borderColor)
          .attr('fill', graph.config.nodes.background);
        for (const position of Object.values(LABEL_NODE_POSITIONS)) {
          const labels = [];
          for (let i = 0; i < graph.config.nodes.labels.length; i++) {
            const label = graph.config.nodes.labels[i];
            if (label.position === position) {
              labels.push({
                ...label,
                index: i
              });
            }
          }
          if (labels.length) {
            const labelNodePlot = nodePlot
              .append('text')
              .attr('x',
                position === LABEL_NODE_POSITIONS.CENTER ? 0 :
                  position === LABEL_NODE_POSITIONS.UP ? 0 :
                    position === LABEL_NODE_POSITIONS.RIGHT ? graph.config.nodes.radio + graph.config.nodes.borderWidth :
                      position === LABEL_NODE_POSITIONS.DOWN ? 0 :
                        position === LABEL_NODE_POSITIONS.LEFT ? -(graph.config.nodes.radio + graph.config.nodes.borderWidth) :
                          0)
              .attr('y',
                position === LABEL_NODE_POSITIONS.CENTER ? 0 :
                  position === LABEL_NODE_POSITIONS.UP ? -(graph.config.nodes.radio + graph.config.nodes.borderWidth / 2) :
                    position === LABEL_NODE_POSITIONS.RIGHT ? 0 :
                      position === LABEL_NODE_POSITIONS.DOWN ? (graph.config.nodes.radio + graph.config.nodes.borderWidth / 2) :
                        position === LABEL_NODE_POSITIONS.LEFT ? 0 :
                          0)
              .attr('text-anchor', position === LABEL_NODE_POSITIONS.CENTER ? 'middle' :
                position === LABEL_NODE_POSITIONS.UP ? 'middle' :
                  position === LABEL_NODE_POSITIONS.RIGHT ? 'start' :
                    position === LABEL_NODE_POSITIONS.DOWN ? 'middle' :
                      position === LABEL_NODE_POSITIONS.LEFT ? 'end' :
                        '')
              .attr('dominant-baseline', position === LABEL_NODE_POSITIONS.CENTER ? 'middle' :
                position === LABEL_NODE_POSITIONS.UP ? 'ideographic' :
                  position === LABEL_NODE_POSITIONS.RIGHT ? 'middle' :
                    position === LABEL_NODE_POSITIONS.DOWN ? 'hanging' :
                      position === LABEL_NODE_POSITIONS.LEFT ? 'middle' :
                        '');
            for (const labelConfig of labels) {
              labelNodePlot
                .append('tspan')
                .text(printValueLabel(node.labels[labelConfig.index].value, node.key))
                .attr('fill', labelConfig.color)
                .attr('font-size', labelConfig.size + 'px');
            }
          }
        }
        if (setGraph) {
          nodePlot
            .on('click', () => {
              if (selectedNodes.length === 0) {
                setSelectedNodes([node.key]);
              } else if (selectedNodes[0] === node.key) {
                setSelectedNodes([]);
              } else {
                const parallelEdge = Object.values(graph.edges).filter((edge: Edge) => {
                  if (graph.config.edgeType === GRAPH_EDGE_TYPE.DIRECTED) {
                    return edge.from === selectedNodes[0] && edge.to === node.key;
                  }
                  return (edge.from === selectedNodes[0] && edge.to === node.key) ||
                    (edge.to === selectedNodes[0] && edge.from === node.key)
                });
                if (parallelEdge.length === 0) {
                  setSelectedNodes([...selectedNodes, node.key]);
                }
              }
            })
            .on('mousemove', (d, i, childNodes) => {
              const coords = D3.mouse(childNodes[i]);
              newEdge
                .attr('x2', xScale.invert(node.x + coords[0]))
                .attr('y2', yScale.invert(node.y + coords[1]))
            })
            .on('mouseover', () => {
              if (!selectedNodes.length) {
                nodePlot
                  .attr('cursor', 'grab');
              }
              nodePlot
                .attr('font-weight', 'bold');
              circleNodePlot
                .attr('stroke-width', graph.config.nodes.borderWidth + 2);
            })
            .on('mouseout', () => {
              nodePlot
                .attr('font-weight', 'unset')
                .attr('cursor', 'unset');
              circleNodePlot
                .attr('stroke-width', graph.config.nodes.borderWidth + ((selectedNodes.length === 1 && node.key === selectedNodes[0]) ? 2 : 0));
            })
            // @ts-ignore
            .call(D3.drag()
              .on('start', () => {
                if (!selectedNodes.length) {
                  nodePlot
                    .raise()
                    .attr('cursor', 'move');
                  nodeDrag.x = D3.event.x;
                  nodeDrag.y = D3.event.y;
                }
              })
              .on('drag', () => {
                if (!selectedNodes.length) {
                  const newCoords = {
                    x: Math.round(node.x + D3.event.x - nodeDrag.x),
                    y: Math.round(node.y + D3.event.y - nodeDrag.y)
                  };
                  nodePlot
                    .attr('cursor', 'move')
                    .attr('transform', `translate(${newCoords.x}, ${newCoords.y})`);
                  for (const edge of Object.values(graph.edges)) {
                    if (edge.to === node.key) {
                      const coors = arrowCoordinates(
                        {
                          x: graph.nodes[edge.from].x,
                          y: graph.nodes[edge.from].y
                        },
                        {
                          x: newCoords.x,
                          y: newCoords.y
                        }
                      );
                      const coorsLabel = arrowLabelCoordinates(
                        {
                          x: coors[0].x,
                          y: coors[0].y
                        },
                        {
                          x: coors[1].x,
                          y: coors[1].y
                        }
                      );
                      edgesPlot
                        .select('.from-' + edge.from + '.to-' + edge.to)
                        .attr('x1', coors[0].x)
                        .attr('y1', coors[0].y)
                        .attr('x2', coors[1].x)
                        .attr('y2', coors[1].y);
                      edgesPlot
                        .select('.from-' + edge.from + '.to-' + edge.to + '.label')
                        .attr('x', coorsLabel.x)
                        .attr('y', coorsLabel.y);
                    }
                    if (edge.from === node.key) {
                      const coors = arrowCoordinates(
                        {
                          x: newCoords.x,
                          y: newCoords.y
                        },
                        {
                          x: graph.nodes[edge.to].x,
                          y: graph.nodes[edge.to].y
                        }
                      );
                      const coorsLabel = arrowLabelCoordinates(
                        {
                          x: coors[0].x,
                          y: coors[0].y
                        },
                        {
                          x: coors[1].x,
                          y: coors[1].y
                        }
                      );
                      edgesPlot
                        .select('.from-' + edge.from + '.to-' + edge.to)
                        .attr('x1', coors[0].x)
                        .attr('y1', coors[0].y)
                        .attr('x2', coors[1].x)
                        .attr('y2', coors[1].y);
                      edgesPlot
                        .select('.from-' + edge.from + '.to-' + edge.to + '.label')
                        .attr('x', coorsLabel.x)
                        .attr('y', coorsLabel.y)
                    }
                  }
                }
              })
              .on('end', () => {
                if (!selectedNodes.length) {
                  const newCords = transformToXY(nodePlot.attr('transform'));
                  const newNodes = {...graph.nodes};
                  newNodes[node.key] = {
                    ...newNodes[node.key],
                    x: newCords.x,
                    y: newCords.y
                  };
                  if (JSON.stringify(newNodes) !== JSON.stringify(graph.nodes)) {
                    // update the position edges
                    const newEdges = {...graph.edges};
                    for (const edgesKey in graph.edges) {
                      if (graph.edges[edgesKey].from === node.key || graph.edges[edgesKey].to === node.key) {
                        const coors = arrowCoordinates(
                          {
                            x: newNodes[graph.edges[edgesKey].from].x,
                            y: newNodes[graph.edges[edgesKey].from].y
                          },
                          {
                            x: newNodes[graph.edges[edgesKey].to].x,
                            y: newNodes[graph.edges[edgesKey].to].y
                          }
                        );
                        newEdges[edgesKey] = {
                          ...graph.edges[edgesKey],
                          x1: coors[0].x,
                          y1: coors[0].y,
                          x2: coors[1].x,
                          y2: coors[1].y
                        };
                      }
                    }
                    setGraph({...graph, nodes: newNodes, edges: newEdges});
                  }
                }
              }));
        }
      }
      if (selectedNodes.length === 1 && graph.nodes[selectedNodes[0]]) {
        plane.attr('cursor', 'grabbing');
        nodesPlot.attr('cursor', 'grabbing');
        newEdge
          .attr('x1', graph.nodes[selectedNodes[0]].x)
          .attr('y1', graph.nodes[selectedNodes[0]].y)
          .attr('x2', graph.nodes[selectedNodes[0]].x)
          .attr('y2', graph.nodes[selectedNodes[0]].y)
          .attr('style', 'display: visible');
      }
    }
  }, [arrowCoordinates, arrowLabelCoordinates, container, graph, selectedNodes, setGraph]);
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef && containerRef.current) {
      maxBox.xLeft -= graph.config.nodes.radio + graph.config.nodes.borderWidth / 2;
      maxBox.xRight += graph.config.nodes.radio + graph.config.nodes.borderWidth / 2;
      maxBox.yTop -= graph.config.nodes.radio + graph.config.nodes.borderWidth / 2;
      maxBox.yBottom += graph.config.nodes.radio + graph.config.nodes.borderWidth / 2;
      const margin = 50;
      let minWidth = maxBox.xRight - maxBox.xLeft + margin * 2;
      let minHeight = maxBox.yBottom - maxBox.yTop + margin * 2;
      const isVoid = minWidth < 0;
      if (isVoid) {
        minWidth = 500;
        minHeight = 500;
      }
      const initialZoom = Math.max(
        // @ts-ignore
        (minWidth / +containerRef.current.clientWidth),
        // @ts-ignore
        (minHeight / +containerRef.current.clientHeight));
      // @ts-ignore
      const paddingX = Math.abs(minWidth - +containerRef.current.clientWidth * initialZoom);
      // @ts-ignore
      const paddingY = Math.abs(minHeight - +containerRef.current.clientHeight * initialZoom);
      setContainer(prevState => ({
        ...prevState,
        originX: isVoid ? 0 : maxBox.xLeft - paddingX / 2 - margin,
        originY: isVoid ? 0 : maxBox.yTop - paddingY / 2 - margin,
        zoom: initialZoom,
        initialZoom,
        // @ts-ignore
        height: +containerRef.current.clientHeight,
        // @ts-ignore
        width: +containerRef.current.clientWidth
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxBox]);

  useEventListener('resize', () => {
    if (containerRef && containerRef.current) {
      setContainer(prevState => ({
        ...prevState,
        // @ts-ignore
        height: +containerRef.current.clientHeight,
        // @ts-ignore
        width: +containerRef.current.clientWidth
      }));
    }
  });
  return (
    <div style={{width: '100%', height: '100%', position: 'relative'}}>
      {editProps.entity !== ENTITY.NONE && setGraph &&
      <EditLabels graph={graph} setGraph={setGraph} editProps={editProps} setEditProps={setEditProps}/>
      }
      <div className="svg-container" ref={containerRef}>
        <svg
          ref={d3Container}
          className="svg-content"
          viewBox={`${container.originX} ${container.originY} ${container.zoom * container.width} ${container.zoom * container.height}`}
        />
      </div>
      <div className={'label-svg'}>
        {setGraph &&
        <div>
            <IconButton aria-label="edit-graph" onClick={() => setEditProps({entity: ENTITY.GRAPH, key: 0})}>
                <EditIcon/>
            </IconButton>
        </div>}
        <div>nodes: {Object.values(graph.nodes).length}</div>
        <div>edges: {Object.values(graph.edges).length}</div>
      </div>
    </div>
  );
}
