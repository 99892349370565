import React from 'react';
import {
  Button, IconButton
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import { Modal } from '../../Shared';
import { UserState } from '../../../store/types';
import { serverDeleteUser } from '../../../config/services/backend';
import { OK, ALERT_TYPES } from '../../../types';

type Props = {
  // dispatch
  addNotice: Function,
  // props
  user: UserState,
  getData: Function,
}

export default ({addNotice, user, getData}: Props) => {
  const deleteUser = async () => {
    const request = await serverDeleteUser(user.email);
    if (request.ok === OK) {
      getData();
      addNotice({type: ALERT_TYPES.SUCCESS, label: `User ${user.email} deleted`});
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: request.message});
    }
  };
  return (
    <Modal
      button={<IconButton><DeleteIcon/></IconButton>}
      title={`Are you sure to remove the ${user.nickname} user?`}
      actions={[
        <Button key="delete-user-action" onClick={deleteUser}>
          Delete
        </Button>]}>
      <></>
    </Modal>
  );
}
