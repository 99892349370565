import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

type Props = {
  button: string | JSX.Element,
  title: string,
  children: JSX.Element,
  actions: Array<JSX.Element>,
  onCancel?: Function,
  persistent?: boolean,
  forceClose?: boolean
};

export const Modal = ({button, title, children, actions, onCancel, persistent, forceClose}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    if (!persistent) {
      setOpen(false);
    }
  };
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    setOpen(false);
  };
  useEffect(() => {
    if (forceClose && open) {
      setOpen(false);
    }
  }, [forceClose, open]);
  return (
    <>
      {typeof button === 'string' ?
        <Button variant="outlined" onClick={handleClickOpen}>
          {button}
        </Button> :
        <span onClick={handleClickOpen} style={{cursor: 'pointer'}}>
        {button}
      </span>
      }
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          {actions.map((action, index) => (
            <span onClick={handleClose} key={'action-' + index}>{action}</span>
          ))}
        </DialogActions>
      </Dialog>
    </>
  );
}
