import { connect } from 'react-redux';

import { HeaderBarComponent } from '../../../components/Shared/HeaderBar';
import { RootStateType } from '../../../store/types';

const mapStateToProps = (state: RootStateType) => {
  return {
    loading: state.flags.loading,
    blocking: state.flags.blocking
  }
};

export const HeaderBar = connect(mapStateToProps)(HeaderBarComponent);
