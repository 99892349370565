import {SCOPE_VISIBILITY} from "../../config/constans";
import { LANGUAGE_TYPES } from '../../types';

export interface NewCodeState {
  name: string,
  description: string,
  type: SCOPE_VISIBILITY,
  language: LANGUAGE_TYPES,
  source: string,
}

export interface CodeState {
  date: Date,
  id: string,
  name: string,
  description: string,
  ownerNickname: string,
  type: SCOPE_VISIBILITY,
  language: LANGUAGE_TYPES,
  source: string,
  password: string,
}

export interface CodeMetaState {
  date: Date,
  id: string,
  name: string,
  ownerNickname: string,
}

export enum CODES_ACTIONS {
  ADD_CODE = 'ADD_CODE',
  UPDATE_CODE = 'UPDATE_CODE',
  REPLACE_CODES = 'REPLACE_CODES'
}

interface AddCodeAction {
  type: CODES_ACTIONS.ADD_CODE,
  code: CodeState,
}

interface UpdateCodeAction {
  type: CODES_ACTIONS.UPDATE_CODE,
  code: CodeState,
}

interface ReplaceCodesAction {
  type: CODES_ACTIONS.REPLACE_CODES,
  codes: Array<CodeMetaState>,
}

export type CodesActionTypes = AddCodeAction | UpdateCodeAction | ReplaceCodesAction;
