import { connect } from 'react-redux';

import AddProblem from '../../../components/Judge/Problem/New';
import { NewProblemState, RootStateType } from '../../../store/types';
import { createNewProblem } from '../../../store/actions';

const mapStateToProps = (state: RootStateType) => {
  return {
    user: state.user
  }
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    createNewProblem: (newProblem: NewProblemState) => {
      dispatch(createNewProblem(newProblem));
    }
  }
};

export const ProblemNew = connect(mapStateToProps, mapDispatchToProps)(AddProblem);

