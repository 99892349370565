import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';

import { Modal } from '../../Shared';
import { UserState } from '../../../store/types';
import UserEditable from '../../../containers/Admin/UserEditable';
import { EDIT } from '../../../types';

interface Props {
  // dispatch
  updateUser: Function,
  // props
  userToEdit: UserState,
  getData: Function,
  button: JSX.Element
}

export default ({updateUser, userToEdit, getData, button}: Props) => {
  const [userNew, setUserNew] = useState<UserState>(userToEdit);
  useEffect(() => {
    setUserNew(userToEdit);
  }, [userToEdit]);
  const handleUpdateUser = () => {
    updateUser(userNew);
    getData();
  };
  return (
    <Modal
      button={button}
      title={''}
      actions={[<Button onClick={handleUpdateUser}>Save</Button>]}>
      <UserEditable kind={EDIT} userEditable={userNew} setUserEditable={setUserNew}/>
    </Modal>
  );
}
