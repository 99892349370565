import React from 'react';
import {
  Avatar
} from '@material-ui/core';
import {
  ExitToApp as ExitToAppIcon,
  AccountBox as AccountBoxIcon
} from '@material-ui/icons';
import { GoogleLogout } from 'react-google-login';

import { APP_ROUTES, GOOGLE } from '../../../config/constans';
import NavBarList from './NavBarList';
import { useStyles } from '../../../config/styles';
import { serverLogout } from '../../../config/services/backend';
import { ALERT_TYPES } from '../../../types/alert';
import { OK } from '../../../types/services';

export default ({user, visible, logoutGoogle, addNotice}) => {
  const classes = useStyles();
  const items = [];
  const userLogout = async () => {
    const request = await serverLogout();
    if (request.ok === OK) {
      addNotice({type: ALERT_TYPES.SUCCESS, label: 'Logout success'});
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: request.message + ', but forced logout'});
    }
    logoutGoogle();
  };
  const responseGoogle = async (response) => {
    if (!response || !response.error) {
      addNotice({
        type: ALERT_TYPES.ERROR,
        label: response ? response.error : 'Error on response of Google, but forced logout'
      });
    } else {
      addNotice({type: ALERT_TYPES.SUCCESS, label: 'Google logout success'});
    }
  };
  if (user.isLogged) {
    items.push({
        icon: user.imageUrl ?
          <Avatar src={user.imageUrl} style={{height: '24px', width: '24px'}} component={'div'}/>
          :
          <AccountBoxIcon/>,
        title: 'Account',
        link: APP_ROUTES.ROOT.PROFILE.SELF
      },
      {
        icon: <ExitToAppIcon/>,
        title: <span onClick={userLogout}>
          <GoogleLogout
            clientId={GOOGLE.CLIENT_ID}
            buttonText="Logout"
            onLogoutSuccess={responseGoogle}
            onFailure={responseGoogle}
            className={classes.googleLogoutButton + ' googleLogoutButton'}
          />
        </span>
      }
    );
  } else {
    items.push({
      icon: <AccountBoxIcon/>,
      title: 'Login',
      link: APP_ROUTES.ROOT.LOGIN
    });
  }
  const head = {
    icon: user.imageUrl ?
      <Avatar src={user.imageUrl} style={{height: '24px', width: '24px'}} component={'div'}/>
      :
      <AccountBoxIcon/>,
    title: user.givenName
  };
  return <NavBarList visible={visible} head={head} items={items}/>;
}
