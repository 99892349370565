export enum PAGE_STATUS {
  PUBLIC = 'public',
  PRIVATE = 'private',
  CLOSED = 'closed',
}

export const PRINT_PAGE_STATUS: { [key in PAGE_STATUS]: string } = {
  [PAGE_STATUS.PRIVATE]: 'Private',
  [PAGE_STATUS.PUBLIC]: 'Public',
  [PAGE_STATUS.CLOSED]: 'Closed',
};

export interface NewPageType {
  title: string,
  tag: string,
  parent: string,
  description: string,
}

export interface PageStateType {
  date: Date
  title: string,
  tag: string,
  parent: string,
  description: string,
  status: PAGE_STATUS,
  ownerNickname: string,
}

export interface PageUpdateType {
  title: string,
  tag: string,
  parent: string,
  description: string,
  status: string,
}
