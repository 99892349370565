import { VERDICTS } from '../config/constans';

export enum LANGUAGE_TYPES {
  CPP = 'cpp',
  JAVA = 'java',
  PYTHON = 'python',
  TEXT = 'txt'
}

export const PRINT_LANGUAGE_TYPES: { [key in LANGUAGE_TYPES]: string } = {
  [LANGUAGE_TYPES.CPP]: 'C ++',
  [LANGUAGE_TYPES.JAVA]: 'Java',
  [LANGUAGE_TYPES.PYTHON]: 'Python',
  [LANGUAGE_TYPES.TEXT]: 'Plain Text'
};

export interface BelongProblemAttemptType {
  problem: number,
}

export interface BelongContestAttemptType {
  problem: number,
  contest: number,
}

type BelongAttemptType = BelongProblemAttemptType | BelongContestAttemptType;

export interface MetaAttemptType {
  attemptId: string,
  date: Date,
  ownerNickname: string,
  belong: BelongAttemptType,
  language: LANGUAGE_TYPES,
  verdict: VERDICTS,
  time: number,
  memory: number
}

export interface BelongType {
  problem: number,
  contest?: number,
}

export interface VerdictResumeAttemptType {
  verdict: VERDICTS,
  time: number,
  memory: number,
  description: string
}

export interface VerdictAttemptType {
  testId: string,
  verdict: VERDICTS,
  time: number,
  memory: number,
  description: string
}

export interface ScoreAttemptType {
  resume: VerdictResumeAttemptType,
  tests: Array<VerdictAttemptType>
}

export interface AttemptType {
  attemptId: string,
  date: Date,
  ownerNickname: string,
  belong: BelongType,
  language: LANGUAGE_TYPES,
  source: string
  score: ScoreAttemptType
}

export interface SubmitProblemType {
  belong: BelongProblemAttemptType,
  language: LANGUAGE_TYPES,
  source: string
}
