import React, {useEffect, useState} from "react";

export default ({source}) => {
  const [text, setText] = useState('');
  const readTextFile = file => {
    fetch(file).then((text) => {
      return text.text();
    }).then((text) => {
      setText(text);
    });
  };
  useEffect(() => {
    readTextFile(source);
  }, [source]);
  return (
    <pre>
      {text}
    </pre>
  );
}

