import { TYPE_OK } from '../../../types';
import { authorizedRequest } from '../services';
import { clean, ErrorResponse } from './index';
import { GraphState, NewGraphState } from '../../../store/types';
import { URL } from '../server.config';

const ROUTE = {
  GRAPH: (id?: string) => {
    return `${URL}/api/graph${id ? '/' + id : ''}`;
  },
  CAN: () => {
    return `${URL}/api/graph/can`;
  },
  PASS: (id: string) => {
    return `${URL}/api/graph/pass/${id}`;
  }
};

interface OkGraphsResponse {
  ok: TYPE_OK,
  result: Array<GraphState>
}

export const serverGetGraphs = async (): Promise<ErrorResponse | OkGraphsResponse> => {
  return clean(await authorizedRequest(ROUTE.GRAPH(), {
    method: 'GET'
  }));
};

interface OkGraphResponse {
  ok: TYPE_OK,
  result: GraphState
}

export const serverCreateNewGraph = async (newGraph: NewGraphState): Promise<ErrorResponse | OkGraphResponse> => {
  return clean(await authorizedRequest(ROUTE.GRAPH(), {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(newGraph)
  }));
};

export const serverGetGraph = async (id: string): Promise<ErrorResponse | OkGraphResponse> => {
  return clean(await authorizedRequest(ROUTE.GRAPH(id), {
    method: 'GET'
  }));
};

export const serverCanUpdateGraph = async (id: string, password: string): Promise<ErrorResponse | OkGraphResponse> => {
  return clean(await authorizedRequest(ROUTE.CAN(), {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({id, password})
  }));
};

export const serverUpdateGraph = async (graph: GraphState): Promise<ErrorResponse | OkGraphResponse> => {
  return clean(await authorizedRequest(ROUTE.GRAPH(graph.id), {
    method: 'PUT',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(graph)
  }));
};

export const serverDeleteGraph = async (graphId: string): Promise<ErrorResponse | OkGraphResponse> => {
  return clean(await authorizedRequest(ROUTE.GRAPH(graphId), {
    method: 'DELETE'
  }));
};

export const serverChangePasswordGraph = async (graphId: string, password: string): Promise<ErrorResponse | OkGraphResponse> => {
  return clean(await authorizedRequest(ROUTE.PASS(graphId), {
    method: 'PUT',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({password})
  }));
};
