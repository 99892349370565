import React from 'react';
import ReactAceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-text';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-dracula'; /// pueder ser
import 'ace-builds/src-noconflict/theme-tomorrow_night_blue'; /// no
import 'ace-builds/src-noconflict/theme-tomorrow_night_eighties'; // puede ser
import 'ace-builds/src-noconflict/theme-tomorrow_night_bright';
import 'ace-builds/src-noconflict/theme-tomorrow_night_eighties';
import 'ace-builds/src-noconflict/theme-tomorrow_night';
import 'ace-builds/src-noconflict/theme-clouds_midnight';
import 'ace-builds/src-noconflict/theme-xcode';
import 'ace-builds/src-noconflict/ext-language_tools'; // to enable enableBasicAutocompletion

import './AceEditor.scss';
import { LANGUAGE_TYPES } from '../../../types';

type Props = {
  value: string,
  language: LANGUAGE_TYPES,
  onChange?: (value: string, event?: any) => void
  readOnly?: boolean,
  autoHeight?: boolean
};

export const AceEditor = ({value, language, onChange, readOnly, autoHeight}: Props) => {
  const MODE_LANGUAGES: { [key in LANGUAGE_TYPES]: string } = {
    [LANGUAGE_TYPES.CPP]: 'c_cpp',
    [LANGUAGE_TYPES.JAVA]: 'java',
    [LANGUAGE_TYPES.PYTHON]: 'python',
    [LANGUAGE_TYPES.TEXT]: 'text'
  };
  return (
    <ReactAceEditor
      // placeholder="Placeholder Text"
      mode={MODE_LANGUAGES[language]}
      theme="dracula"
      name="blid-ace-editor-codeah2"
      height={autoHeight ? `${value.split('\n').length * 17 + 17}px` : '100%'}
      width="100%"
      onLoad={() => {
      }}
      onChange={onChange}
      fontSize={14}
      showPrintMargin={false}
      showGutter={true}
      highlightActiveLine={true}
      value={value}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: false,
        showLineNumbers: true,
        tabSize: 2,
        readOnly: !onChange || readOnly
      }}
    />
  );
}
