import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';

import { serverGetAttempts } from '../../../config/services/backend';
import { OK, ALERT_TYPES, PRINT_LANGUAGE_TYPES, MetaAttemptType } from '../../../types';
import { AppTable, BodyRowsType, HeadCellsType } from '../../Shared';
import { APP_ROUTES, PRINT_VERDICTS, VERDICTS } from '../../../config/constans';
import { HeaderBar } from '../../../containers/Shared';
import { UserState } from '../../../store/types';
import socketIOClient from 'socket.io-client';
import { URL } from '../../../config/services/server.config';

interface Props {
  // state
  user: UserState
  // dispatch
  addNotice: Function
}

export default ({user, addNotice}: Props) => {
  const [attempts, setAttempts] = useState<{ [key: string]: MetaAttemptType }>({});
  useEffect(() => {
    const socket = socketIOClient(`${URL}/attempts`);
    socket.on('judged', (attempt: MetaAttemptType) => {
      setAttempts(prevState => ({
        ...prevState,
        [attempt.attemptId]: attempt
      }));
    });
    return () => {
      socket.close();
    }
  }, []);
  const actions = [
    <Link to={APP_ROUTES.ROOT.JUDGE.STATUS.USER(user.nickname)}>
      <Button variant="outlined">My Attempts</Button>
    </Link>
  ];

  const getData = useCallback(async () => {
    const request = await serverGetAttempts();
    if (request.ok === OK) {
      const newAttempts: { [key: string]: MetaAttemptType } = {};
      for (const attempt of request.result) {
        newAttempts[attempt.attemptId] = attempt;
      }
      setAttempts(newAttempts);
      addNotice({type: ALERT_TYPES.SUCCESS, label: 'Successfully attempts loaded'})
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: request.message})
    }
  }, [addNotice]);
  useEffect(() => {
    getData();
  }, [getData]);
  const headCells: HeadCellsType =
    [
      {type: 'string', align: 'center', label: '#'},
      {type: 'date', align: 'center', label: 'Date'},
      {type: 'numeric', align: 'center', label: 'Problem'},
      {type: 'string', align: 'center', label: 'Nickname'},
      {type: 'string', align: 'center', label: 'Language'},
      {type: 'string', align: 'center', label: 'Verdict'},
      {type: 'numeric', align: 'right', label: 'Time'},
      {type: 'numeric', align: 'right', label: 'Memory'}];
  const bodyRows: BodyRowsType = Object.values(attempts).map((attempt: MetaAttemptType) => {
    const rows = [
      {value: attempt.attemptId.substr(0, 7), link: APP_ROUTES.ROOT.JUDGE.ATTEMPT.VIEW(attempt.attemptId)},
      {value: new Date(attempt.date)},
      {value: attempt.belong.problem, link: APP_ROUTES.ROOT.JUDGE.PROBLEM.VIEW(attempt.belong.problem)},
      {value: attempt.ownerNickname, link: APP_ROUTES.ROOT.PROFILE.USER(attempt.ownerNickname)},
      {value: PRINT_LANGUAGE_TYPES[attempt.language]},
      {
        value: PRINT_VERDICTS[attempt.verdict] || '',
        print: <>{attempt.verdict === VERDICTS.JUDGING && <CircularProgress style={{
          width: '15px',
          height: '15px',
          marginRight: '10px'
        }}/>}{PRINT_VERDICTS[attempt.verdict]}</>
      },
      {
        value: attempt.time,
        print: attempt.verdict === VERDICTS.JUDGING ? <CircularProgress style={{
          width: '15px',
          height: '15px',
          marginRight: '10px'
        }}/> : attempt.time
      },
      {
        value: attempt.memory,
        print: attempt.verdict === VERDICTS.JUDGING ? <CircularProgress style={{
          width: '15px',
          height: '15px',
          marginRight: '10px'
        }}/> : attempt.memory
      }];
    return {
      rows,
      id: attempt.attemptId
    }
  });
  return (
    <>
      <HeaderBar title="Status" actions={actions}/>
      <AppTable headCells={headCells} bodyRows={bodyRows} config={{order: 2}}/>
    </>
  )
}
