import React from 'react';
import { Typography } from '@material-ui/core';

import './ProblemViewer.scss';
import {
  PRINT_PROBLEM_INPUT_TYPE,
  PRINT_PROBLEM_OUTPUT_TYPE,
  PRINT_PROBLEM_STATUS
} from '../../../../types';
import { useStyles } from '../../../../config/styles';
import { TitleBox, MdViewer } from '../../../Shared';
import { NewProblemState, ProblemState } from '../../../../store/types';

export default ({problem}: { problem: ProblemState | NewProblemState }) => {
  const classes = useStyles();
  return (
    <div className={'preview-problem ' + classes.boxShadow}>
      <div>
        <div className="problem-name">
          <Typography variant="h5" gutterBottom>
            {problem.name}
          </Typography>
        </div>
        <div className="problem-properties">
          <Typography variant="subtitle2" gutterBottom>
            {`${problem.timeLimitPerTest / 1000}seg ${problem.memoryLimitPerTest / 1000}MB\n`}
          </Typography>
        </div>
        <div className="problem-properties">
          <Typography variant="subtitle2" gutterBottom>
            {`Input: ${PRINT_PROBLEM_INPUT_TYPE[problem.typeInput]}, Output: ${PRINT_PROBLEM_OUTPUT_TYPE[problem.typeOutput]}`}
          </Typography>
        </div>
        <div className="problem-properties-last">
          <Typography variant="subtitle2" gutterBottom>
            {`Visibility: ${PRINT_PROBLEM_STATUS[problem.status]}`}
          </Typography>
        </div>
      </div>
      <TitleBox
        title={<Typography variant="h6">Description</Typography>}
        body={<MdViewer source={problem.description}/>}
      />
      <TitleBox
        title={<Typography variant="h6">Input</Typography>}
        body={<MdViewer source={problem.inputDescription}/>}
      />
      <TitleBox
        title={<Typography variant="h6">Output</Typography>}
        body={<MdViewer source={problem.outputDescription}/>}
      />
      <div className={'problem-samples ' + classes.boxShadow}>
        <div className={'problem-samples-head ' + classes.boxShadow}>
          <Typography variant="h6" gutterBottom className={classes.problemSamplesHeadTitle}>
            Sample Input
          </Typography>
          <Typography variant="h6" gutterBottom className={classes.problemSamplesHeadTitle}>
            Sample Output
          </Typography>
        </div>
        {problem.samples.map(problemSample => {
          return (
            <div key={problemSample.id} className="problem-samples-box">
              <div className="problem-samples-box-text">
                <pre>
                  <span>
                    {problemSample.input}
                  </span>
                </pre>
              </div>
              <div className="problem-samples-box-text">
                <pre>
                  <span>
                    {problemSample.output}
                  </span>
                </pre>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
