import { connect } from 'react-redux';

import List from '../../../components/Judge/Problem/List';
import Loader from '../../../components/HOC/Loader';
import { ProblemState, RootStateType, UserState } from '../../../store/types';
import { getAllProblems } from '../../../store/actions';

const mapStateToProps = (state: RootStateType) => {
  return {
    user: state.user,
    problems: Object.values(state.problems)
  }
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    getAllProblems: async () => {
      await dispatch(getAllProblems());
    }
  }
};

export interface ProblemListProps {
  // state
  user: UserState,
  problems: Array<ProblemState>,
  // dispatch
  getAllProblems: Function
}

export const ProblemList = connect(mapStateToProps, mapDispatchToProps)(Loader(List,
  async (props: ProblemListProps) => {
    if (props.problems.length) {
      props.getAllProblems();
    } else {
      await props.getAllProblems();
    }
  }
));

