import { connect } from 'react-redux';

import Cover from '../../components/Cover';
import { toReturnPath } from '../../helpers/functions';
import { APP_ROUTES } from '../../config/constans';
import { RootStateType } from '../../store/types';

export const mapStateToProps = (state: RootStateType) => {
  return {
    toReturn: toReturnPath(state, APP_ROUTES.ROOT.PROFILE.SELF)
  }
};

export default connect(mapStateToProps)(Cover);

