import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';

import { useStyles } from '../../../../config/styles';
import { UserState } from '../../../../store/types';
// import {
//   serverGetPages,
// } from "../../../../Config/services.backend";
import ListSubPages from '../../../../containers/Train/Category/ListSubPages';
import { Grid, Typography } from '@material-ui/core';
import { HeaderBar } from '../../../../containers/Shared';

type Props = {
  //state
  user: UserState,
  // dispatch
  addNotice: Function
}

export default ({user, addNotice}: Props) => {
  const classes = useStyles();
  const getData = useCallback(async () => {
    // const request = await serverGetPages('root');
    // if (request.ok === OK) {
    //   setCategories(request.result.map((result: any) => ({
    //     date: result.date,
    //     title: result.title,
    //     tag: result.tag,
    //     parent: result.parent,
    //     description: result.description,
    //     status: PAGE_STATUS_TYPES.PRIVATE,
    //     ownerNickname: result.ownerNickname,
    //   })));
    //   addNotice({type: ALERT_TYPES.SUCCESS, label: `Categories listed`});
    // } else {
    //   addNotice({type: ALERT_TYPES.ERROR, label: request.message});
    // }
  }, []);
  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <>
      <HeaderBar title={'Categories'} actions={[]}/>
      <div className={clsx(classes.pageMaxHeight)}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
        >
          <Grid item>
            <ListSubPages/>
          </Grid>
          <Grid item>
            <Typography variant="h6" gutterBottom>
              Material
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" gutterBottom>
              Exercises
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
