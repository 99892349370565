import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import TestCases from '../../../components/Judge/Problem/TestCases';
import {
  addBlocking,
  addLoading,
  addNotice,
  putProblemTypeJudge,
  subBlocking,
  subLoading
} from '../../../store/actions';
import Loader from '../../../components/HOC/Loader';
import { APP_ROUTES } from '../../../config/constans';
import { Message, ProblemState, UserState } from '../../../store/types';
import { ROLES } from '../../../config/constans';
import { TypeJudgeType } from '../../../types';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    user: state.user,
    problem: state.problems[ownProps.match.params.problemId]
  }
};

const mapDispatchToProps = (dispatch: Function, ownProps: any) => {
  return {
    addNotice(notice: Message) {
      dispatch(addNotice(notice));
    },
    addBlocking() {
      dispatch(addBlocking());
    },
    addLoading() {
      dispatch(addLoading());
    },
    subBlocking() {
      dispatch(subBlocking());
    },
    subLoading() {
      dispatch(subLoading());
    },
    async putProblemTypeJudge(chunkProblem: { id: number, typeJudge: TypeJudgeType }) {
      await dispatch(putProblemTypeJudge(chunkProblem))
    }
  }
};

export type ProblemTestCasesProps = {
  // state
  user: UserState,
  problem: ProblemState,
  // dispatch
  addNotice: Function,
  addBlocking: Function,
  addLoading: Function,
  subBlocking: Function,
  subLoading: Function,
  putProblemTypeJudge: Function,
  //
  history: any,
  location: any,
  match: any
}

export const ProblemTests = connect(mapStateToProps, mapDispatchToProps)(withRouter(Loader(TestCases,
  async (props: ProblemTestCasesProps) => {
    if (!props.problem || (props.user.role !== ROLES.SUPER_ADMIN && props.user.role !== ROLES.ADMIN)) {
      props.history.push(APP_ROUTES.ROOT.JUDGE.PROBLEM.VIEW(props.match.params.problemId));
    }
  }, (props: ProblemTestCasesProps) => {
    return !!props.problem
  })));
