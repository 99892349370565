import {
  ErrorOutline as ErrorOutlineIcon,
  ReportProblemOutlined as ReportProblemOutlinedIcon,
  InfoOutlined as InfoOutlinedIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  Schedule as ScheduleIcon,
  Event as EventIcon
} from '@material-ui/icons';
import { Chip } from '@material-ui/core';
import React from 'react';

import './Alert.css';
import { ALERT_TYPES } from '../../../types';

interface AlertProps {
  type: ALERT_TYPES,
  label: any
}

export const Alert = ({type, label}: AlertProps) => {
  const config = {
    [ALERT_TYPES.ERROR]: {
      icon: <ErrorOutlineIcon/>,
      class: 'color-red'
    },
    [ALERT_TYPES.WARNING]: {
      icon: <ReportProblemOutlinedIcon/>,
      class: 'color-orange'
    },
    [ALERT_TYPES.INFO]: {
      icon: <InfoOutlinedIcon/>,
      class: 'color-blue'
    },
    [ALERT_TYPES.SUCCESS]: {
      icon: <CheckCircleOutlinedIcon/>,
      class: 'color-green'
    },
    [ALERT_TYPES.CLOCK_LIVE]: {
      icon: <ScheduleIcon/>,
      class: 'color-red'
    },
    [ALERT_TYPES.CLOCK_FUTURE]: {
      icon: <EventIcon/>,
      class: 'color-blue'
    }
  };
  return <Chip
    className={config[type].class}
    size={type === ALERT_TYPES.INFO ? 'medium' : 'small'}
    icon={config[type].icon}
    label={label}
  />
};
