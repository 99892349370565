import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';

import './TestCases.scss';
import { HeaderBar } from '../../../../containers/Shared';
import { ProblemTestCasesProps } from '../../../../containers/Judge/Problem';
import { ProblemTestsState } from '../../../../store/types';
import { APP_ROUTES } from '../../../../config/constans';
import {
  Add as AddIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Error as ErrorIcon,
  Save as SaveIcon,
  Visibility as VisibilityIcon
} from '@material-ui/icons';
import {
  ALERT_TYPES,
  EDITABLE,
  OK,
  PROBLEM_JUDGE_TYPE,
  PROBLEM_JUDGE_TYPE_SELECTOR,
  TEST_STATUS
} from '../../../../types';
import {
  serverDeleteProblemTest,
  serverGetProblemTest,
  serverGetProblemTests,
  serverUpdateProblemTest
} from '../../../../config/services/backend';
import { Modal } from '../../../Shared/Modal';
import { SelectorEditable } from '../../../Shared/SelectorEditable';
import { TextAreaEditable } from '../../../Shared/TextAreaEditable';
import { TextFieldEditable } from '../../../Shared/TextFieldEditable';
import { LabelField } from '../../../Shared/LabelField';

type EditingTest = { input: string, output: string, testId: number, group: number, type: 'sample' | 'case' };
type EditingBoxProps = {
  editTest: EditingTest | null, setEditTest: Function, updateProblemTests: Function, mode: PROBLEM_JUDGE_TYPE
}

const EditingBox = ({editTest, setEditTest, updateProblemTests, mode}: EditingBoxProps) => {
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    if (!editTest) {
      setEditable(false);
    }
  }, [editTest]);
  return editTest ? <div>
    <div className="list-test-cases">
      <div>
        {editTest.testId === -1 ? `Creating a new ${editTest.type}` : `Editing the ${editTest.testId} ${editTest.type}`}
      </div>
      {editable && <IconButton
          title={'Save Test'}
          onClick={async () => {
            const successfully = await updateProblemTests(editTest.type, editTest.testId, editTest.group, editTest.input, editTest.output);
            if (successfully) {
              setEditable(false);
            }
          }}>
          <SaveIcon/>
      </IconButton>}
      {!editable && <IconButton
          title={'Edit Test'}
          onClick={() => {
            setEditable(true);
          }}>
          <EditIcon/>
      </IconButton>}
      {mode === PROBLEM_JUDGE_TYPE.POINTS && (editable ? <TextFieldEditable
        label="Group"
        value={'' + editTest.group}
        onChange={({target}: React.ChangeEvent<HTMLInputElement>) => setEditTest((prevState: EditingTest) => (prevState ?
          {
            ...prevState,
            group: Number.isNaN(+target.value) ? 0 : +target.value
          } : null))
        }
        rowsMax={1}
      /> : <LabelField text={'' + editTest.group} clarification={'Group'}/>)}
      <IconButton
        title={'Cancel'}
        onClick={() => {
          setEditTest(null);
        }}>
        <CancelIcon/>
      </IconButton>
    </div>
    <div style={{display: 'inline-flex', width: '100%'}}>
      <TextAreaEditable
        placeholder="Put here the input"
        body={editTest.input}
        style={{width: 'calc(50%)'}}
        readOnly={!editable}
        rowsMax={20}
        onChangeBody={editable ? ({target}: React.ChangeEvent<HTMLTextAreaElement>) => setEditTest((prevState: EditingTest) => (prevState ?
          {
            ...prevState,
            input: target.value
          } : null)) : undefined
        }
      />
      <TextAreaEditable
        placeholder="Put here the output"
        body={editTest.output}
        style={{width: 'calc(50%)'}}
        readOnly={!editable}
        rowsMax={20}
        onChangeBody={editable ? ({target}: React.ChangeEvent<HTMLTextAreaElement>) => setEditTest((prevState: EditingTest) => (prevState ?
          {
            ...prevState,
            output: target.value
          } : null)) : undefined
        }
      />
    </div>
  </div> : null
};

export default ({problem, addNotice, addBlocking, addLoading, subBlocking, subLoading, putProblemTypeJudge}: ProblemTestCasesProps) => {
  const [editProblemTests, setEditProblemTests] = useState<ProblemTestsState>({
    typeJudge: {
      mode: PROBLEM_JUDGE_TYPE.TOTAL,
      groupsPoint: []
    },
    samples: [],
    testCases: []
  });
  const [editTest, setEditTest] = useState<EditingTest | null>(null);
  const [editGroups, setEditGroups] = useState<boolean>(false);
  const getData = useCallback(async (loading: boolean) => {
    if (loading) {
      addBlocking();
      addLoading();
    }
    const request = await serverGetProblemTests(problem.id);
    if (request.ok === OK) {
      const groups: { [key: number]: { id: number, points: number } } = {};
      for (const sample of request.result.samples) {
        groups[sample.group] = {
          id: sample.group,
          points: (request.result.typeJudge.groupsPoint.find((group) => group.id === sample.group) || {points: 0}).points
        };
      }
      for (const tests of request.result.testCases) {
        groups[tests.group] = {
          id: tests.group,
          points: (request.result.typeJudge.groupsPoint.find((group) => group.id === tests.group) || {points: 0}).points
        };
      }
      setEditProblemTests({
        ...request.result,
        typeJudge: {
          ...request.result.typeJudge,
          groupsPoint: Object.values(groups)
        }
      });
      addNotice({type: ALERT_TYPES.SUCCESS, label: 'Successfully tests loaded'})
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: request.message})
    }
    if (loading) {
      subLoading();
      subBlocking();
    }
  }, [addBlocking, addLoading, addNotice, problem.id, subBlocking, subLoading]);
  useEffect(() => {
    getData(true);
  }, [getData]);
  const updateProblemTest = useCallback(async (type, id, group, input, output) => {
    addBlocking();
    addLoading();
    const request = await serverUpdateProblemTest(type, problem.id, id, group, input, output);
    let successfully = false;
    if (request.ok === OK) {
      addNotice({type: ALERT_TYPES.SUCCESS, label: request.result.message});
      successfully = true;
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: request.message})
    }
    await getData(false);
    subLoading();
    subBlocking();
    return successfully;
  }, [addBlocking, addLoading, addNotice, getData, problem.id, subBlocking, subLoading]);
  const deleteTest = useCallback(async (type: 'sample' | 'case', id: number) => {
    addBlocking();
    addLoading();
    const request = await serverDeleteProblemTest(type, problem.id, id);
    if (request.ok === OK) {
      addNotice({type: ALERT_TYPES.SUCCESS, label: 'Successfully test deleted'})
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: request.message})
    }
    await getData(true);
    subLoading();
    subBlocking();
  }, [addBlocking, addLoading, addNotice, getData, problem.id, subBlocking, subLoading]);
  const handleEditTest = useCallback(async (type, id: number, group: number) => {
    setEditTest(null);
    addLoading();
    if (id === -1) {
      setEditTest({input: '', output: '', group: 0, type: type, testId: -1});
    } else {
      const request = await serverGetProblemTest(type, problem.id, id);
      if (request.ok === OK) {
        setEditTest({...request.result, group, type: type, testId: id});
        addNotice({type: ALERT_TYPES.SUCCESS, label: 'Successfully test loaded'});
      } else {
        addNotice({type: ALERT_TYPES.ERROR, label: request.message})
      }
    }
    subLoading();
  }, [addLoading, addNotice, problem.id, subLoading]);
  const handleSaveGroups = useCallback(async (typeJudge) => {
    await putProblemTypeJudge({id: problem.id, typeJudge});
    setEditGroups(false);
  }, [problem.id, putProblemTypeJudge]);
  return (
    <div>
      <HeaderBar
        title="Editing Test Cases"
        actions={[
          <Link to={APP_ROUTES.ROOT.JUDGE.PROBLEM.VIEW(problem.id)}>
            <IconButton title={'Cancel'}><CancelIcon/></IconButton>
          </Link>
        ]}
      />
      <div>
        <div className="list-test-cases">
          <SelectorEditable
            editable={EDITABLE}
            selectorData={PROBLEM_JUDGE_TYPE_SELECTOR}
            value={editProblemTests.typeJudge.mode}
            onChange={async (value: { value: PROBLEM_JUDGE_TYPE }) => {
              setEditProblemTests({...editProblemTests, typeJudge: {...editProblemTests.typeJudge, mode: value.value}});
              await handleSaveGroups({...editProblemTests.typeJudge, mode: value.value});
            }}
          />
        </div>
        <EditingBox
          editTest={editTest}
          setEditTest={setEditTest}
          updateProblemTests={updateProblemTest}
          mode={editProblemTests.typeJudge.mode}
        />
        <div className="list-test-cases">
          <Typography variant="h6">Samples</Typography>
          <IconButton
            title={'View test'}
            onClick={() => handleEditTest('sample', -1, 0)}
          >
            <AddIcon/>
          </IconButton>
        </div>
        {editProblemTests.samples.map((sample) => {
          return (
            <div className="list-test-cases" key={sample.id}>
              {editProblemTests.typeJudge.mode === PROBLEM_JUDGE_TYPE.POINTS && <div>Group {sample.group}</div>}
              <div className="list-test-cases-title">
                Test {sample.id}
                {sample.status === TEST_STATUS.CORRUPT &&
                <IconButton title={'Not found on judge, Edit & Save de test.'}>
                    <ErrorIcon/>
                </IconButton>}
              </div>
              <IconButton
                title={'View test'}
                onClick={() => handleEditTest('sample', sample.id, sample.group)}
              >
                <VisibilityIcon/>
              </IconButton>
              <Modal
                button={<IconButton title={'Delete Test'}><DeleteIcon/></IconButton>}
                title="Deleting Test"
                actions={[
                  <Button key="delete-source-action" onClick={() => deleteTest('sample', sample.id)}>
                    Delete
                  </Button>]}>
                <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                  <Grid item>
                    Are you sure to delete it?
                  </Grid>
                </Grid>
              </Modal>
            </div>
          )
        })}
        <div className="list-test-cases">
          <Typography variant="h6">Test Cases</Typography>
          <IconButton
            title={'View test'}
            onClick={() => handleEditTest('case', -1, 0)}
          >
            <AddIcon/>
          </IconButton>
        </div>
        {editProblemTests.testCases.map((test) => {
          return (
            <div className="list-test-cases" key={test.id}>
              {editProblemTests.typeJudge.mode === PROBLEM_JUDGE_TYPE.POINTS && <div>Group {test.group}</div>}
              <div className="list-test-cases-title">
                Test {test.id}
                {test.status === TEST_STATUS.CORRUPT &&
                <IconButton title={'Not found on judge, Edit & Save de test.'}>
                    <ErrorIcon/>
                </IconButton>}
              </div>
              <IconButton
                title={'View test'}
                onClick={() => handleEditTest('case', test.id, test.group)}
              >
                <VisibilityIcon/>
              </IconButton>
              <Modal
                button={<IconButton title={'Delete Test'}><DeleteIcon/></IconButton>}
                title="Deleting Test"
                actions={[
                  <Button key="delete-source-action" onClick={() => deleteTest('case', test.id)}>
                    Delete
                  </Button>]}>
                <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                  <Grid item>
                    Are you sure to delete it?
                  </Grid>
                </Grid>
              </Modal>
            </div>
          )
        })}
        {editProblemTests.typeJudge.mode === PROBLEM_JUDGE_TYPE.POINTS &&
        <>
            <div className="list-test-cases">
                <Typography variant="h6">Groups</Typography>
              {editGroups ?
                <IconButton
                  title={'Save groups'}
                  onClick={() => handleSaveGroups(editProblemTests.typeJudge)}
                >
                  <SaveIcon/>
                </IconButton> :
                <IconButton
                  title={'Edit groups'}
                  onClick={() => setEditGroups(true)}
                >
                  <EditIcon/>
                </IconButton>
              }
            </div>
          {editProblemTests.typeJudge.groupsPoint.map((group) => {
            return (
              <div className="list-test-cases" key={group.id}>
                <div className="list-test-cases-title">
                  Group {group.id}
                </div>
                {editGroups ?
                  <TextFieldEditable
                    label="Group"
                    value={'' + group.points}
                    onChange={({target}: React.ChangeEvent<HTMLInputElement>) => setEditProblemTests(prevState => ({
                      ...prevState,
                      typeJudge: {
                        ...prevState.typeJudge,
                        groupsPoint: prevState.typeJudge.groupsPoint.map(prevGroup => (
                          prevGroup.id === group.id ? {id: group.id, points: +target.value} : prevGroup
                        ))
                      }
                    }))}
                    rowsMax={1}
                  /> : <LabelField text={`Points ${group.points}`}/>}
              </div>
            )
          })}
        </>}
      </div>
    </div>
  )
    ;
}
