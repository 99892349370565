import React from 'react';
import {
  List as ListIcon,
  Gavel as GavelIcon,
  BarChart as BarChartIcon,
  Dehaze as DehazeIcon
} from '@material-ui/icons';

import NavBarList from './NavBarList';
import { UserState } from '../../../store/types';
import { APP_ROUTES } from '../../../config/constans';

export default ({user, visible}: { user: UserState, visible: boolean }): JSX.Element => {
  const items = [
    {
      icon: <ListIcon/>,
      title: 'Problem',
      link: APP_ROUTES.ROOT.JUDGE.PROBLEM.LIST
    }];
  if (user.isLogged) {
    items.push({
      icon: <DehazeIcon/>,
      title: 'Status',
      link: APP_ROUTES.ROOT.JUDGE.STATUS.LIST
    });
  }
  items.push({
    icon: <BarChartIcon/>,
    title: 'Ranking',
    link: APP_ROUTES.ROOT.JUDGE.RANK.LIST
  });
  const head = {
    icon: <GavelIcon/>,
    title: 'Judge'
  };
  return <NavBarList visible={visible} head={head} items={items}/>;
};
