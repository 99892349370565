import React from 'react';
import {
  Menu as MenuIcon
} from '@material-ui/icons';
import {
  Drawer, ListItem
} from '@material-ui/core';
import clsx from 'clsx';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import AdminList from '../../containers/Navbar/Tabs/AdminList';
import { ROLES } from '../../config/constans';
import UserList from '../../containers/Navbar/Tabs/UserList';
import { useStyles } from '../../config/styles';
import TrainingList from '../../containers/Navbar/Tabs/TrainingList';
import Judge from '../../containers/Navbar/Tabs/Judge';
import CodeEditor from '../../containers/Navbar/Tabs/Utils';
import { UserState } from '../../store/types';

type Props = {
  user: UserState,
  open: boolean,
  setOpen: Function
}
export default ({user, open, setOpen}: Props) => {
  const classes = useStyles();
  return (
    <Drawer
      variant="permanent"
      open={open}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
      // onClick={(event) => (open ? event.preventDefault() : setOpen(true))}
    >
      <ListItem button onClick={() => setOpen((prevState: boolean) => !prevState)} component={'li'}
                className={classes.drawerMenuIcon}>
        <ListItemIcon>
          {open ? <MenuIcon/> : <MenuIcon/>}
        </ListItemIcon>
      </ListItem>
      <div className={classes.drawerMenuList}>
        <div style={{direction: 'ltr'}}>
          {user.role === ROLES.ADMIN || user.role === ROLES.SUPER_ADMIN ? <AdminList visible={open}/> : null}
          <Judge visible={open}/>
          {user.role !== ROLES.GUEST ? <TrainingList visible={open}/> : null}
          <CodeEditor visible={open}/>
          <UserList visible={open}/>
        </div>
      </div>
      {/*<div style={{position: 'absolute', bottom: '0', width: '100%'}}>*/}
      {/*  <Link to={{pathname: '/'}} onClick={(event) => (open ? null : event.preventDefault())}>*/}
      {/*    <ListItem button onClick={() => {*/}
      {/*    }} component={'li'}>*/}
      {/*      <ListItemIcon>*/}
      {/*        <HomeIcon/>*/}
      {/*      </ListItemIcon>*/}
      {/*      {open ? <ListItemText primary={'Home'} className={classes.title}/> : null}*/}
      {/*    </ListItem>*/}
      {/*  </Link>*/}
      {/*</div>*/}
    </Drawer>
  );
}
