import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Button, Grid, IconButton } from '@material-ui/core';
import {
  Delete as DeleteIcon, Edit as EditIcon,
  FileCopy as FileCopyIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@material-ui/icons';
import copy from 'copy-to-clipboard';

import { useStyles } from '../../../config/styles';
import { LabelField, Modal, TextFieldEditable, TitleBox } from '../../Shared';
import { APP_ROUTES, PRINT_SCOPE_VISIBILITY } from '../../../config/constans';
import { ALERT_TYPES } from '../../../types';
import ChangePassword from '../../../containers/Graph/ChangePassword';
import { GraphViewerProps } from '../../../containers/Graph';
import GraphAnimation from './GraphAnimation';
import { useEventListener } from '../../../helpers/customHooks';
import { HeaderBar } from '../../../containers/Shared';
import { Link } from 'react-router-dom';

export default ({user, graph, addNotice, deleteGraph}: GraphViewerProps) => {
  const classes = useStyles();
  const [password, setPassword] = useState<string>(graph.password);
  const [visibility, setVisibility] = useState<boolean>(false);
  useEffect(() => {
    if (graph.password) {
      setPassword(graph.password);
    }
    setVisibility(false);
  }, [graph]);
  const copyToClipboard = () => {
    copy(password);
    addNotice({type: ALERT_TYPES.INFO, label: 'Copied!'});
  };
  const titleRef = useRef(null);
  const [titleHeight, setTitleHeight] = useState(0);
  useEffect(() => {
    if (titleRef && titleRef.current) {
      // @ts-ignore
      setTitleHeight(+titleRef.current.clientHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEventListener('resize', () => {
    if (titleRef && titleRef.current) {
      // @ts-ignore
      setTitleHeight(+titleRef.current.clientHeight);
    }
  });
  const actions = [];
  if (user.nickname === graph.ownerNickname) {
    actions.push(<ChangePassword/>,
      <Modal
        button={<IconButton title={'Delete'}><DeleteIcon/></IconButton>}
        title="Deleting Code"
        actions={[
          <Button key="delete-source-action" onClick={() => deleteGraph()}>
            Delete
          </Button>]}>
        <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
          <Grid item>
            Are you sure to delete it?
          </Grid>
        </Grid>
      </Modal>
    );
  }
  return (
    <>
      <HeaderBar
        title="Graph Viewer"
        back={APP_ROUTES.ROOT.GRAPH.LIST}
        actions={[...actions,
          <Link to={{pathname: APP_ROUTES.ROOT.GRAPH.EDIT(graph.id), state: {password: password}}}>
            <IconButton title={'Edit Graph'}><EditIcon/></IconButton>
          </Link>
        ]}
      />
      <div className={clsx(classes.pageMaxHeight, classes.boxShadow)}>
        <TitleBox
          body={
            <div style={{height: `calc(100% - ${titleHeight}px)`}}>
              <GraphAnimation graphs={graph.graph}/>
            </div>
          }
          title={
            <Grid
              container
              justify="space-between"
              alignItems="center"
              className={classes.maxDivSize}
              ref={titleRef}
            >
              <Grid item>
                <LabelField text={graph.name} clarification={''}/>
              </Grid>
              <Grid item>
                <LabelField text={PRINT_SCOPE_VISIBILITY[graph.type]} clarification={'Visibility'}/>
              </Grid>
              <Grid item>
                <LabelField text={graph.ownerNickname} clarification={'Owner'}/>
              </Grid>
              <Grid item>
                <LabelField text={graph.tags.join(', ')} clarification={'Tags'}/>
              </Grid>
              <Grid item>
                <div style={{display: 'flex'}}>
                  <TextFieldEditable
                    label="Password"
                    value={password}
                    onChange={(event: any) => setPassword(event.target.value)}
                    isPassword={!visibility}
                  />
                  <IconButton onClick={copyToClipboard}>
                    <FileCopyIcon/>
                  </IconButton>
                  <IconButton onClick={() => setVisibility(prevState => !prevState)}>
                    {visibility ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          }
        />
      </div>
    </>
  );
};
