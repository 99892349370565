import React, { useState } from 'react';
import {
  Button
} from '@material-ui/core';

import { Modal } from '../../../Shared';
import { PageStateType } from '../../../../types';
import EditCategoryBody from './EditCategoryBody';

type Props = {
  // props
  button: JSX.Element,
  category: PageStateType,
  action: Function,
}

export default ({button, category, action}: Props) => {
  const [categoryNew, setCategoryNew] = useState<PageStateType>(category);
  return (
    <Modal button={button}
           title={`Editing the ${category.title} category`} actions={[
      <Button key="edit-category-action" onClick={() => action(categoryNew, category)}>
        Update
      </Button>]} onCancel={() => setCategoryNew(category)} persistent>
      <EditCategoryBody category={categoryNew} setCategory={setCategoryNew}/>
    </Modal>
  );
}
