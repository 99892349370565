import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Viewer from '../../components/Graph/Viewer';
import { UserState, GraphState, Message } from '../../store/types';
import { addNotice, deleteGraph, getGraph } from '../../store/actions';
import Loader from '../../components/HOC/Loader';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    user: state.user,
    graph: state.graphs[ownProps.match.params.graphId]
  }
};

const mapDispatchToProps = (dispatch: Function, ownProps: any) => {
  return {
    addNotice: (notice: Message) => {
      dispatch(addNotice(notice));
    },
    getGraph: async () => {
      await dispatch(getGraph(ownProps.match.params.graphId, true));
    },
    deleteGraph: async () => {
      dispatch(deleteGraph(ownProps.match.params.graphId));
    }
  }
};

export type GraphViewerProps = {
  // state
  user: UserState,
  graph: GraphState,
  // dispatch
  addNotice: Function,
  getGraph: Function,
  deleteGraph: Function,
}

export const GraphViewer = connect(mapStateToProps, mapDispatchToProps)(withRouter(Loader(Viewer,
  async (props: GraphViewerProps) => {
    props.getGraph();
  }, (props: GraphViewerProps) => { // when to click back
    return !!props.graph && !!props.graph.graph
  })));
