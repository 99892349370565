export enum GOOGLE {
  CLIENT_ID = '649589698934-1nsiu2vkbph9pqg6lrj8dij2hhv7i7h6.apps.googleusercontent.com',
}

export const secRoutes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => ('sec' + value));

export const INDEX: any = {
  main: [
    {title: 'Estructura de Datos', path: 'ed'},
    {title: 'Grafos', path: 'grafos'}],
  ed: [
    {title: 'Union-Find Disjoint Sets', path: 'ufds'},
    {title: 'Segment Tree', path: 'segment-tree'},
    {title: 'Sparse Table', path: 'sparse-table'},
    {title: 'Heap', path: 'heap'}],
  grafos: [
    {title: 'Recorridos', path: 'recorridos'},
    {title: 'Clasificación', path: 'clasificacion'},
    {title: 'Flujos', path: 'flujos'},
    {title: 'Caminos', path: 'caminos'}],
  recorridos: [
    {title: 'Depth First Search (DFS)', path: 'dfs'},
    {title: 'Breadth First Search (BFS)', path: 'bfs'}
  ]
};

export enum TYPES_CONTENTS {
  LATEX = 'LATEX',
  PDF = 'PDF',
  MARK_DOWN = 'MARK_DOWN',
  PLAIN_TEXT = 'PLAIN_TEXT',
}

export const CONTENT = {
  main: {
    title: 'Contenido',
    pathContent: '/latex/heap.tex',
    typeContent: TYPES_CONTENTS.LATEX
  }
};

export enum ROLES {
  ADMIN = 'admin',
  SUPER_ADMIN = 'superAdmin',
  USER = 'user',
  GUEST = 'guest',
}

export const PRINT_ROLES = {
  [ROLES.SUPER_ADMIN]: 'Admin',
  [ROLES.ADMIN]: 'Assistant',
  [ROLES.USER]: 'User',
  [ROLES.GUEST]: 'Guest'
};

export enum SCOPE_VISIBILITY {
  PUBLIC = 'public',
  PRIVATE = 'private',
  PERSONAL = 'personal'
}

export const PRINT_SCOPE_VISIBILITY = {
  [SCOPE_VISIBILITY.PUBLIC]: 'Public',
  [SCOPE_VISIBILITY.PRIVATE]: 'Private',
  [SCOPE_VISIBILITY.PERSONAL]: 'Personal'
};

export const MAX_RATING = 4000;
export const RATING_SELECTOR = {
  values: [600, 700, 1000, 1500, 2000, 2500, 3000, 3500, MAX_RATING].map((rating) => {
    return {value: rating, name: `< ${rating}`};
  }),
  minWidth: 100,
  title: 'Rating'
};

export enum STATUS {
  LIVE = 'LIVE',
  PAST = 'PAST',
  FUTURE = 'FUTURE'
}

export enum VERDICTS {
  FAILED = 'FAILED',
  OK = 'OK',
  PARTIAL = 'PARTIAL',
  COMPILATION_ERROR = 'COMPILATION_ERROR',
  RUNTIME_ERROR = 'RUNTIME_ERROR',
  WRONG_ANSWER = '  WRONG_ANSWER',
  PRESENTATION_ERROR = 'PRESENTATION_ERROR',
  TIME_LIMIT_EXCEEDED = 'TIME_LIMIT_EXCEEDED',
  MEMORY_LIMIT_EXCEEDED = 'MEMORY_LIMIT_EXCEEDED',
  IDLENESS_LIMIT_EXCEEDED = 'IDLENESS_LIMIT_EXCEEDED',
  SECURITY_VIOLATED = 'SECURITY_VIOLATED',
  CRASHED = '  CRASHED',
  INPUT_PREPARATION_CRASHED = 'INPUT_PREPARATION_CRASHED',
  CHALLENGED = 'CHALLENGED',
  SKIPPED = 'SKIPPED',
  TESTING = 'TESTING',
  REJECTED = 'REJECTED',
  //
  UN_SUBMIT = 'UN_SUBMIT',
  JUDGING = 'JUDGING',
}

export const PRINT_VERDICTS: { [key in VERDICTS]: string } = {
  [VERDICTS.FAILED]: 'FAILED',
  [VERDICTS.OK]: 'Accepted',
  [VERDICTS.PARTIAL]: 'Partial',
  [VERDICTS.COMPILATION_ERROR]: 'CE',
  [VERDICTS.RUNTIME_ERROR]: 'RE',
  [VERDICTS.WRONG_ANSWER]: '  WA',
  [VERDICTS.PRESENTATION_ERROR]: 'PE',
  [VERDICTS.TIME_LIMIT_EXCEEDED]: 'TLE',
  [VERDICTS.MEMORY_LIMIT_EXCEEDED]: 'MLE',
  [VERDICTS.IDLENESS_LIMIT_EXCEEDED]: 'IDLENESS_LIMIT_EXCEEDED',
  [VERDICTS.SECURITY_VIOLATED]: 'SECURITY_VIOLATED',
  [VERDICTS.CRASHED]: '  CRASHED',
  [VERDICTS.INPUT_PREPARATION_CRASHED]: 'INPUT_PREPARATION_CRASHED',
  [VERDICTS.CHALLENGED]: 'CHALLENGED',
  [VERDICTS.SKIPPED]: 'SKIPPED',
  [VERDICTS.TESTING]: 'TESTING',
  [VERDICTS.REJECTED]: 'REJECTED',
  //
  [VERDICTS.UN_SUBMIT]: 'no submit',
  [VERDICTS.JUDGING]: 'Judging'
};

export const defaultProblemNames = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

export const APP_ROUTES = {
  RADIX: '/',
  ROOT: {
    LOGIN:'/login',
    ADMIN: {
      BLOG: '/admin/blog',
      USERS: '/admin/users'
    },
    GRAPH: {
      LIST: '/graphic/',
      NEW: '/graphic/new',
      EDIT: (id: string) => (`/graph/edit/${id}`),
      VIEW: (id: string) => (`/graph/view/${id}`)
    },
    CODE: {
      LIST: '/code/',
      NEW: '/code/new',
      EDIT: (id: string) => (`/code/edit/${id}`),
      VIEW: (id: string) => (`/code/view/${id}`)
    },
    JUDGE: {
      PROBLEM: {
        LIST: '/judge/problem',
        NEW: '/judge/problem/new',
        EDIT: (id: number | ':problemId') => (`/judge/problem/edit/${id}`),
        TESTS: (id: number | ':problemId') => (`/judge/problem/tests/${id}`),
        VIEW: (id: number | ':problemId') => (`/judge/problem/view/${id}`),
        SUBMIT: (id: number | ':problemId') => (`/judge/problem/submit/${id}`)
      },
      STATUS: {
        LIST: '/judge/status',
        USER: (userNickname: string) => (`/judge/status/${userNickname}`)
      },
      ATTEMPT: {
        VIEW: (id: string) => (`/judge/attempt/view/${id}`)
      },
      RANK: {
        LIST: '/judge/rank'
      }
    },
    TRAIN: {
      CATEGORY: {
        LIST: '/train/category',
        TAG: (tag: string) => (`/train/category/${tag}`)
      },
      DIFFICULTY: '/train/difficulty',
      CONTEST: '/train/contest',
      ROOM: {
        LIST: '/train/room',
        VIEW: (id: string) => (`/train/room${id}`)
      },
      MATERIAL: {

      }
    },
    PROFILE: {
      SELF: '/profile',
      USER: (userNickname: string) => (`/profile/${userNickname}`)
    }
  }
};
