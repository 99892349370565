import {FLAGS_ACTIONS, FlagsActionTypes} from "../types/flags";

export const addLoading = () : FlagsActionTypes => {
  return {
    type: FLAGS_ACTIONS.ADDING_LOADING,
    count: 1
  }
};

export const subLoading = () : FlagsActionTypes => {
  return {
    type: FLAGS_ACTIONS.ADDING_LOADING,
    count: -1
  }
};

export const addBlocking = () : FlagsActionTypes => {
  return {
    type: FLAGS_ACTIONS.ADDING_BLOCKING,
    count: 1
  }
};

export const subBlocking = () : FlagsActionTypes => {
  return {
    type: FLAGS_ACTIONS.ADDING_BLOCKING,
    count: -1
  }
};
