import { connect } from 'react-redux';

import NewCode from '../../components/CodeEditor/NewCode';
import { createNewCode } from '../../store/actions/codes';
import { NewCodeState } from '../../store/types/codes';

export const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  }
};

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    createNewSource: (newSource: NewCodeState) => {
      dispatch(createNewCode(newSource));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCode);
