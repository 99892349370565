import {FLAGS_ACTIONS, FlagsActionTypes, FlagsState} from "../types/flags";

export const flags = (state = {loading: 0, blocking: 0}, action: FlagsActionTypes): FlagsState => {
  switch (action.type) {
    case FLAGS_ACTIONS.ADDING_LOADING:
      return {...state, loading: state.loading + action.count};
    case FLAGS_ACTIONS.ADDING_BLOCKING:
      return {...state, blocking: state.blocking + action.count};
    default:
      return state;
  }
};
