import {connect} from 'react-redux';

import Rank from "../../../components/Judge/Rank/Rank";

export const mapStateToProps = (state: any, ownProps: any) => {
  return {}
};

export const mapDispatchToProps = (dispatch: Function, ownProps: any) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(Rank);

