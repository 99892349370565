import UserList from '../../../components/Navbar/Tabs/Account';
import { connect } from 'react-redux';
import { addNotice, logoutUser } from '../../../store/actions';
import { Message, RootStateType } from '../../../store/types';

const mapStateToProps = (state: RootStateType) => {
  return {
    user: state.user
  }
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logoutGoogle: () => {
      dispatch(logoutUser());
    },
    addNotice(notice: Message) {
      dispatch(addNotice(notice));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);

