import React from 'react';
import { useEffect, useState } from 'react';
import { HeaderBar } from '../../containers/Shared';

export default (Component: any, preview: Function, isValid?: Function) => (props: any) => {
  const [did, setDid] = useState(false);
  useEffect(() => {
    preview(props).then(() => {
      setDid(true)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (did && (isValid ? isValid(props) : true)) {
    return <Component {...props}/>;
  }
  return <HeaderBar title=""/>;
};
