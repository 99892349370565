import React, { useCallback, useEffect, useState } from 'react';
import {
  Edit as EditIcon
} from '@material-ui/icons';
import {
  IconButton
} from '@material-ui/core';

import './Users.css';
import { serverGetAllUsers } from '../../../config/services/backend';
import NewUser from '../../../containers/Admin/AddUser';
import { OK, ALERT_TYPES } from '../../../types';
import { UserState } from '../../../store/types';
import EditUser from '../../../containers/Account/EditUser';
import { AppTable, BodyRowsType, HeadCellsType } from '../../Shared';
import DeleteUser from '../../../containers/Admin/DeleteUser';
import { HeaderBar } from '../../../containers/Shared';
import { APP_ROUTES, PRINT_ROLES } from '../../../config/constans';

type Props = {
  // dispatch
  addNotice: Function,
  updateUser: Function
}
export default ({addNotice, updateUser}: Props) => {
  const [users, setUsers] = useState([]);
  const getData = useCallback(async () => {
    const request = await serverGetAllUsers();
    if (request.ok === OK) {
      request.result.sort((userA: UserState, userB: UserState) => userA.name.localeCompare(userB.name));
      setUsers(request.result);
      addNotice({type: ALERT_TYPES.SUCCESS, label: 'Successfully users loaded'});
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: request.message});
    }
  }, [addNotice]);
  useEffect(() => {
    getData();
  }, [getData]);
  const headCells: HeadCellsType = [
    {type: 'string', align: 'center', label: 'Name'},
    {type: 'string', align: 'center', label: 'Email'},
    {type: 'string', align: 'center', label: 'Rol'},
    {type: 'string', align: 'right', label: 'Nickname'},
    {type: 'array', align: 'right', label: 'Ops'}
  ];
  const bodyRows: BodyRowsType = users.map((user: UserState) => {
    const rows = [
      {
        value: user.name + user.givenName + user.familyName,
        link: APP_ROUTES.ROOT.PROFILE.USER(user.nickname),
        print: <>{user.name}<br/>{user.givenName + ' ' + user.familyName}</>
      },
      {value: user.email},
      {value: PRINT_ROLES[user.role]},
      {value: user.nickname, link: APP_ROUTES.ROOT.PROFILE.USER(user.nickname)},
      {
        value: <>
          <EditUser button={<IconButton><EditIcon/></IconButton>} getData={getData} userToEdit={user}/>
          <DeleteUser user={user} getData={getData}/>
        </>,
        style: {width: '60px'}
      }
    ];
    return {
      rows,
      id: user.email
    }
  });
  return (
    <>
      <HeaderBar title="Users" actions={[<NewUser getData={getData}/>]}/>
      <AppTable headCells={headCells} bodyRows={bodyRows}/>
    </>
  );
}
