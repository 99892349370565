export interface FlagsState {
  loading: number,
  blocking: number
}

export enum FLAGS_ACTIONS {
  ADDING_LOADING = 'ADDING_LOADING',
  ADDING_BLOCKING = 'ADDING_BLOCKING',
}

interface AddingLoadingAction {
  type: FLAGS_ACTIONS.ADDING_LOADING,
  count: number,
}

interface AddingBlockingAction {
  type: FLAGS_ACTIONS.ADDING_BLOCKING,
  count: number
}

export type FlagsActionTypes = AddingLoadingAction | AddingBlockingAction;
