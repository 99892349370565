import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Viewer from '../../../components/Judge/Problem/Viewer';
import Loader from '../../../components/HOC/Loader';
import { ProblemState, UserState } from '../../../store/types';
import { deleteProblem, getProblem } from '../../../store/actions';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    user: state.user,
    problem: state.problems[ownProps.match.params.problemId]
  }
};

const mapDispatchToProps = (dispatch: Function, ownProps: any) => {
  return {
    getProblem: async () => {
      await dispatch(getProblem(ownProps.match.params.problemId));
    },
    deleteProblem: async () => {
      dispatch(deleteProblem(ownProps.match.params.problemId));
    },
  }
};

export type ProblemViewerProps = {
  // state
  user: UserState,
  problem: ProblemState,
  // dispatch
  getProblem: Function,
  deleteProblem: Function,
}

export const ProblemViewer = connect(mapStateToProps, mapDispatchToProps)(withRouter(Loader(Viewer,
  async (props: ProblemViewerProps) => {
    props.getProblem();
  }, (props: ProblemViewerProps) => { // when to click back
    return !!props.problem && !!props.problem.description
  })));
