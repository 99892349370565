import { connect } from 'react-redux';

import User from '../../components/Account/User';
import { addNotice } from '../../store/actions';
import { Message } from '../../store/types/notices';
import Loader from '../../components/HOC/Loader';
import { Props } from '../../components/Account/User';
import { getUserAccount } from '../../store/actions/users';
import { withRouter } from 'react-router';
import { RootStateType } from '../../store/types';

const mapStateToProps = (state: RootStateType, ownProps: any) => {
  return {
    user: state.users[ownProps.match.params.userNickname]
  }
};

const mapDispatchToProps = (dispatch: Function, ownProps: any) => {
  return {
    addNotice: (notice: Message) => {
      dispatch(addNotice(notice));
    },
    getUserAccount: async () => {
      await dispatch(getUserAccount(ownProps.match.params.userNickname));
    }
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(Loader(withRouter(User),
  async (props: Props) => {
    if (props.user) {
      props.getUserAccount();
    } else {
      await props.getUserAccount();
    }
  }));
