export enum ALERT_TYPES {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  CLOCK_LIVE = 'CLOCK_LIVE',
  CLOCK_FUTURE = 'CLOCK_FUTURE'
}

export const ALERT_DURATIONS: { [key in ALERT_TYPES.ERROR | ALERT_TYPES.INFO | ALERT_TYPES.WARNING | ALERT_TYPES.SUCCESS]: number } = {
  [ALERT_TYPES.ERROR]: 5000,
  [ALERT_TYPES.WARNING]: 4000,
  [ALERT_TYPES.INFO]: 5000,
  [ALERT_TYPES.SUCCESS]: 3000,
};
