import {NOTICES_ACTIONS, NoticesActionTypes, NoticeState} from '../types';

export const notices = (state = [], action: NoticesActionTypes): Array<NoticeState> => {
  const newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case NOTICES_ACTIONS.ADD_NOTICE:
      newState.push(action.notice);
      return newState;
    case NOTICES_ACTIONS.REMOVE_NOTICE:
      return newState.filter((notice: NoticeState) => notice.index !== action.index);
    default:
      return state;
  }
};
