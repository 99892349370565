import React from 'react';
import { useParams, useRouteMatch } from 'react-router';

import { getIndexList } from '../../../../helpers/functions';
import { secRoutes } from '../../../../config/constans';
import RouteSelector from './RouteSelector';
import ViewerContent from './ViewerContent';
import { HeaderBar } from '../../../../containers/Shared';

export default () => {
  const {url} = useRouteMatch();
  const params = useParams();
  const endParam = secRoutes.reduce((result, value) => {
    if (params[value]) {
      return params[value];
    }
    return result;
  }, 'main');
  return (
    <>
      <HeaderBar title="Material" actions={[]}/>
      <RouteSelector/>
      <div>
        <h1>Índice</h1>
        {getIndexList(endParam, url)}
      </div>
      <ViewerContent section={endParam}/>
    </>
  );
}
