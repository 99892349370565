import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  Cancel as CancelIcon,
  Save as SaveIcon
} from '@material-ui/icons';

import { APP_ROUTES, SCOPE_VISIBILITY } from '../../../config/constans';
import { GRAPH_EDGE_TYPE, LABEL_NODE_POSITIONS } from '../../../types';
import { UserState, NewGraphState } from '../../../store/types';
import { HeaderBar } from '../../../containers/Shared';
import Body from '../Editor/Body';

type Props = {
  user: UserState,
  createNewGraph: Function
}

export default ({user, createNewGraph}: Props) => {
  const [newGraph, setNewGraph] = useState<NewGraphState>({
    name: '',
    description: '',
    type: SCOPE_VISIBILITY.PUBLIC,
    graph: [{
      nodes: {}, edges: {}, config: {
        nodes: {
          background: 'white',
          borderColor: 'black',
          borderWidth: 2,
          radio: 16,
          labels: [
            {
              color: 'black',
              position: LABEL_NODE_POSITIONS.CENTER,
              default: '_INDEX_',
              size: 14,
              name: 'index'
            }
          ]
        },
        edgeType: GRAPH_EDGE_TYPE.DIRECTED,
        edges: {
          color: 'black',
          width: 3,
          labels: []
        }
      }
    }],
    tags: []
  });
  return (
    <>
      <HeaderBar
        title="Graph Editor"
        actions={[
          <IconButton title={'Save'} onClick={() => createNewGraph(newGraph)}><SaveIcon/>
          </IconButton>,
          <Link to={APP_ROUTES.ROOT.GRAPH.LIST}><IconButton title={'Cancel'}><CancelIcon/></IconButton></Link>
        ]}
      />
      <Body user={user} graphic={newGraph} setGraphic={setNewGraph}/>
    </>
  );
}
