// import React, {useEffect, useState} from 'react';
// import {useParams} from "react-router";
// import {Link} from "react-router-dom";
// import {
//   TableHead,
//   TableRow,
//   Table,
//   Grid,
//   TableCell,
//   TableBody,
//   TextField,
//   ExpansionPanel,
//   ExpansionPanelSummary,
//   ExpansionPanelDetails, IconButton
// } from "@material-ui/core";
// import {
//   AddCircle as AddCircleIcon, Cancel as CancelIcon, Delete as DeleteIcon, Edit as EditIcon,
//   ExpandMore as ExpandMoreIcon,
//   Link as LinkIcon,
//   Save as SaveIcon,
//   Search as SearchIcon,
//   PlaylistAdd as PlaylistAddIcon,
//   Update as UpdateIcon,
// } from '@material-ui/icons';
// import * as DateFns from 'date-fns';
// import {
//   MuiPickersUtilsProvider,
//   DateTimePicker,
// } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
//
// import {
//   defaultProblemNames, PRINT_VERDICTS,
//   RATING_SELECTOR,
//   STATUS,
//   VERDICTS
// } from "../../../../Config/constans";
// import {
//   codeforcesProblems,
//   codeforcesSubmissions
// } from '../../../../Config/services.codeforces';
// import {
//   serverCreateNewPractice, serverDeletePractice,
//   serverGetPracticesRoom,
//   serverGetRoom, serverGetUsersRoom, serverUpdatePractice,
// } from "../../../../Config/services";
// import Clock from "../../../Shared/Clock";
// import SelectorEditable from "../../../Shared/SelectorEditable";
// import TextFieldEditable from "../../../Shared/TextFieldEditable/TextFieldEditable";
// import {getAllCodeforcesTags, isSuperAdmin} from "../../../../helpers/functions";
// import TableRowStyled from "../../../Shared/TableRowStyled";
// import {LOADER_STATE} from "../../../../store/types/loader";
// import LinearLoader from "../../../Shared/LinearLoader";
// import Alert from "../../../Shared/Alert/Alert";
// import {ALERT_TYPES} from "../../../Shared/types";
//
// const SELECT_RANDOM = 'SELECT RANDOM';
// const ALL = 'ALL';
// const NO_PROBLEM_FOUND = 'NO PROBLEM FOUND';

export default ({
                  user, loader,
                  updateLoader
                }) => {
  // const params = useParams();
  // const [roomData, setRoomData] = useState({members: []});
  // const [practices, setPractices] = useState([]);
  // const [allTags, setAllTags] = useState([ALL]);
  // const [practiceNew, setPracticeNew] = useState(
  //   {
  //     date: new Date(),
  //     problems: [],
  //     selectedProblem: {
  //       tag: {value: ALL, name: ALL},
  //       rating: RATING_SELECTOR.values[0],
  //       contestId: '',
  //       index: '',
  //       problem: {value: NO_PROBLEM_FOUND, name: NO_PROBLEM_FOUND}
  //     }
  //   });
  // const [practiceEdit, setPracticeEdit] = useState(null);
  // const [selectableProblems, setSelectableProblems] = useState([{value: NO_PROBLEM_FOUND, name: NO_PROBLEM_FOUND}]);
  // const [selectableProblemsEdit, setSelectableProblemsEdit] = useState([{
  //   value: NO_PROBLEM_FOUND,
  //   name: NO_PROBLEM_FOUND
  // }]);
  // const [roomUsers, setRoomUsers] = useState([]);
  // const getRoomData = async () => {
  //   const request = await serverGetRoom(params.id);
  //   if (request.OK) {
  //     if (request.result[0]) {
  //       setRoomData(request.result[0]);
  //     }
  //   }
  // };
  // const getPracticesData = async () => {
  //   const request = await serverGetPracticesRoom(params.id);
  //   if (request.OK) {
  //     request.result.sort((practiceA, practiceB) => new Date(practiceB.date).getTime() - new Date(practiceA.date).getTime());
  //     setPractices(request.result);
  //   }
  //
  // };
  // const getRoomUsers = async () => {
  //   const request = await serverGetUsersRoom(params.id);
  //   if (request.OK) {
  //     setRoomUsers(request.result);
  //   }
  // };
  // // COrrect this
  // const userCanEdit = isSuperAdmin(user)
  //   || (roomData.members.find((member) => member.userEmail === user.email) || {}).role === 'admin'
  //   || user.email === roomData.ownerUserEmail;
  // const thenAction = async () => {
  //   await getRoomData();
  //   await getPracticesData();
  //   await getRoomUsers();
  //
  // };
  //
  // useEffect(() => {
  //   const start = async () => {
  //     await thenAction();
  //     // setLoader({...loader, webPage: false}); // REFACTOR
  //   };
  //   start();
  // }, []);
  // useEffect(() => {
  //   async function thenAction() {
  //     let userCanEdit = isSuperAdmin(user)
  //       || (roomData.members.find((member) => member.userEmail === user.email) || {}).role === 'admin'
  //       || user.email === roomData.ownerUserEmail;
  //
  //     if (userCanEdit) {
  //       setAllTags([ALL, ...(await getAllCodeforcesTags())]);
  //     }
  //   }
  //
  //   thenAction();
  // }, [roomData, user]);
  //
  // const addPractice = async () => {
  //   updateLoader('addNewPractice', LOADER_STATE.PROGRESS);
  //   const newPractice = {
  //     date: practiceNew.date,
  //     name: practiceNew.name,
  //     duration: practiceNew.duration,
  //     problems: practiceNew.problems.map((problem) => {
  //       return {
  //         name: problem.name,
  //         url: problem.url,
  //         contestId: problem.contestId,
  //         index: problem.index,
  //         rating: problem.rating
  //       }
  //     }),
  //     roomId: params.id,
  //     submissions: []
  //   };
  //   await serverCreateNewPractice(newPractice);
  //   await getPracticesData();
  //   updateLoader('addNewPractice', LOADER_STATE.DONE);
  // };
  // const deletePractice = async (_id) => {
  //   await serverDeletePractice(_id);
  //   getPracticesData();
  // };
  // const updatePractice = async () => {
  //   await serverUpdatePractice({
  //     _id: practiceEdit._id,
  //     date: practiceEdit.date,
  //     name: practiceEdit.name,
  //     duration: practiceEdit.duration,
  //     problems: practiceEdit.problems.map((problem) => {
  //       return {
  //         name: problem.name,
  //         url: problem.url,
  //         contestId: problem.contestId,
  //         index: problem.index,
  //         rating: problem.raging
  //       }
  //     }),
  //     roomId: practiceEdit.roomId,
  //     submissions: practiceEdit.submissions
  //   });
  //   await getPracticesData();
  //   setPracticeEdit(null);
  // };
  // // Codeforces
  //
  // const searchProblem = async () => {
  //   updateLoader('searchProblem', LOADER_STATE.PROGRESS);
  //   const problems = await codeforcesProblems([practiceNew.selectedProblem.tag.value === ALL ? '' : practiceNew.selectedProblem.tag.value]);
  //   const filterProblems = problems.problems.filter((cfProblem) => {
  //     return +cfProblem.rating < +practiceNew.selectedProblem.rating.value &&
  //       !(practiceNew.problems.length > 0 && !!practiceNew.problems.find((problem) => problem.contestId === cfProblem.contestId && problem.index === cfProblem.index));
  //   });
  //   const mappedProblems = filterProblems.map((problem) => {
  //     return {
  //       value: {
  //         name: problem.name,
  //         url: `https://codeforces.com/problemset/problem/${problem.contestId}/${problem.index}`,
  //         contestId: problem.contestId,
  //         index: problem.index,
  //         rating: problem.rating,
  //       },
  //       name: `${problem.contestId}${problem.index} ${problem.name}`
  //     }
  //   });
  //   const newSelectableProblems = mappedProblems.length > 0 ?
  //     [{value: SELECT_RANDOM, name: SELECT_RANDOM}, ...mappedProblems] :
  //     [{value: NO_PROBLEM_FOUND, name: NO_PROBLEM_FOUND}];
  //
  //   setSelectableProblems(newSelectableProblems);
  //   setPracticeNew({
  //     ...practiceNew,
  //     selectedProblem: {...practiceNew.selectedProblem, problem: newSelectableProblems[0]}
  //   });
  //   updateLoader('searchProblem', LOADER_STATE.DONE);
  // };
  // const searchProblemEdit = async () => { // Copy of above
  //   updateLoader('searchProblemEdit', LOADER_STATE.PROGRESS);
  //   const problems = await codeforcesProblems([practiceEdit.selectedProblem.tag.value === ALL ? '' : practiceEdit.selectedProblem.tag.value]);
  //   if (!problems) {
  //     updateLoader('searchProblemEdit', LOADER_STATE.FAIL);
  //     return;
  //   }
  //   const filterProblems = problems.problems.filter((cfProblem) => {
  //     return +cfProblem.rating < +practiceEdit.selectedProblem.rating.value &&
  //       !(practiceEdit.problems.length > 0 && !!practiceEdit.problems.find((problem) => problem.contestId === cfProblem.contestId && problem.index === cfProblem.index));
  //   });
  //   const mappedProblems = filterProblems.map((problem) => {
  //     return {
  //       value: {
  //         name: problem.name,
  //         url: `https://codeforces.com/problemset/problem/${problem.contestId}/${problem.index}`,
  //         contestId: problem.contestId,
  //         index: problem.index,
  //         rating: problem.rating,
  //       },
  //       name: `${problem.contestId}${problem.index} ${problem.name}`
  //     }
  //   });
  //   const newSelectableProblems = mappedProblems.length > 0 ?
  //     [{value: SELECT_RANDOM, name: SELECT_RANDOM}, ...mappedProblems] :
  //     [{value: NO_PROBLEM_FOUND, name: NO_PROBLEM_FOUND}];
  //
  //   setSelectableProblemsEdit(newSelectableProblems);
  //   setPracticeEdit({
  //     ...practiceEdit,
  //     selectedProblem: {...practiceEdit.selectedProblem, problem: newSelectableProblems[0]}
  //   });
  //   updateLoader('searchProblemEdit', LOADER_STATE.DONE);
  // };
  // const deleteProblem = (problem) => {
  //   setPracticeNew({
  //     ...practiceNew,
  //     problems: practiceNew.problems.filter((problemA) => !(problem.contestId === problemA.contestId && problem.index === problemA.index))
  //   });
  // };
  // const practiceStatus = (practice) => {
  //   const startDatePractice = new Date(practice.date);
  //   const endDatePractice = DateFns.addMinutes(startDatePractice, practice.duration ? practice.duration : 60);
  //   if (DateFns.isWithinInterval(new Date(), {start: startDatePractice, end: endDatePractice})) {
  //     return STATUS.LIVE;
  //   }
  //   if (DateFns.isPast(endDatePractice)) {
  //     return STATUS.PAST;
  //   }
  //   return STATUS.FUTURE;
  // };
  // const practiceElapsed = (practice) => {
  //   const status = practiceStatus(practice);
  //   const startDatePractice = new Date(practice.date);
  //   const now = new Date();
  //   const endDatePractice = DateFns.addMinutes(startDatePractice, practice.duration ? practice.duration : 60);
  //   if (status === STATUS.LIVE) {
  //     return <Alert type={ALERT_TYPES.CLOCK_LIVE}
  //                   label={<Clock elapsed={DateFns.differenceInSeconds(now, startDatePractice)} live={1}/>}/>;
  //   }
  //   if (status === STATUS.FUTURE) {
  //     return <Alert type={ALERT_TYPES.CLOCK_FUTURE}
  //                   label={<Clock elapsed={DateFns.differenceInSeconds(endDatePractice, now)} live={-1}/>}/>;
  //   }
  //   return <Alert type={ALERT_TYPES.SUCCESS} label={'Finished'}/>;
  // };
  // const updatePracticeData = async (practice) => {
  //   updateLoader('tableResults', LOADER_STATE.PROGRESS);
  //   const newSubmissions = [];
  //   for (const user of roomUsers) {
  //     if (!user.codeforcesUserName) {
  //       continue;
  //     }
  //     const submissions = await codeforcesSubmissions(user.codeforcesUserName);
  //     const practiceSubmissions = submissions.filter(
  //       (submission) => practice.problems.some(
  //         (problem) => problem.contestId === submission.problem.contestId && problem.index === submission.problem.index));
  //     practice.problems.forEach((problem) => {
  //       const practiceProblemSubmissions = practiceSubmissions.filter(
  //         (submission) => submission.problem.contestId === problem.contestId && submission.problem.index === problem.index);
  //       let status = VERDICTS.UN_SUBMIT;
  //       let url = '#';
  //       for (const submission of practiceProblemSubmissions) {
  //         if (submission.verdict === VERDICTS.OK) {
  //           status = VERDICTS.OK;
  //           url = `https://codeforces.com/contest/${problem.contestId}/submission/${submission.id}`;
  //           break;
  //         }
  //         status = submission.verdict;
  //         break;
  //       }
  //       newSubmissions.push({
  //         userEmail: user.email,
  //         status: status,
  //         url: url,
  //         tries: practiceProblemSubmissions.length,
  //         problemId: problem._id,
  //       });
  //     });
  //   }
  //   await serverUpdatePractice({
  //     ...practice,
  //     submissions: newSubmissions
  //   });
  //   await getPracticesData();
  //   updateLoader('tableResults', LOADER_STATE.DONE);
  // };
  // const memberRoom = roomData.members.find((member) => member.userEmail === user.email);
  // return <>
  //   <Grid
  //     container
  //     direction="row"
  //     justify="center"
  //     alignItems="center"
  //   >
  //     <Grid item>
  //       <h2>{roomData.name}</h2>
  //     </Grid>
  //   </Grid>
  //   {(isSuperAdmin(user) || (memberRoom && memberRoom.role === 'admin')) && <ExpansionPanel>
  //     <ExpansionPanelSummary
  //       expandIcon={<ExpandMoreIcon/>}
  //       aria-controls="panel1a-content"
  //       id="panel1a-header"
  //     >
  //       Add a new Practice
  //       <LinearLoader scope={'addNewPractice'}/>
  //     </ExpansionPanelSummary>
  //     <ExpansionPanelDetails>
  //       <div className={'without-borderColor-bottom'}>
  //         <Table>
  //           <TableBody>
  //             <TableRow key={'add-user'}>
  //               <TableCell component="td">
  //                 <TextField id="outlined-basic" label="Name" variant="outlined" value={practiceNew.name}
  //                            onChange={(e) => setPracticeNew({...practiceNew, name: e.target.value})}/>
  //               </TableCell>
  //               <TableCell component="td">
  //                 <MuiPickersUtilsProvider utils={DateFnsUtils}>
  //                   <DateTimePicker value={practiceNew.date}
  //                                   onChange={(date) => setPracticeNew({...practiceNew, date: date})}/><br/><br/>
  //                   <TextField id="outlined-basic" label="Duration" variant="outlined" value={practiceNew.duration}
  //                              onChange={(e) => setPracticeNew({
  //                                ...practiceNew,
  //                                duration: Number.isInteger(+e.target.value) ? e.target.value : 0
  //                              })}/>
  //                 </MuiPickersUtilsProvider>
  //               </TableCell>
  //               <TableCell component="td">
  //                 {practiceNew.problems.map((problem) => {
  //                   return <>
  //                     <Link
  //                       to={{pathname: `https://codeforces.com/problemset/problem/${problem.contestId}/${problem.index}`}}
  //                       target={'blanck'}>
  //                       {`${problem.contestId}${problem.index} ${problem.name}`}
  //                     </Link>
  //                     <IconButton onClick={() => deleteProblem(problem)}>
  //                       <DeleteIcon/>
  //                     </IconButton>
  //                     <br/>
  //                   </>
  //                 })}
  //               </TableCell>
  //               <TableCell component="td">
  //                 <SelectorEditable
  //                   selectorData={{
  //                     values: allTags.map((tag) => {
  //                       return {value: tag, name: tag}
  //                     }),
  //                     minWidth: 100,
  //                     title: 'Tag',
  //                   }}
  //                   value={practiceNew.selectedProblem.tag}
  //                   onChange={(e) => setPracticeNew({
  //                     ...practiceNew,
  //                     selectedProblem: {...practiceNew.selectedProblem, tag: JSON.parse(e.target.value)}
  //                   })}/>
  //                 <SelectorEditable
  //                   selectorData={RATING_SELECTOR}
  //                   value={practiceNew.selectedProblem.rating}
  //                   onChange={(e) => setPracticeNew({
  //                     ...practiceNew,
  //                     selectedProblem: {...practiceNew.selectedProblem, rating: JSON.parse(e.target.value)}
  //                   })}/>
  //                 <IconButton onClick={searchProblem}
  //                             disabled={loader['searchProblem'] === LOADER_STATE.PROGRESS}><SearchIcon/></IconButton>
  //                 <br/><LinearLoader scope={'searchProblem'}/><br/>
  //                 <SelectorEditable
  //                   selectorData={{
  //                     values: selectableProblems,
  //                     minWidth: 100,
  //                     title: 'Problem',
  //                   }}
  //                   value={practiceNew.selectedProblem.problem}
  //                   onChange={(e) => setPracticeNew({
  //                     ...practiceNew,
  //                     selectedProblem: {...practiceNew.selectedProblem, problem: JSON.parse(e.target.value)}
  //                   })}/>
  //                 <IconButton href={practiceNew.selectedProblem.problem.value.url} target={'_blanck'}>
  //                   <LinkIcon/>
  //                 </IconButton>
  //                 <IconButton onClick={() => {
  //                   const rnd = Math.floor((Math.random() * (selectableProblems.length - 1)) + 1);
  //                   const selectedProblem = practiceNew.selectedProblem.problem.value === SELECT_RANDOM ? selectableProblems[rnd] :
  //                     practiceNew.selectedProblem.problem.value === NO_PROBLEM_FOUND ? null :
  //                       practiceNew.selectedProblem.problem;
  //                   setPracticeNew({
  //                     ...practiceNew,
  //                     problems: selectedProblem ? [...practiceNew.problems, selectedProblem.value] : [...practiceNew.problems],
  //                   })
  //                 }}>
  //                   <PlaylistAddIcon/>
  //                 </IconButton>
  //                 <br/> <br/>
  //                 <TextField id="outlined-basic" label="Contest id" variant="outlined"
  //                            value={practiceNew.selectedProblem.contestId}
  //                            onChange={(e) => setPracticeNew({
  //                              ...practiceNew,
  //                              selectedProblem: {...practiceNew.selectedProblem, contestId: e.target.value}
  //                            })}/>
  //                 <TextField id="outlined-basic" label="Index" variant="outlined"
  //                            value={practiceNew.selectedProblem.index}
  //                            onChange={(e) => setPracticeNew({
  //                              ...practiceNew,
  //                              selectedProblem: {...practiceNew.selectedProblem, index: e.target.value}
  //                            })}/>
  //                 <IconButton onClick={() => {
  //                   const selectedProblem = selectableProblems.find((problem) => '' + problem.value.contestId === practiceNew.selectedProblem.contestId &&
  //                     problem.value.index === practiceNew.selectedProblem.index);
  //                   setPracticeNew({
  //                     ...practiceNew,
  //                     problems: selectedProblem ? [...practiceNew.problems, selectedProblem.value] : [...practiceNew.problems],
  //                   })
  //                 }}>
  //                   <PlaylistAddIcon/>
  //                 </IconButton>
  //               </TableCell>
  //               <TableCell component="td">
  //                 <IconButton onClick={addPractice}
  //                             disabled={!practiceNew.name || !practiceNew.duration}>
  //                   <AddCircleIcon/>
  //                 </IconButton>
  //               </TableCell>
  //             </TableRow>
  //           </TableBody>
  //         </Table>
  //       </div>
  //     </ExpansionPanelDetails>
  //   </ExpansionPanel>}
  //   <Table aria-label="customized table">
  //     <TableHead>
  //       <TableRowStyled>
  //         <TableCell align={'center'}>Name</TableCell>
  //         <TableCell align={'center'}>Date</TableCell>
  //         <TableCell align={'center'}>Results</TableCell>
  //         <TableCell align={'center'}>OPS</TableCell>
  //       </TableRowStyled>
  //     </TableHead>
  //     <TableBody>
  //       {practices.map(practice => {
  //         return <TableRow key={practice._id}>
  //           <TableCell component="td">
  //             <TextFieldEditable
  //               editable={practiceEdit && practiceEdit._id === practice._id}
  //               label={'Name'}
  //               defaultValue={<>
  //                 {practice.name}<br/>{practiceElapsed(practice)}
  //               </>}
  //               value={practiceEdit && practiceEdit.name}
  //               onChange={(e) => setPracticeEdit({...practiceEdit, name: e.target.value})}
  //             />
  //           </TableCell>
  //           <TableCell component="td">
  //             {practiceEdit && practiceEdit._id === practice._id ?
  //               <MuiPickersUtilsProvider utils={DateFnsUtils}>
  //                 <DateTimePicker value={practiceEdit.date}
  //                                 onChange={(date) => setPracticeEdit({...practiceEdit, date: date})}/>
  //                 <TextField required id="outlined-basic" label="Duration" variant="outlined"
  //                            value={practiceEdit.duration} type={'number'}
  //                            onChange={(e) => setPracticeEdit({...practiceEdit, duration: e.target.value})}/>
  //               </MuiPickersUtilsProvider>
  //               : <>
  //                 {DateFns.format(new Date(practice.date), 'dd LLL, y hh:mmaaaa')}
  //                 <br/>
  //                 {`${practice.duration} minutes`}
  //               </>}
  //           </TableCell>
  //           <TableCell component="td">
  //             <LinearLoader scope={'tableResults'}/>
  //             <Table aria-label="customized table">
  //               <TableHead>
  //                 <TableRowStyled>
  //                   <TableCell align={'center'}>User</TableCell>
  //                   {practiceEdit && practiceEdit._id === practice._id ?
  //                     practiceEdit.problems.map((problem, problemIndex) => {
  //                       return <TableCell align={'center'} key={JSON.stringify(problem)}>
  //                         <Link to={{pathname: problem.url}}>
  //                           {defaultProblemNames[problemIndex]}
  //                         </Link>
  //                         <IconButton onClick={() => {
  //                           setPracticeEdit({
  //                             ...practiceEdit,
  //                             problems: practiceEdit.problems.filter((problemA) => !(problem.contestId === problemA.contestId && problem.index === problemA.index))
  //                           });
  //                         }}>
  //                           <DeleteIcon/>
  //                         </IconButton>
  //                       </TableCell>
  //                     })
  //                     :
  //                     practice.problems.map((problem, problemIndex) => {
  //                       return <TableCell align={'center'} key={JSON.stringify(problem)}>
  //                         <Link to={{pathname: problem.url}}>
  //                           {defaultProblemNames[problemIndex]}
  //                         </Link>
  //                       </TableCell>
  //                     })
  //                   }
  //                 </TableRowStyled>
  //               </TableHead>
  //               <TableBody>
  //                 {!(practiceEdit && practiceEdit._id === practice._id) ? roomUsers.map((user) => {
  //                     const aSubmit = practice.submissions.filter((submission) => submission.userEmail === user.email && submission.status !== VERDICTS.UN_SUBMIT);
  //                     if (aSubmit.length === 0) {
  //                       return null;
  //                     }
  //                     return <TableRow key={JSON.stringify(user)}>
  //                       <TableCell>
  //                         <Link to={{pathname: `https://codeforces.com/profile/${user.codeforcesUserName}`}}
  //                               target={'blanck'}>
  //                           {user.codeforcesUserName}
  //                         </Link>
  //                       </TableCell>
  //                       {practice.problems.map((problem) => {
  //                         const submission = practice.submissions.find((submission) => (
  //                           submission.userEmail === user.email && submission.problemId === problem._id));
  //                         return <TableCell key={JSON.stringify(problem) + '#$#' + JSON.stringify(user)}>
  //                           {submission && submission.status !== VERDICTS.UN_SUBMIT &&
  //                           <Alert type={submission.status === VERDICTS.OK ? ALERT_TYPES.SUCCESS : ALERT_TYPES.ERROR}
  //                                  label={`(${submission.tries}) ${PRINT_VERDICTS[submission.status]}`}/>}
  //                         </TableCell>
  //                       })}
  //                     </TableRow>
  //                   })
  //                   :
  //                   <TableRow>
  //                     <TableCell colSpan={practiceEdit.problems.length + 1}>
  //                       <SelectorEditable
  //                         selectorData={{
  //                           values: allTags.map((tag) => {
  //                             return {value: tag, name: tag}
  //                           }),
  //                           minWidth: 100,
  //                           title: 'Tag',
  //                         }}
  //                         value={practiceEdit.selectedProblem.tag}
  //                         onChange={(e) => setPracticeEdit({
  //                           ...practiceEdit,
  //                           selectedProblem: {...practiceEdit.selectedProblem, tag: JSON.parse(e.target.value)}
  //                         })}/>
  //                       < SelectorEditable
  //                         selectorData={RATING_SELECTOR}
  //                         value={practiceEdit.selectedProblem.rating}
  //                         onChange={(e) => setPracticeEdit({
  //                           ...practiceEdit,
  //                           selectedProblem: {...practiceEdit.selectedProblem, rating: JSON.parse(e.target.value)}
  //                         })}/>
  //                       <IconButton onClick={searchProblemEdit}
  //                                   disabled={loader['searchProblemEdit'] === LOADER_STATE.PROGRESS}><SearchIcon/></IconButton>
  //                       <br/><LinearLoader scope={'searchProblemEdit'}/><br/>
  //                       <SelectorEditable
  //                         selectorData={{
  //                           values: selectableProblemsEdit,
  //                           minWidth: 100,
  //                           title: 'Problem',
  //                         }}
  //                         value={practiceEdit.selectedProblem.problem}
  //                         onChange={(e) => setPracticeEdit({
  //                           ...practiceEdit,
  //                           selectedProblem: {...practiceEdit.selectedProblem, problem: JSON.parse(e.target.value)}
  //                         })}
  //                       />
  //                       <IconButton href={practiceEdit.selectedProblem.problem.value.url} target={'_blanck'}>
  //                         <LinkIcon/>
  //                       </IconButton>
  //                       <IconButton onClick={() => {
  //                         const rnd = Math.floor((Math.random() * (selectableProblemsEdit.length - 1)) + 1);
  //                         const selectedProblem = practiceEdit.selectedProblem.problem.value === SELECT_RANDOM ? selectableProblemsEdit[rnd] :
  //                           practiceEdit.selectedProblem.problem.value === NO_PROBLEM_FOUND ? null :
  //                             practiceEdit.selectedProblem.problem;
  //                         setPracticeEdit({
  //                           ...practiceEdit,
  //                           problems: selectedProblem ? [...practiceEdit.problems, selectedProblem.value] : [...practiceEdit.problems],
  //                         })
  //                       }}>
  //                         <PlaylistAddIcon/>
  //                       </IconButton>
  //                       <br/> <br/>
  //                       <TextField id="outlined-basic" label="Contest id" variant="outlined"
  //                                  value={practiceEdit.selectedProblem.contestId}
  //                                  onChange={(e) => setPracticeEdit({
  //                                    ...practiceEdit,
  //                                    selectedProblem: {...practiceEdit.selectedProblem, contestId: e.target.value}
  //                                  })}/>
  //                       <TextField id="outlined-basic" label="Index" variant="outlined"
  //                                  value={practiceEdit.selectedProblem.index}
  //                                  onChange={(e) => setPracticeEdit({
  //                                    ...practiceEdit,
  //                                    selectedProblem: {...practiceEdit.selectedProblem, index: e.target.value}
  //                                  })}/>
  //                       <IconButton onClick={() => {
  //                         const selectedProblem = selectableProblemsEdit.find((problem) => '' + problem.value.contestId === practiceEdit.selectedProblem.contestId &&
  //                           problem.value.index === practiceEdit.selectedProblem.index);
  //                         setPracticeEdit({
  //                           ...practiceEdit,
  //                           problems: selectedProblem ? [...practiceEdit.problems, selectedProblem.value] : [...practiceEdit.problems],
  //                         })
  //                       }}>
  //                         <PlaylistAddIcon/>
  //                       </IconButton>
  //                     </TableCell>
  //                   </TableRow>
  //                 }
  //               </TableBody>
  //             </Table>
  //           </TableCell>
  //           <TableCell component="td" style={{minWidth: '90px'}}>
  //             <IconButton onClick={() => updatePracticeData(practice)}>
  //               <UpdateIcon fontSize={'small'}/>
  //             </IconButton>
  //             {practiceEdit && practiceEdit._id === practice._id ?
  //               <>
  //                 <IconButton onClick={() => updatePractice()}>
  //                   <SaveIcon fontSize={'small'}/>
  //                 </IconButton>
  //                 <IconButton onClick={() => setPracticeEdit(null)}>
  //                   <CancelIcon fontSize={'small'}/>
  //                 </IconButton>
  //               </> :
  //               userCanEdit ?
  //                 <>
  //                   <IconButton onClick={() => {
  //                     setSelectableProblemsEdit([{value: NO_PROBLEM_FOUND, name: NO_PROBLEM_FOUND}]);
  //                     setPracticeEdit({
  //                       ...practice,
  //                       selectedProblem: {
  //                         tag: {value: ALL, name: ALL},
  //                         rating: RATING_SELECTOR.values[0],
  //                         contestId: '',
  //                         index: '',
  //                         problem: {value: NO_PROBLEM_FOUND, name: NO_PROBLEM_FOUND}
  //                       }
  //                     });
  //                   }}>
  //                     <EditIcon fontSize={'small'}/>
  //                   </IconButton>
  //                   <IconButton onClick={() => deletePractice(practice._id)}>
  //                     <DeleteIcon fontSize={'small'}/>
  //                   </IconButton>
  //                 </> : null
  //             }
  //           </TableCell>
  //         </TableRow>
  //       })}
  //     </TableBody>
  //   </Table>
  // </>;
}
