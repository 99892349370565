import { connect } from 'react-redux';

import NewGraph from '../../components/Graph/New';
import { NewGraphState } from '../../store/types';
import { createNewGraph } from '../../store/actions';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    user: state.user
  }
};

const mapDispatchToProps = (dispatch: Function, ownProps: any) => {
  return {
    createNewGraph: (newGraph: NewGraphState) => {
      dispatch(createNewGraph(newGraph));
    }
  }
};

export const GraphNew = connect(mapStateToProps, mapDispatchToProps)(NewGraph);
