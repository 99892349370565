import {CodeMetaState, CODES_ACTIONS, CodesActionTypes, CodeState} from "../types/codes";

export const codes = (state: { [key: string]: CodeState } = {}, action: CodesActionTypes) => {
  const newState = {...state};
  switch (action.type) {
    case CODES_ACTIONS.ADD_CODE:
      newState[action.code.id] = action.code;
      return newState;
    case CODES_ACTIONS.UPDATE_CODE:
      newState[action.code.id] = {...newState[action.code.id], ...action.code};
      return newState;
    case CODES_ACTIONS.REPLACE_CODES:
      const newEmptyState: { [key: string]: CodeState } = {};
      action.codes.forEach((code: CodeMetaState) => {
        newEmptyState[code.id] = {...newState[code.id], ...code};
      });
      return newEmptyState;
    default:
      return state;
  }
};
