import { connect } from 'react-redux';

import NavigationBar from '../../components/Navbar';
import { RootStateType } from '../../store/types';

const mapStateToProps = (state: RootStateType) => {
  return {
    user: state.user
  }
};

export default connect(mapStateToProps)(NavigationBar);

