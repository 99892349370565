import React from 'react';
import { TextareaAutosize } from '@material-ui/core';

import './TextAreaEditable.scss';
import { useStyles } from '../../../config/styles';

interface EditableBoxNoteProps {
  body: string,
  placeholder?: string,
  style?: Object,
  rowsMax?: number,
  readOnly?: boolean,
  onChangeBody?: ({target}: React.ChangeEvent<HTMLTextAreaElement>) => any
}

type Props = EditableBoxNoteProps;

export const TextAreaEditable = ({body, placeholder, style, rowsMax, readOnly, onChangeBody}: Props) => {
  const classes = useStyles();
  return (
    <TextareaAutosize
      rowsMin={1}
      aria-label="maximum height"
      placeholder={placeholder}
      value={body}
      className={classes.textarea}
      onChange={onChangeBody}
      style={style}
      readOnly={readOnly}
      rowsMax={rowsMax}
    />
  );
}
