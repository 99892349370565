import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import {
  Cancel as CancelIcon,
  Save as SaveIcon
} from '@material-ui/icons';
import { Link } from 'react-router-dom';

import { APP_ROUTES } from '../../../config/constans';
import { GraphEditorProps } from '../../../containers/Graph';
import { GraphState } from '../../../store/types';
import { HeaderBar } from '../../../containers/Shared';
import Body from './Body';

export default ({user, graph, updateGraph}: GraphEditorProps) => {
  const [editGraph, setEditGraph] = useState<GraphState>(graph);
  return (
    <>
      <HeaderBar
        title="Graph Editor"
        actions={[
          <IconButton title={'Save'} onClick={() => updateGraph(editGraph)}><SaveIcon/></IconButton>,
          <Link to={APP_ROUTES.ROOT.GRAPH.VIEW(graph.id)}>
            <IconButton title={'Cancel'}><CancelIcon/></IconButton>
          </Link>]}
      />
      <Body user={user} graphic={editGraph} setGraphic={setEditGraph}/>
    </>
  );
};
