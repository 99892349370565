import React from 'react';

import './LabelField.scss';

export const LabelField = ({text, clarification, titleTop}: { text: string, clarification?: string, titleTop?: any }) => {
  return (
    <div className={'label-field-box'}>
      {clarification && titleTop && <p className={'label-field-clarification'}>{clarification}</p>}
      <p className={'label-field-text'}>{text}</p>
      {clarification && !titleTop && <p className={'label-field-clarification'}>{clarification}</p>}
    </div>
  );
}
