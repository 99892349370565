import React from 'react';
import { Link } from 'react-router-dom';

import { APP_ROUTES, INDEX, ROLES } from '../config/constans';
import { UserState } from '../store/types';
import { codeforcesProblems } from '../config/services/services.codeforces';

export const durationToHHMMSS = (duration: number): string => { // seconds
  const hours = Math.floor(duration / 60 / 60);
  const minutes = Math.floor((duration - (hours * 60 * 60)) / 60);
  const seconds = duration - (hours * 60 * 60) - (minutes * 60);
  return (hours < 10 ? '0' : '') + hours + ':'
    + (minutes < 10 ? '0' : '') + minutes + ':'
    + (seconds < 10 ? '0' : '') + seconds;
};

export const isSuperAdmin = (user: UserState): boolean => {
  return user.role === ROLES.SUPER_ADMIN;
};

export const isGuest = (user: UserState): boolean => {
  return user.role === ROLES.GUEST;
};

export const getIndexList = (path: string, origin: any): any => {
  const list: any[] = [];

  if (!INDEX[path]) {
    return null;
  }
  INDEX[path].forEach((subSection: any, index: any): any => {
    const subList = getIndexList(subSection.path, origin + '/' + subSection.path);
    list.push(<li key={`${origin} + '/' + ${subSection.path}`}>
      <Link to={{pathname: origin + '/' + subSection.path}}>
        {subSection.title}
      </Link>
      {subList}
    </li>);
  });
  return <ol start={1}>{list}</ol>;
};

export const getAllCodeforcesTags = async () => {
  const problems = await codeforcesProblems([]);
  const allTags = new Set();
  problems.problems.forEach((problem: any) => {
    problem.tags.forEach((tag: string) => allTags.add(tag));
  });
  return Array.from(allTags);
};

export const getTextFile = async (src: string) => {
  const file = require('../files/' + src);
  return await fetch(file).then((text) => {
    return text.text();
  }).then((text) => {
    return text;
  });
};

export const isJson = (str: string): boolean => {
  try {
    return (JSON.parse(str) && !!str);
  } catch (e) {
    return false;
  }
};

export const toReturnPath = (state: any, defaultPath?: string): string => {
  return state.router.location.state && state.router.location.state.from && state.router.location.state.from.pathname ?
    state.router.location.state.from.pathname :
    defaultPath ? defaultPath : APP_ROUTES.RADIX;
};

export const mex = (array: Array<number | string>) => {
  let i = 1;
  array.sort((a, b) => +a - +b);
  for (const a of array) {
    if (+a !== i) {
      break;
    }
    i++;
  }
  return i;
};
