import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import Clsx from 'clsx';

import { UserState } from '../../../store/types';
import { AttemptType, LANGUAGE_TYPES, PRINT_LANGUAGE_TYPES } from '../../../types';
import { AceEditor, TitleBox, MdViewer } from '../../Shared';
import { HeaderBar } from '../../../containers/Shared'
import { APP_ROUTES, PRINT_VERDICTS, VERDICTS } from '../../../config/constans';
import { useStyles } from '../../../config/styles';

interface Props {
  // state
  user: UserState
  // dispatch
  addNotice: Function
}

export default ({user, addNotice}: Props) => {

  const attempt: AttemptType = {
    attemptId: '',
    belong: {problem: 0},
    date: new Date(),
    language: LANGUAGE_TYPES.TEXT,
    source: 'hola\nasfa',
    ownerNickname: '',
    score: {
      resume: {
        verdict: VERDICTS.JUDGING,
        time: 10,
        memory: 234,
        description: ''
      },
      tests: [
        {
          testId: 'sample1',
          verdict: VERDICTS.OK,
          time: 12,
          memory: 543,
          description: ''
        },
        {
          testId: 'test1',
          verdict: VERDICTS.WRONG_ANSWER,
          time: 23,
          memory: 534,
          description: ''
        },
        {
          testId: 'test2',
          verdict: VERDICTS.TIME_LIMIT_EXCEEDED,
          time: 1000,
          memory: 534,
          description: `asf
          alklsfa
          sfasf
          sfasaf`
        }
      ]
    }
  };
  const getData = useCallback(async () => {
    // const request = await serverGetAttempts();
    // if (request.ok === OK) {
    //   setAttempts(request.result);
    //   addNotice({type: ALERT_TYPES.SUCCESS, label: 'Successfully attempts loaded'})
    // } else {
    //   addNotice({type: ALERT_TYPES.ERROR, label: request.message})
    // }
  }, []);
  useEffect(() => {
    getData();
  }, [getData]);
  const classes = useStyles();
  return (
    <>
      <HeaderBar
        title={`${attempt.ownerNickname} source of ${attempt.belong.problem}`}
        actions={[
          <Link to={{pathname: APP_ROUTES.ROOT.JUDGE.STATUS.USER(user.nickname)}}>
            <Button variant="outlined">My Attempts</Button>
          </Link>,
          <Link to={{pathname: APP_ROUTES.ROOT.JUDGE.PROBLEM.VIEW(attempt.belong.problem)}}>
            <Button variant="outlined">Problem</Button>
          </Link>]}/>
      <div className={Clsx(classes.pageMaxHeight, classes.boxShadow)}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item style={{width: '100%'}}>
            <TitleBox
              title={
                <Grid item style={{width: '100%'}}>
                  <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                  >
                    <Grid item>
                      <strong>LANG</strong>: {PRINT_LANGUAGE_TYPES[attempt.language]}
                    </Grid>
                    <Grid item>
                      <strong>VERDICT</strong>: {PRINT_VERDICTS[attempt.score.resume.verdict]}
                    </Grid>
                    <Grid item>
                      <strong>TIME</strong>: {attempt.score.resume.time} ms
                    </Grid>
                    <Grid item>
                      <strong>MEMORY</strong>: {attempt.score.resume.memory} KB
                    </Grid>
                  </Grid>
                </Grid>
              }
              body={
                <>
                  <AceEditor
                    value={attempt.source}
                    language={attempt.language}
                    readOnly
                    autoHeight/>
                  <MdViewer source={attempt.score.resume.description}/>
                </>
              }
            />
          </Grid>
          {attempt.score.tests.map((test) => {
            return <Grid item style={{width: '100%'}}>
              <TitleBox
                title={
                  <Grid item style={{width: '100%'}}>
                    <Grid
                      container
                      direction="row"
                      justify="space-around"
                      alignItems="center"
                    >
                      <Grid item>
                        <strong>{`${test.testId} :`}</strong>
                      </Grid>
                      <Grid item>
                        <strong>Verdict</strong>: {PRINT_VERDICTS[test.verdict]}
                      </Grid>
                      <Grid item>
                        <strong>Time</strong>: {test.time} ms
                      </Grid>
                      <Grid item>
                        <strong>Memory</strong>: {test.memory} KB
                      </Grid>
                    </Grid>
                  </Grid>
                }
                body={<MdViewer source={test.description}/>}
              />
            </Grid>
          })}
        </Grid>
      </div>
    </>)
}
