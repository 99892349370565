import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router';

import { user } from './user';
import { users } from './users';
import { flags } from './flags';
import { notices } from './notices';
import { codes } from './codes';
import { graphs } from './graphs';
import { problems } from './problems';
import { createBrowserHistory } from 'history';
import { USER_ACTIONS } from '../types';

export const history = createBrowserHistory();

const appReducer = combineReducers({
  router: connectRouter(history),
  user,
  flags,
  users,
  sources: codes,
  graphs,
  problems,
  notices
});

export default (state: any, action: any) => {
  if (action.type === USER_ACTIONS.LOGOUT_USER) {
    state = undefined;
  }
  return appReducer(state, action)
};
