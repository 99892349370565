import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';

import { Modal } from '../../../Shared';
import EditCategoryBody from './EditCategoryBody';
import { serverCreateNewCategory } from '../../../../config/services/backend';
import { PAGE_STATUS, PageStateType, OK, ALERT_TYPES } from '../../../../types';
import { UserState } from '../../../../store/types';

type Props = {
  // state
  user: UserState,
  // dispatch
  addNotice: Function,
  // props
  button: JSX.Element,
  getData: Function,
  parent: string
}

export default ({user, addNotice, button, getData, parent}: Props) => {
  const [forceClose, setForceClose] = useState<boolean>(false);
  const [categoryNew, setCategoryNew] = useState<PageStateType>({
    date: new Date(),
    tag: '',
    parent: parent,
    title: '',
    description: '',
    status: PAGE_STATUS.PRIVATE,
    ownerNickname: user.nickname
  });
  useEffect(() => {
    setCategoryNew({
      date: new Date(),
      tag: '',
      parent: parent,
      title: '',
      description: '',
      status: PAGE_STATUS.PRIVATE,
      ownerNickname: user.nickname
    });
  }, [parent, user.nickname]);
  const addCategory = async () => {
    const request = await serverCreateNewCategory({
      title: categoryNew.title,
      tag: categoryNew.tag,
      parent: categoryNew.parent,
      description: categoryNew.description
    });
    if (request.ok === OK) {
      setForceClose(true);
      setForceClose(false);
      await getData();
      addNotice({type: ALERT_TYPES.SUCCESS, label: `Category ${categoryNew.title} added`});
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: request.message});
    }
  };

  return (
    <Modal
      button={button}
      title="Adding category"
      actions={[
        <Button key="add-user-action" onClick={addCategory}>
          Add
        </Button>
      ]}
      persistent
      forceClose={forceClose}>
      <EditCategoryBody category={categoryNew} setCategory={setCategoryNew}/>
    </Modal>
  );
}
