import { connect } from 'react-redux';

import { NoticesComponent } from '../../../components/Shared/Notices';
import { RootStateType } from '../../../store/types';

const mapStateToProps = (state: RootStateType) => {
  return {
    notices: state.notices
  }
};

export const Notices = connect(mapStateToProps)(NoticesComponent);

