// const URL = 'https://oscargauss-backend-api.herokuapp.com';
export const URL = process.env.NODE_ENV === 'production' ? 'https://oscargauss-backend-api.herokuapp.com' : '';

export const trainingCodeforcesDaily = (): string => {
  return `${URL}/training/codeforces/daily`
};

export const trainingCodeforcesDailyPractice = (_id: string): string => {
  return `${URL}/training/codeforces/daily/${_id}`
};

// USER
export const API = {
  USER: {
    WHO_IS: () => {
      return `${URL}/api/user/who-is`;
    },
    LOGIN: () => {
      return `${URL}/api/user/login`;
    },
    LOGOUT: () => {
      return `${URL}/api/user/logout`;
    },
    USER: (nickname?: string) => {
      return `${URL}/api/user${nickname ? '/' + nickname : ''}`;
    },
    SIGN: () => {
      return `${URL}/api/user/sign`;
    }
  },
  PROBLEM: {
    PROBLEM: (id?: number) => {
      return `${URL}/api/problem${id ? '/' + id : ''}`;
    },
    TESTS: (problemId: number) => {
      return `${URL}/api/problem/tests/${problemId}`;
    },
    TEST: (problemId: number, type: 'sample' | 'case', testId: number) => {
      return `${URL}/api/problem/testCase/${problemId}/${type}/${testId}`;
    },
    GROUPS: () => {
      return `${URL}/api/problem/groups`
    }
  },
  SOURCE: {
    SOURCE: (id?: string) => {
      return `${URL}/api/source${id ? '/' + id : ''}`;
    },
    CAN: () => {
      return `${URL}/api/source/can`;
    },
    PASS: (id: string) => {
      return `${URL}/api/source/pass/${id}`;
    }
  },
  ATTEMPT: {
    ATTEMPT: (id?: string) => {
      return `${URL}/api/attempt${id ? '/' + id : ''}`;
    },
    PROBLEM: () => {
      return `${URL}/api/attempt/problem`;
    }
  },
  PAGE: {
    PAGE: (tag?: string) => {
      return `${URL}/api/page${tag ? '/' + tag : ''}`;
    },
    TAG: (tag: string) => {
      return `${URL}/api/page/tag/${tag}`;
    }
  },
  ROOM: () => {
    return `${URL}/api/room`;
  }
};

export const dataUsersRoom_roomId = (roomId: string) => {
  return `${URL}/data/users/room/${roomId}`
};

// ROOM
export const dataRoom = () => {
  return `${URL}/data/room`;
};

export const dataRoom_roomId = (roomId: string) => {
  return `${URL}/data/room/${roomId}`;
};

export const dataRoomUser_email = (email: string) => {
  return `${URL}/data/room/user/${email}`;
};

// PRACTICE
export const dataPractice = () => {
  return `${URL}/data/practice`;
};

export const dataPracticesRoom_roomId = (roomId: string) => {
  return `${URL}/data/practices/room/${roomId}`;
};

export const dataPractice_practiceId = (practiceId: string) => {
  return `${URL}/data/practice/${practiceId}`;
};

