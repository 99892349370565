import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, Grid, IconButton } from '@material-ui/core';
import {
  Cancel as CancelIcon,
  Delete as DeleteIcon, Edit as EditIcon,
  Save as SaveIcon,
  FileCopy as FileCopyIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@material-ui/icons';
import copy from 'copy-to-clipboard';
import { useParams } from 'react-router';

import { useStyles } from '../../../config/styles';
import { AceEditor, LabelField, TitleBox, Modal, TextFieldEditable, SelectorEditable } from '../../Shared';
import { serverCanUpdateSource } from '../../../config/services/backend';
import { CodeState } from '../../../store/types';
import {
  LANGUAGE_TYPE_SELECTOR,
  SCOPE_VISIBILITY_LOGGED_IN_SELECTOR,
  SCOPE_VISIBILITY_SELECTOR,
  OK,
  ALERT_TYPES,
  LANGUAGE_TYPES, PRINT_LANGUAGE_TYPES, EDITABLE
} from '../../../types';
import { PRINT_SCOPE_VISIBILITY, SCOPE_VISIBILITY } from '../../../config/constans';
import ChangePassword from '../../../containers/CodeEditor/ChangePassword';
import { Props } from '../../../containers/CodeEditor/ViewCode';
import { HeaderBar } from '../../../containers/Shared';

export default ({user, source, addNotice, updateSource, deleteSource}: Props) => {
  const classes = useStyles();
  const params = useParams<{ codeId: string }>();
  const [edit, setEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [sourceEdit, setSourceEdit] = useState<CodeState>(source);
  const [wrongPassword, setWrongPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>(source.password);
  const [visibility, setVisibility] = useState<boolean>(false);
  const handleEdit = async () => {
    const request = await serverCanUpdateSource(params.codeId, password);
    if (request.ok === OK) {
      setEdit(true);
      setWrongPassword(false);
      setSourceEdit(source);
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: 'Invalid credentials'});
      setWrongPassword(true);
    }
    setPassword(password);
  };
  const handleSave = async () => {
    setEdit(false);
    setLoading(true);
    await updateSource({...sourceEdit, password: password});
    setLoading(false);
  };
  const handleCancel = () => {
    setEdit(false);
  };
  useEffect(() => {
    if (source.password) {
      setPassword(source.password);
    }
    setVisibility(false);
  }, [source]);
  const copyToClipboard = () => {
    copy(password);
    addNotice({type: ALERT_TYPES.INFO, label: 'Copied!'});
  };

  const actions = [];
  if (user.nickname === source.ownerNickname) {
    actions.push(<ChangePassword/>);
    actions.push(
      <Modal
        button={<IconButton title={'Delete'}><DeleteIcon/></IconButton>}
        title="Deleting Code"
        actions={[
          <Button key="delete-source-action" onClick={() => deleteSource()}>
            Delete
          </Button>]}>
        <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
          <Grid item>
            Are you sure to delete it?
          </Grid>
        </Grid>
      </Modal>
    );
  }
  return (
    <>
      <HeaderBar
        title="Editor"
        actions={[...actions, ...(edit ? [
            <IconButton title={'Save'} onClick={handleSave}><SaveIcon/></IconButton>,
            <IconButton title={'Cancel'} onClick={handleCancel}><CancelIcon/></IconButton>] :
          [<IconButton title={'Edit'} onClick={handleEdit}><EditIcon/></IconButton>])
        ]}
      />
      <div className={clsx(classes.pageMaxHeight, classes.boxShadow)}
           style={loading ? {opacity: '0.5', pointerEvents: 'none'} : undefined}>
        <TitleBox
          body={
            <div style={{height: 'calc(100% - 50px)'}}>
              {edit ?
                <AceEditor
                  value={sourceEdit.source}
                  language={sourceEdit.language}
                  onChange={(value) => setSourceEdit(prevState => ({...prevState, source: value}))}/> :
                <AceEditor
                  value={source.source}
                  language={source.language}
                  readOnly/>}
            </div>
          }
          title={
            edit ?
              <Grid
                container
                justify="space-between"
                alignItems="center"
                className={classes.maxDivSize}
              >
                <Grid item>
                  <TextFieldEditable
                    label="Title"
                    value={sourceEdit.name}
                    onChange={({target}: React.ChangeEvent<HTMLInputElement>) => setSourceEdit(prevState => ({
                      ...prevState,
                      name: target.value
                    }))}/>
                </Grid>
                <Grid item>
                  <SelectorEditable
                    editable={EDITABLE}
                    selectorData={LANGUAGE_TYPE_SELECTOR}
                    value={sourceEdit.language}
                    onChange={(value: { value: LANGUAGE_TYPES }) => setSourceEdit(prevState => ({
                      ...prevState,
                      language: value.value
                    }))}
                  />
                </Grid>
                <Grid item>
                  <SelectorEditable
                    editable={EDITABLE}
                    selectorData={user.isLogged ? SCOPE_VISIBILITY_LOGGED_IN_SELECTOR : SCOPE_VISIBILITY_SELECTOR}
                    value={sourceEdit.type}
                    onChange={(value: { value: SCOPE_VISIBILITY }) => setSourceEdit(prevState => ({
                      ...prevState,
                      type: value.value
                    }))}
                  />
                </Grid>
              </Grid> :
              <Grid
                container
                justify="space-between"
                alignItems="center"
                className={classes.maxDivSize}
              >
                <Grid item>
                  <LabelField text={source.name} clarification={'Name'}/>
                </Grid>
                <Grid item>
                  <LabelField text={PRINT_LANGUAGE_TYPES[source.language]} clarification={'Language'}/>
                </Grid>
                <Grid item>
                  <LabelField text={PRINT_SCOPE_VISIBILITY[source.type]} clarification={'Visibility'}/>
                </Grid>
                <Grid item>
                  <LabelField text={source.ownerNickname} clarification={'Owner'}/>
                </Grid>
                <Grid item>
                  <div style={{display: 'flex'}}>
                    <TextFieldEditable
                      label="Password"
                      value={password}
                      onChange={(event: any) => setPassword(event.target.value)}
                      error={wrongPassword}
                      isPassword={!visibility}
                    />
                    <IconButton onClick={copyToClipboard}>
                      <FileCopyIcon/>
                    </IconButton>
                    <IconButton onClick={() => setVisibility(prevState => !prevState)}>
                      {visibility ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
          }
        />
      </div>
    </>
  );
};
