import React from 'react';
import { GoogleLogout } from 'react-google-login';
import {
  Typography, Grid,
  Card, CardMedia,
  Button, ListItem, ListItemText,
  Divider, ListItemIcon, IconButton
} from '@material-ui/core';
import { Edit as EditIcon, FiberManualRecord as FiberManualRecordIcon } from '@material-ui/icons';

import { useStyles } from '../../config/styles';
import { GOOGLE, PRINT_ROLES, ROLES } from '../../config/constans';
import { serverLogout } from '../../config/services/backend';
import { ALERT_TYPES, OK } from '../../types';
import { LabelField } from '../Shared';
import EditUser from '../../containers/Account/EditUser';
import { HeaderBar } from '../../containers/Shared';

export default ({
                  user, // sate
                  getUser, updateUser, logoutGoogle, addNotice// dispatch
                }) => {
  const classes = useStyles();
  const userLogout = async () => {
    const request = await serverLogout();
    if (request.ok === OK) {
      addNotice({type: ALERT_TYPES.SUCCESS, label: 'Logout success'});
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: request.message + ', but forced logout'});
    }
    logoutGoogle();
  };
  const responseGoogle = async (response) => {
    if (!response || !response.error) {
      addNotice({
        type: ALERT_TYPES.ERROR,
        label: response ? response.error : 'Error on response of Google, but forced logout'
      });
    } else {
      addNotice({type: ALERT_TYPES.SUCCESS, label: 'Google logout success'});
    }
  };
  const handleUpdateTheme = async (theme) => {
    await updateUser({...user, theme});
    getUser();
  };
  return (
    <>
      <HeaderBar title={user.givenName} actions={[]}/>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid item lg={6} md={10} sm={12}>
          <Card elevation={3}>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <CardMedia
                  component="img"
                  alt={`${user.givenName} ${user.familyName}`}
                  height="150"
                  image={user.imageUrl}
                  title="Contemplative Reptile"
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justify="space-around"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography variant="h6" align={'center'}>
                      {`${user.givenName} ${user.familyName}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom align={'center'}>
                      {PRINT_ROLES[user.role]}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <div onClick={userLogout}>
                      <GoogleLogout
                        clientId={GOOGLE.CLIENT_ID}
                        buttonText="Logout"
                        onLogoutSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        className={classes.googleLogoutButton + ' googleLogoutButton'}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider/>
            <Divider/>
            <Grid
              container
              direction="column"
              justify="space-around"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <LabelField text={user.email} clarification={'email'} titleTop/>
              </Grid>
              {(ROLES.SUPER_ADMIN === user.role || ROLES.ADMIN === user.role) && <Grid item>
                <Button
                  href="https://bitbucket.org/site/oauth2/authorize?client_id=WQPdSZEa5kRE5hPYhS&response_type=token"
                  onClick={(event) => (user.bitbucketAccessToken === '' ? null : event.preventDefault())}>
                  <ListItem button component={'li'}>
                    <ListItemIcon>
                      <div>
                        <img src={'/images/logos/bitbucket-logo.svg'} width="20" height="20" alt="bitbucket-logo"/>
                        {user.bitbucketAccessToken === '' && <div className={classes.bitbucketAlertDot}/>}
                      </div>
                    </ListItemIcon>
                    <ListItemText primary={'Bitbucket'} className={classes.title}/>
                  </ListItem>
                </Button>
              </Grid>}
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justify="space-around"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <LabelField text={user.nickname ? user.nickname : '-'} clarification={'nickname'} titleTop/>
                  </Grid>
                  <Grid item>
                    <LabelField text={user.codeforcesUserName ? user.codeforcesUserName : '-'}
                                clarification={'Codeforces nickname'} titleTop/>
                  </Grid>
                  <Grid item>
                    <LabelField text={user.topcoderUserName ? user.topcoderUserName : '-'}
                                clarification={'Topcoder nickname'} titleTop/>
                  </Grid>
                  <Grid item>
                    <LabelField text={user.codechefUserName ? user.codechefUserName : '-'}
                                clarification={'Codechef nickname'} titleTop/>
                  </Grid>
                  <Grid item>
                    <LabelField text={user.uvaUserName ? user.uvaUserName : '-'} clarification={'UVA nickname'}
                                titleTop/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{padding: '10px'}}>
                <EditUser
                  button={<Button variant="outlined"><EditIcon/>Edit Nicknames</Button>}
                  userToEdit={user}
                  getData={getUser}
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                >
                  <Grid item>
                    <IconButton onClick={() => handleUpdateTheme(0)}>
                      <FiberManualRecordIcon style={{color: '#eceff1'}}/>
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => handleUpdateTheme(1)}>
                      <FiberManualRecordIcon style={{color: '#4dabf5'}}/>
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => handleUpdateTheme(2)}>
                      <FiberManualRecordIcon style={{color: '#455a64'}}/>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
