import {connect} from 'react-redux';
import { withRouter } from 'react-router';

import ChangePassword from '../../components/Graph/Viewer/ChangePassword';
import { changePasswordGraph } from '../../store/actions';
import { RootStateType } from '../../store/types';

export const mapStateToProps = (state: RootStateType, ownProps: any) => {
  return {
    graph: state.graphs[ownProps.match.params.graphId]
  }
};

export const mapDispatchToProps = (dispatch: Function, ownProps: any) => {
  return {
    changePasswordGraph: (password: string) => {
      dispatch(changePasswordGraph(ownProps.match.params.graphId, password));
    }
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));

