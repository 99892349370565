import React, { useState } from 'react';
import { Button } from '@material-ui/core';

import { serverCreateNewUser } from '../../../config/services/backend';
import { ALERT_TYPES, OK, NEW } from '../../../types';
import { Modal } from '../../Shared';
import { UserNewType } from '../../../config/Types';
import UserEditable from '../../../containers/Admin/UserEditable';
import { ROLES } from '../../../config/constans';

type Props = {
  // own props
  getData: Function,
  addNotice: Function
}

export default ({getData, addNotice}: Props) => {
  const [userNew, setUserNew] = useState<UserNewType>({
    name: '',
    givenName: '',
    familyName: '',
    email: '',
    nickname: '',
    role: ROLES.GUEST,
    codeforcesUserName: '',
    topcoderUserName: '',
    codechefUserName: '',
    uvaUserName: ''
  });
  const addUser = async () => {
    const newUser = {
      name: userNew.name.trim(),
      givenName: userNew.givenName.trim(),
      familyName: userNew.familyName.trim(),
      email: userNew.email.trim(),
      nickname: userNew.nickname.trim(),
      role: userNew.role,
      codeforcesUserName: userNew.codeforcesUserName.trim(),
      topcoderUserName: userNew.topcoderUserName.trim(),
      codechefUserName: userNew.codechefUserName.trim(),
      uvaUserName: userNew.uvaUserName.trim()
    };
    const request = await serverCreateNewUser(newUser);
    if (request.ok === OK) {
      getData();
      addNotice({type: ALERT_TYPES.SUCCESS, label: `User ${newUser.email} added`});
    } else {
      addNotice({type: ALERT_TYPES.ERROR, label: request.message});
    }
  };

  return (
    <Modal
      button="Add User"
      title="Adding User"
      actions={[
        <Button
          key="add-user-action"
          onClick={addUser}
          autoFocus
          disabled={!userNew.name || !userNew.givenName || !userNew.familyName || !userNew.email}
        >
          Add
        </Button>]}>
      <UserEditable kind={NEW} userEditable={userNew} setUserEditable={setUserNew}/>
    </Modal>
  );
}
