import { connect } from 'react-redux';

import AddUser from '../../components/Admin/Users/AddUser';
import { addNotice } from '../../store/actions';
import { Message } from '../../store/types/notices';

export const mapStateToProps = (state: any, ownProps: any) => {
  return {}
};

export const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    addNotice(notice: Message) {
      dispatch(addNotice(notice));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);

