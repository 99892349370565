import { USER_ACTIONS, UserActionTypes, UserState } from '../types/user';
import { USER_GUEST } from '../types/user';
import { history } from '../reducers';
import { serverUpdateUser, serverWhoIs } from '../../config/services/backend';
import { OK } from '../../types/services';
import { ALERT_TYPES } from '../../types/alert';
import { addNotice } from './notices';

export const loginUser = (user: UserState): UserActionTypes => {
  return {
    type: USER_ACTIONS.REPLACE_USER,
    user
  }
};

export const updateUserState = (user: UserState): UserActionTypes => {
  return {
    type: USER_ACTIONS.UPDATE_USER,
    user
  }
};

export const cleanUser = (): UserActionTypes => {
  return {
    type: USER_ACTIONS.REPLACE_USER,
    user: USER_GUEST
  }
};

export const getUser = () => {
  return async (dispatch: Function) => {
    const request = await serverWhoIs();
    if (request.ok === OK) {
      dispatch(updateUserState({...request.result}));
    } else {
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
  }
};

export const updateUser = (user: UserState) => {
  return async (dispatch: Function, getState: Function) => {
    const request = await serverUpdateUser(user);
    if (request.ok === OK) {
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `User ${user.nickname} updated`}));
    } else {
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
  }
};

export const logoutUser = () => {
  return (dispatch: Function) => {
    dispatch(cleanUser());
    history.push('/');
  }
};


export const saveBitbucketAccessToken = (accessToken: string): UserActionTypes => {
  return {
    type: USER_ACTIONS.REPLACE_BITBUCKET_ACCESS_TOKEN_USER,
    accessToken
  }
};
