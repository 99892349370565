import store from "../../store";

import {isJson} from "../../helpers/functions";
import {saveBitbucketAccessToken} from "../../store/actions";

export const bitbucketRequest = async (url: string) => {
  return await fetch(url,
    {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${store.getState().user.bitbucketAccessToken}`
      },
    })
    .then(data => {
      if (data.status === 401) {
        console.error('401 Unauthorized Bitbucket');
        store.dispatch(saveBitbucketAccessToken(''));
      }
      return data.text();
    })
    .then(text => {
      if (isJson(text)) {
        return JSON.parse(text);
      }
      console.error('Bitbucket MESSAGE:', text);
      return null;
    })
    .catch((error) => {
      console.error('Bitbucket ERROR:', error);
      return null;
    });
};

export const bitbucketTextPlain = async (url: string) => {
  return await fetch(url,
    {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${store.getState().user.bitbucketAccessToken}`
      },
    })
    .then(data => {
      if (data.status === 401) {
        console.error('401 Unauthorized Bitbucket');
        store.dispatch(saveBitbucketAccessToken(''));
      }
      return data.text();
    })
    .catch((error) => {
      console.error('Bitbucket ERROR:', error);
      return null;
    });
};


export const getCommitSHAHead = async (workspace: string): Promise<string | null> => {
  const response = await bitbucketRequest(`https://api.bitbucket.org/2.0/repositories/OscarGauss/${workspace}/commits`);
  if (response && response.values && response.values[0] && response.values[0].hash) {
    return response.values[0].hash;
  }
  return null;
};

export const getSrcRepository = async (fullPath: string): Promise<any | null> => {
  const response = await bitbucketRequest(fullPath);
  if (response) {
    return response
  }
  return null;
};

export const getAllSrcRepository = async (workspace: string, hash: string, path: string): Promise<Array<any>> => {
  return getAllSrcRepositoryFromFullPath(`https://api.bitbucket.org/2.0/repositories/OscarGauss/${workspace}/src/${hash}${path}`);
};

export const getAllSrcRepositoryFromFullPath = async (fullPath: string): Promise<Array<any>> => {
  const values = [];
  let response = await getSrcRepository(fullPath);
  if (response && response.values) {
    values.push(...response.values);
  }
  while (response && response.next) {
    response = await getSrcRepository(response.next);
    if (response.values) {
      values.push(...response.values);
    }
  }
  return values;
};

