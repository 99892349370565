import { NewCodeState, CodeMetaState, CODES_ACTIONS, CodesActionTypes, CodeState } from '../types/codes';
import {
  serverChangePasswordSource,
  serverCreateNewSource, serverDeleteSource,
  serverGetSource,
  serverGetSources,
  serverUpdateSource
} from '../../config/services/backend';
import { OK } from '../../types/services';
import { ALERT_TYPES } from '../../types/alert';
import { addNotice } from './notices';
import { history } from '../reducers';

export const addCode = (code: CodeState): CodesActionTypes => {
  return {
    type: CODES_ACTIONS.ADD_CODE,
    code
  }
};

export const updateSource = (code: CodeState): CodesActionTypes => {
  return {
    type: CODES_ACTIONS.UPDATE_CODE,
    code
  }
};

export const replaceCodes = (codes: Array<CodeMetaState>): CodesActionTypes => {
  return {
    type: CODES_ACTIONS.REPLACE_CODES,
    codes
  }
};

export const createNewCode = (newCode: NewCodeState) => {
  return async (dispatch: Function) => {
    const request = await serverCreateNewSource(newCode);
    if (request.ok === OK) {
      dispatch(addCode({...request.result}));
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `Code ${request.result.name} created`}));
      history.push('/editor/' + request.result.id);
    } else {
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
  }
};

export const getCode = (codeId: string) => {
  return async (dispatch: Function) => {
    const request = await serverGetSource(codeId);
    if (request.ok === OK) {
      dispatch(updateSource({...request.result}));
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `Code ${request.result.name} loaded`}));
    } else {
      history.push('/editor');
      dispatch(addNotice({type: ALERT_TYPES.INFO, label: 'Redirect to list'}));
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
  }
};

export const putSource = (source: CodeState) => {
  return async (dispatch: Function) => {
    const request = await serverUpdateSource(source);
    if (request.ok === OK) {
      await dispatch(getCode(source.id));
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `Code ${request.result.name} updated`}));
    } else {
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
  }
};

export const changePasswordSource = (sourceId: string, password: string) => {
  return async (dispatch: Function) => {
    const request = await serverChangePasswordSource(sourceId, password);
    if (request.ok === OK) {
      dispatch(getCode(sourceId));
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `Password changed`}));
    } else {
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
  }
};

export const deleteSource = (sourceId: string) => {
  return async (dispatch: Function) => {
    const request = await serverDeleteSource(sourceId);
    if (request.ok === OK) {
      history.push('/editor');
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `Source ${sourceId} deleted`}));
    } else {
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
  }
};

export const getAllSources = () => {
  return async (dispatch: Function) => {
    const request = await serverGetSources();
    if (request.ok === OK) {
      dispatch(replaceCodes(request.result));
      dispatch(addNotice({type: ALERT_TYPES.SUCCESS, label: `Get all sources`}));
    } else {
      dispatch(addNotice({type: ALERT_TYPES.ERROR, label: request.message}));
    }
  }
};
