import { PRINT_ROLES, PRINT_SCOPE_VISIBILITY, ROLES, SCOPE_VISIBILITY } from '../config/constans';
import {
  PRINT_PROBLEM_INPUT_TYPE, PRINT_PROBLEM_JUDGE_TYPE,
  PRINT_PROBLEM_OUTPUT_TYPE,
  PRINT_PROBLEM_STATUS,
  PROBLEM_INPUT_TYPE, PROBLEM_JUDGE_TYPE,
  PROBLEM_OUTPUT_TYPE, PROBLEM_STATUS
} from './problems';
import { PAGE_STATUS, PRINT_PAGE_STATUS } from './page';
import { LANGUAGE_TYPES, PRINT_LANGUAGE_TYPES } from './attempt';
import {
  GRAPH_EDGE_TYPE,
  LABEL_NODE_POSITIONS,
  PRINT_GRAPH_EDGE_TYPE,
  PRINT_LABEL_NODE_POSITIONS
} from './graph';

export interface selectorValue {
  value: string,
  name: string
}

export interface SelectorType {
  values: Array<selectorValue>,
  minWidth: number,
  title: string
}

export const ROOM_TYPE_SELECTOR: SelectorType = {
  values: [
    {value: 'codeforcesOneProblem', name: `One Problem Codeforces`},
    {value: 'codeforcesSeveralProblems', name: `Several Problems Codeforces`}
  ],
  minWidth: 260,
  title: 'Type'
};

//PROBLEM_INPUT_TYPE
type ProblemInputTypeValueType = { name: string, value: PROBLEM_INPUT_TYPE }

export interface ProblemInputTypeSelectorType {
  values: Array<ProblemInputTypeValueType>,
  minWidth: number,
  title: string
}

export const PROBLEM_INPUT_TYPE_SELECTOR: ProblemInputTypeSelectorType = {
  values: [{
    value: PROBLEM_INPUT_TYPE.STANDARD,
    name: PRINT_PROBLEM_INPUT_TYPE[PROBLEM_INPUT_TYPE.STANDARD]
  }, {
    value: PROBLEM_INPUT_TYPE.INTERACTIVE,
    name: PRINT_PROBLEM_INPUT_TYPE[PROBLEM_INPUT_TYPE.INTERACTIVE]
  }],
  minWidth: 75,
  title: 'Input'
};

// PROBLEM_OUTPUT_TYPE
type ProblemOutputTypeValueType = { name: string, value: PROBLEM_OUTPUT_TYPE }

interface ProblemOutputTypeSelectorType {
  values: Array<ProblemOutputTypeValueType>,
  minWidth: number,
  title: string
}

export const PROBLEM_OUTPUT_TYPE_SELECTOR: ProblemOutputTypeSelectorType = {
  values: [{
    value: PROBLEM_OUTPUT_TYPE.EXACT,
    name: PRINT_PROBLEM_OUTPUT_TYPE[PROBLEM_OUTPUT_TYPE.EXACT]
  }, {
    value: PROBLEM_OUTPUT_TYPE.INTERACTIVE,
    name: PRINT_PROBLEM_OUTPUT_TYPE[PROBLEM_OUTPUT_TYPE.INTERACTIVE]
  }, {
    value: PROBLEM_OUTPUT_TYPE.SEVERAL,
    name: PRINT_PROBLEM_OUTPUT_TYPE[PROBLEM_OUTPUT_TYPE.SEVERAL]
  }],
  minWidth: 123,
  title: 'Output'
};

// SCOPE_VISIBILITY
type ScopeVisibilityValueType = { name: string, value: SCOPE_VISIBILITY }

interface ScopeVisibilitySelectorType {
  values: Array<ScopeVisibilityValueType>,
  minWidth: number,
  title: string
}

export const SCOPE_VISIBILITY_SELECTOR: ScopeVisibilitySelectorType = {
  values: [
    {value: SCOPE_VISIBILITY.PUBLIC, name: PRINT_SCOPE_VISIBILITY[SCOPE_VISIBILITY.PUBLIC]},
    {value: SCOPE_VISIBILITY.PRIVATE, name: PRINT_SCOPE_VISIBILITY[SCOPE_VISIBILITY.PRIVATE]},
    {value: SCOPE_VISIBILITY.PERSONAL, name: PRINT_SCOPE_VISIBILITY[SCOPE_VISIBILITY.PERSONAL]}
  ],
  minWidth: 64,
  title: 'Visibility'
};

export const SCOPE_VISIBILITY_LOGGED_IN_SELECTOR: ScopeVisibilitySelectorType = {
  values: [
    {value: SCOPE_VISIBILITY.PUBLIC, name: PRINT_SCOPE_VISIBILITY[SCOPE_VISIBILITY.PUBLIC]},
    {value: SCOPE_VISIBILITY.PRIVATE, name: PRINT_SCOPE_VISIBILITY[SCOPE_VISIBILITY.PRIVATE]},
    {value: SCOPE_VISIBILITY.PERSONAL, name: PRINT_SCOPE_VISIBILITY[SCOPE_VISIBILITY.PERSONAL]}
  ],
  minWidth: 64,
  title: 'Visibility'
};


// GRAPH_EDGE_TYPE
type GraphEdgeTypeValueType = { name: string, value: GRAPH_EDGE_TYPE }

interface GraphEdgeTypeSelectorType {
  values: Array<GraphEdgeTypeValueType>,
  minWidth: number,
  title: string
}

export const GRAPH_EDGE_TYPE_SELECTOR: GraphEdgeTypeSelectorType = {
  values: [
    {value: GRAPH_EDGE_TYPE.NO_DIRECTED, name: PRINT_GRAPH_EDGE_TYPE[GRAPH_EDGE_TYPE.NO_DIRECTED]},
    {value: GRAPH_EDGE_TYPE.DIRECTED, name: PRINT_GRAPH_EDGE_TYPE[GRAPH_EDGE_TYPE.DIRECTED]}
  ],
  minWidth: 83,
  title: 'Type'
};

//LABEL_NODE_POSITIONS
type LabelNodePositionsValueType = { name: string, value: LABEL_NODE_POSITIONS }

interface LabelNodePositionsSelectorType {
  values: Array<LabelNodePositionsValueType>,
  minWidth: number,
  title: string
}

export const LABEL_NODE_POSITIONS_SELECTOR: LabelNodePositionsSelectorType = {
  values: [
    {value: LABEL_NODE_POSITIONS.CENTER, name: PRINT_LABEL_NODE_POSITIONS[LABEL_NODE_POSITIONS.CENTER]},
    {value: LABEL_NODE_POSITIONS.UP, name: PRINT_LABEL_NODE_POSITIONS[LABEL_NODE_POSITIONS.UP]},
    {value: LABEL_NODE_POSITIONS.RIGHT, name: PRINT_LABEL_NODE_POSITIONS[LABEL_NODE_POSITIONS.RIGHT]},
    {value: LABEL_NODE_POSITIONS.DOWN, name: PRINT_LABEL_NODE_POSITIONS[LABEL_NODE_POSITIONS.DOWN]},
    {value: LABEL_NODE_POSITIONS.LEFT, name: PRINT_LABEL_NODE_POSITIONS[LABEL_NODE_POSITIONS.LEFT]}
  ],
  minWidth: 49,
  title: 'Position'
};

// LANGUAGE_TYPES
type LanguageTypeValueType = { name: string, value: LANGUAGE_TYPES }

interface LanguageTypeSelectorType {
  values: Array<LanguageTypeValueType>,
  minWidth: number,
  title: string
}

export const LANGUAGE_TYPE_SELECTOR: LanguageTypeSelectorType = {
  values: [
    {value: LANGUAGE_TYPES.CPP, name: PRINT_LANGUAGE_TYPES[LANGUAGE_TYPES.CPP]},
    {value: LANGUAGE_TYPES.PYTHON, name: PRINT_LANGUAGE_TYPES[LANGUAGE_TYPES.PYTHON]},
    {value: LANGUAGE_TYPES.JAVA, name: PRINT_LANGUAGE_TYPES[LANGUAGE_TYPES.JAVA]},
    {value: LANGUAGE_TYPES.TEXT, name: PRINT_LANGUAGE_TYPES[LANGUAGE_TYPES.TEXT]}
  ],
  minWidth: 71,
  title: 'Language'
};

// ROLES
type RolesValueType = { name: string, value: ROLES }

interface RolesSelectorType {
  values: Array<RolesValueType>,
  minWidth: number,
  title: string
}

export const ROLES_SELECTOR: RolesSelectorType = {
  values: [
    {value: ROLES.SUPER_ADMIN, name: PRINT_ROLES[ROLES.SUPER_ADMIN]},
    {value: ROLES.ADMIN, name: PRINT_ROLES[ROLES.ADMIN]},
    {value: ROLES.USER, name: PRINT_ROLES[ROLES.USER]},
    {value: ROLES.GUEST, name: PRINT_ROLES[ROLES.GUEST]}
  ],
  minWidth: 67,
  title: 'Role'
};

// PAGE_STATUS
type PageStatusValueType = { name: string, value: PAGE_STATUS }

interface PageStatusSelectorType {
  values: Array<PageStatusValueType>,
  minWidth: number,
  title: string
}

export const PAGE_STATUS_SELECTOR: PageStatusSelectorType = {
  values: [
    {value: PAGE_STATUS.PUBLIC, name: PRINT_PAGE_STATUS[PAGE_STATUS.PUBLIC]},
    {value: PAGE_STATUS.PRIVATE, name: PRINT_PAGE_STATUS[PAGE_STATUS.PRIVATE]},
    {value: PAGE_STATUS.CLOSED, name: PRINT_PAGE_STATUS[PAGE_STATUS.CLOSED]}
  ],
  minWidth: 51,
  title: 'Visibility'
};

// PROBLEM_STATUS
type ProblemStatusValueType = { name: string, value: PROBLEM_STATUS }

interface ProblemStatusSelectorType {
  values: Array<ProblemStatusValueType>,
  minWidth: number,
  title: string
}

export const PROBLEM_STATUS_SELECTOR: ProblemStatusSelectorType = {
  values: [
    {value: PROBLEM_STATUS.PRIVATE, name: PRINT_PROBLEM_STATUS[PROBLEM_STATUS.PRIVATE]},
    {value: PROBLEM_STATUS.PUBLIC, name: PRINT_PROBLEM_STATUS[PROBLEM_STATUS.PUBLIC]}
  ],
  minWidth: 80,
  title: 'Visibility'
};

// PROBLEM_JUDGE_TYPE
type ProblemJudgeTypeValueType = { name: string, value: PROBLEM_JUDGE_TYPE }

interface ProblemJudgeTypeSelectorType {
  values: Array<ProblemJudgeTypeValueType>,
  minWidth: number,
  title: string
}

export const PROBLEM_JUDGE_TYPE_SELECTOR: ProblemJudgeTypeSelectorType = {
  values: [
    {value: PROBLEM_JUDGE_TYPE.TOTAL, name: PRINT_PROBLEM_JUDGE_TYPE[PROBLEM_JUDGE_TYPE.TOTAL]},
    {value: PROBLEM_JUDGE_TYPE.POINTS, name: PRINT_PROBLEM_JUDGE_TYPE[PROBLEM_JUDGE_TYPE.POINTS]}
  ],
  minWidth: 80,
  title: 'Judge type'
};

export type ValueTypes = ProblemInputTypeValueType |
  ProblemOutputTypeValueType |
  ScopeVisibilityValueType |
  GraphEdgeTypeValueType |
  LabelNodePositionsValueType |
  LanguageTypeValueType |
  RolesValueType |
  PageStatusValueType |
  ProblemStatusValueType |
  ProblemJudgeTypeValueType;

export type ValuesTypes = PROBLEM_INPUT_TYPE |
  PROBLEM_OUTPUT_TYPE |
  SCOPE_VISIBILITY |
  GRAPH_EDGE_TYPE |
  LABEL_NODE_POSITIONS |
  LANGUAGE_TYPES |
  ROLES |
  PAGE_STATUS |
  PROBLEM_STATUS |
  PROBLEM_JUDGE_TYPE;

export type SelectorDataType = ProblemInputTypeSelectorType |
  ProblemOutputTypeSelectorType |
  ScopeVisibilitySelectorType |
  GraphEdgeTypeSelectorType |
  LabelNodePositionsSelectorType |
  LanguageTypeSelectorType |
  RolesSelectorType |
  PageStatusSelectorType |
  ProblemStatusSelectorType |
  ProblemJudgeTypeSelectorType;

export const SCOPE_SELECTOR = {
  values: [
    {value: 'public', name: `Public`},
    {value: 'private', name: `Private`}
  ],
  minWidth: 80,
  title: 'Scope'
};
