import {connect} from 'react-redux';

import LoginCard from "../../components/Login/LoginCard";
import {addNotice, loginUser} from "../../store/actions";
import {USER_GUEST} from "../../store/types/user";

export const mapStateToProps = (state, ownProps) => {
  return {}
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loginGoogle: (user) => {
      const userLogged = {...USER_GUEST, ...user, isLogged: true};
      dispatch(loginUser(userLogged));
    },
    addNotice(notice) {
      dispatch(addNotice(notice));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginCard);

