import { connect } from 'react-redux';

import SubmitProblem from '../../../components/Judge/Problem/Submit';
import { addNotice } from '../../../store/actions';
import { Message } from '../../../store/types';

const mapStateToProps = (state: any, ownProps: any) => {
  return {}
};

const mapDispatchToProps = (dispatch: Function, ownProps: any) => {
  return {
    addNotice(notice: Message) {
      dispatch(addNotice(notice));
    }
  }
};

export const ProblemSubmit = connect(mapStateToProps, mapDispatchToProps)(SubmitProblem);
