import { API } from '../server.config';
import { UserNewType } from '../../Types'; // TODO: Delete this file
import { authorizedRequest } from '../services';
import { isJson } from '../../../helpers/functions';
import {
  NewCodeState,
  CodeState,
  UserState,
  NewProblemState,
  ProblemMetaState,
  ProblemState, ProblemTestsState, ProblemTestState
} from '../../../store/types';
import {
  ERROR,
  OK,
  TYPE_ERROR,
  TYPE_OK,
  MetaAttemptType,
  SubmitProblemType,
  NewPageType,
  PageStateType,
  PageUpdateType, TypeJudgeType
} from '../../../types';

export interface ErrorResponse {
  ok: TYPE_ERROR,
  message: string
}

export interface OkResponse {
  ok: TYPE_OK,
  result: any
}

type ResponseType = ErrorResponse | OkResponse;

export const clean = (responseText: string): ResponseType => {
  if (!isJson(responseText)) {
    // this occurs when the endpoint don't exits or server is down
    console.error(responseText);
    return {ok: ERROR, message: 'Service no found'};
  }
  const result = JSON.parse(responseText).result;
  if (typeof result === 'string') {
    return {ok: ERROR, message: result};
  }
  return {ok: OK, result: result};
};

// ********************** BEGIN API/USER
export const serverGetAllUsers = async (): Promise<ResponseType> => {
  return clean(await authorizedRequest(API.USER.USER(), {
    method: 'GET'
  }));
};

export const serverCreateNewUser = async (newUser: UserNewType): Promise<ResponseType> => {
  return clean(await authorizedRequest(API.USER.USER(), {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(newUser)
  }));
};

export const serverSignUpNewUser = async (tokenId: string): Promise<ResponseType> => {
  return clean(await authorizedRequest(API.USER.SIGN(), {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({tokenId: tokenId})
  }));
};

interface OkUserResponse {
  ok: TYPE_OK,
  result: UserState
}

type UserResponse = ErrorResponse | OkUserResponse;

export const serverWhoIs = async (): Promise<UserResponse> => {
  return clean(await authorizedRequest(API.USER.WHO_IS(), {
    method: 'GET'
  }));
};

// interface OkUserResponse {
//   ok: TYPE_OK,
//   result: UserState
// }
//
// type PublicUserResponse = ErrorResponse | OkUserResponse;

export const serverGetUser = async (nickname: string): Promise<UserResponse> => {
  return clean(await authorizedRequest(API.USER.USER(nickname), {
    method: 'GET'
  }));
};

export const serverLoginUser = async (tokenId: string): Promise<ResponseType> => {
  return clean(await authorizedRequest(API.USER.LOGIN(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({tokenId})
  }));
};

export const serverLogout = async (): Promise<ResponseType> => {
  return clean(await authorizedRequest(API.USER.LOGOUT(), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  }));
};

export const serverDeleteUser = async (email: string): Promise<ResponseType> => {
  return clean(await authorizedRequest(API.USER.USER(), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({email})
  }));
};

export const serverUpdateUser = async (user: any): Promise<UserResponse> => {
  return clean(await authorizedRequest(API.USER.USER(), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(user)
  }));
};

// ******************************* BEGIN API/PROBLEM
export const serverCreateNewProblem = async (newProblem: NewProblemState): Promise<ResponseType> => {
  return clean(await authorizedRequest(API.PROBLEM.PROBLEM(), {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(newProblem)
  }));
};

interface OkProblemsResponse {
  ok: TYPE_OK,
  result: Array<ProblemMetaState>
}

type ProblemsResponse = ErrorResponse | OkProblemsResponse;

export const serverGetAllProblems = async (): Promise<ProblemsResponse> => {
  return clean(await authorizedRequest(API.PROBLEM.PROBLEM(), {
    method: 'GET',
    headers: {'Content-Type': 'application/json'}
  }));
};

interface OkProblemResponse {
  ok: TYPE_OK,
  result: ProblemState
}

type ProblemResponse = ErrorResponse | OkProblemResponse;

export const serverGetProblem = async (problemId: number): Promise<ProblemResponse> => {
  return clean(await authorizedRequest(API.PROBLEM.PROBLEM(problemId), {
    method: 'GET',
    headers: {'Content-Type': 'application/json'}
  }));
};

export const serverUpdateProblemTypeJudge = async (newProblem: { id: number, typeJudge: TypeJudgeType }): Promise<ResponseType> => {
  return clean(await authorizedRequest(API.PROBLEM.GROUPS(), {
    method: 'PUT',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(newProblem)
  }));
};

export const serverUpdateProblem = async (newProblem: ProblemState): Promise<ResponseType> => {
  return clean(await authorizedRequest(API.PROBLEM.PROBLEM(), {
    method: 'PUT',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(newProblem)
  }));
};

export const serverDeleteProblem = async (problemId: number): Promise<ResponseType> => {
  return clean(await authorizedRequest(API.PROBLEM.PROBLEM(problemId), {
    method: 'DELETE'
  }));
};


interface OkProblemTestsResponse {
  ok: TYPE_OK,
  result: ProblemTestsState
}

type ProblemTestsResponse = ErrorResponse | OkProblemTestsResponse;

export const serverGetProblemTests = async (problemId: number): Promise<ProblemTestsResponse> => {
  return clean(await authorizedRequest(API.PROBLEM.TESTS(problemId), {
    method: 'GET'
  }));
};

interface OkProblemTestResponse {
  ok: TYPE_OK,
  result: ProblemTestState
}

type ProblemTestResponse = ErrorResponse | OkProblemTestResponse;

export const serverGetProblemTest = async (type: 'sample' | 'case', problemId: number, id: number): Promise<ProblemTestResponse> => {
  return clean(await authorizedRequest(API.PROBLEM.TEST(problemId, type, id), {
    method: 'GET'
  }));
};

export const serverDeleteProblemTest = async (type: 'sample' | 'case', problemId: number, id: number): Promise<ResponseType> => {
  return clean(await authorizedRequest(API.PROBLEM.TEST(problemId, type, id), {
    method: 'DELETE'
  }));
};

export const serverUpdateProblemTest = async (type: 'sample' | 'case', problemId: number, id: number, group: number, input: string, output: string): Promise<ResponseType> => {
  return clean(await authorizedRequest(API.PROBLEM.TEST(problemId, type, id), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      input: input,
      output: output,
      group: group
    })
  }));
};

// ******************************* BEGIN API/SOURCE
interface OkSourcesResponse {
  ok: TYPE_OK,
  result: Array<CodeState>
}

export const serverGetSources = async (): Promise<ErrorResponse | OkSourcesResponse> => {
  return clean(await authorizedRequest(API.SOURCE.SOURCE(), {
    method: 'GET'
  }));
};

interface OkSourceResponse {
  ok: TYPE_OK,
  result: CodeState
}

export const serverCreateNewSource = async (newSource: NewCodeState): Promise<ErrorResponse | OkSourceResponse> => {
  return clean(await authorizedRequest(API.SOURCE.SOURCE(), {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(newSource)
  }));
};

export const serverGetSource = async (id: string): Promise<ErrorResponse | OkSourceResponse> => {
  return clean(await authorizedRequest(API.SOURCE.SOURCE(id), {
    method: 'GET'
  }));
};

export const serverCanUpdateSource = async (id: string, password: string): Promise<ErrorResponse | OkSourceResponse> => {
  return clean(await authorizedRequest(API.SOURCE.CAN(), {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({id, password})
  }));
};

export const serverUpdateSource = async (source: CodeState): Promise<ErrorResponse | OkSourceResponse> => {
  return clean(await authorizedRequest(API.SOURCE.SOURCE(source.id), {
    method: 'PUT',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(source)
  }));
};

export const serverDeleteSource = async (sourceId: string): Promise<ErrorResponse | OkSourceResponse> => {
  return clean(await authorizedRequest(API.SOURCE.SOURCE(sourceId), {
    method: 'DELETE'
  }));
};

export const serverChangePasswordSource = async (sourceId: string, password: string): Promise<ErrorResponse | OkSourceResponse> => {
  return clean(await authorizedRequest(API.SOURCE.PASS(sourceId), {
    method: 'PUT',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({password})
  }));
};


// ******************************* BEGIN API/ATTEMPT

interface OkAttemptsResponse {
  ok: TYPE_OK,
  result: Array<MetaAttemptType>
}

export const serverGetAttempts = async (): Promise<ErrorResponse | OkAttemptsResponse> => {
  return clean(await authorizedRequest(API.ATTEMPT.ATTEMPT(), {
    method: 'GET'
  }));
};

interface OkAttemptResponse {
  ok: TYPE_OK,
  result: MetaAttemptType
}

export const serverSubmitProblemAttempt = async (attempt: SubmitProblemType): Promise<ErrorResponse | OkAttemptResponse> => {
  return clean(await authorizedRequest(API.ATTEMPT.PROBLEM(), {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(attempt)
  }));
};

// ******************************* BEGIN API/PAGE

interface OkPagesResponse {
  ok: TYPE_OK,
  result: Array<PageStateType>
}

export const serverGetPages = async (root: string): Promise<ErrorResponse | OkPagesResponse> => {
  return clean(await authorizedRequest(API.PAGE.PAGE(root), {
    method: 'GET'
  }));
};

interface OkPageResponse {
  ok: TYPE_OK,
  result: PageStateType
}

export const serverGetPage = async (tag: string): Promise<ErrorResponse | OkPageResponse> => {
  return clean(await authorizedRequest(API.PAGE.TAG(tag), {
    method: 'GET'
  }));
};

export const serverCreateNewCategory = async (page: NewPageType): Promise<ErrorResponse | OkPageResponse> => {
  return clean(await authorizedRequest(API.PAGE.PAGE(page.parent), {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(page)
  }));
};

export const serverUpdateCategory = async (page: PageUpdateType, oldTag: string): Promise<ErrorResponse | OkPageResponse> => {
  return clean(await authorizedRequest(API.PAGE.PAGE(oldTag), {
    method: 'PUT',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(page)
  }));
};

export const serverDeleteCategory = async (tag: string): Promise<ErrorResponse | OkPageResponse> => {
  return clean(await authorizedRequest(API.PAGE.PAGE(), {
    method: 'DELETE',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({tag})
  }));
};

// USER
// export const serverGetUsersRoom = async (roomId: string) => {
//   return await fetch(dataUsersRoom_roomId(roomId))
//     .then((response) => response.json())
//     .then((myJson) => myJson)
//     .catch((error) => {
//       console.error('The Error:', error);
//     })
// };
//
// // ROOM
// export const serverGetRoom = async (_id: string) => {
//   return await fetch(dataRoom_roomId(_id))
//     .then((response) => response.json())
//     .then((myJson) => myJson)
//     .catch((error) => {
//       console.error('The Error:', error);
//     })
//   ;
// };

export const serverGetAllAuthorizedRooms = async (): Promise<ResponseType> => {
  return clean(await authorizedRequest(API.ROOM(), {
    method: 'GET'
  }));
};

export const serverDeleteRoom = async (_id: string): Promise<ResponseType> => {
  return clean(await authorizedRequest(API.ROOM(), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({roomId: _id})
  }));
};

export const serverUpdateRoom = async (room: any): Promise<ResponseType> => {
  return clean(await authorizedRequest(API.ROOM(), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(room)
  }));
};

export const serverCreateNewRoom = async (room: any): Promise<ResponseType> => {
  return clean(await authorizedRequest(API.ROOM(), {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(room)
  }));
};

// PRACTICES

// export const serverGetPracticesRoom = async (_id: string) => {
//   return await fetch(dataPracticesRoom_roomId(_id))
//     .then((response) => response.json())
//     .then((myJson) => myJson)
//     .catch((error) => {
//       console.error('The Error:', error);
//     });
// };
//
// export const serverCreateNewPractice = async (practice: any) => {
//   return await fetch(dataPractice(), {
//     method: 'POST',
//     headers: {'Content-Type': 'application/json'},
//     body: JSON.stringify(practice)
//   })
//     .then((response) => response.json())
//     .then((myJson) => myJson)
//     .catch((error) => {
//       console.error('The Error:', error);
//     });
// };
//
// export const serverDeletePractice = async (practiceId: string) => {
//   return await fetch(dataPractice_practiceId(practiceId), {
//     method: 'DELETE',
//   })
//     .then((response) => response.json())
//     .then((myJson) => myJson)
//     .catch((error) => {
//       console.error('The Error:', error);
//     });
// };
//
//
// export const serverUpdatePractice = async (practice: any) => {
//   return await fetch(dataPractice_practiceId(practice._id), {
//     method: 'PUT',
//     headers: {'Content-Type': 'application/json'},
//     body: JSON.stringify(practice)
//   })
//     .then((response) => response.json())
//     .then((myJson) => myJson)
//     .catch((error) => {
//       console.error('The Error:', error);
//     });
// };

export * from './api.graph';
