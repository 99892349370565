import { Message, NOTICES_ACTIONS, NoticesActionTypes } from '../types';
import { ALERT_DURATIONS } from '../../types';

export const removeNotice = (index: number): NoticesActionTypes => {
  return {
    type: NOTICES_ACTIONS.REMOVE_NOTICE,
    index
  }
};

let noticeIndex = 1;
export const addNotice = (message: Message) => {
  return (dispatch: Function): void => {
    const index = noticeIndex;
    dispatch({
      type: NOTICES_ACTIONS.ADD_NOTICE,
      notice: {index: index, type: message.type, label: message.label}
    });
    setTimeout(() => {
      dispatch(removeNotice(index));
    }, ALERT_DURATIONS[message.type]);
    noticeIndex++;
  }
};


