import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const CHUNK_VOLUME_SHOW = 20;

export default ({selectorData, value, onChange}) => {
  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: selectorData.minWidth + 20 + 4 // svg down button + left padding
    }
  }));
  const classes = useStyles();
  const inputRatingLabel = useRef(null);
  const [labelRatingWidth, setLabelRatingWidth] = useState(0);
  const [indexValuesShow, setIndexValuesShow] = useState(0);
  const addSelectableShowProblems = useCallback((currentValues, indexValuesShow) => {
    let counter = 0;
    const newValuesShow = JSON.parse(JSON.stringify(currentValues));
    let index = indexValuesShow;
    for (;
      index < selectorData.values.length;
      index++) {
      if (counter >= CHUNK_VOLUME_SHOW) {
        break;
      }
      newValuesShow.push(selectorData.values[index]);
      counter++;
    }
    setIndexValuesShow(index);
    return newValuesShow;
  }, [selectorData.values]);
  const [valuesShow, setValuesShow] = useState([]);

  useEffect(() => {
    setLabelRatingWidth(inputRatingLabel.current.offsetWidth - 10);
    setIndexValuesShow(0);
    setValuesShow(addSelectableShowProblems([], 0));
  }, [addSelectableShowProblems]);

  const onScroll = (e) => {
    const element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setValuesShow(addSelectableShowProblems(valuesShow, indexValuesShow));
    }
  };
  return <FormControl variant="outlined" className={classes.formControl}>
    <InputLabel ref={inputRatingLabel}
                id="demo-simple-select-outlined-label">
      {selectorData.title}
    </InputLabel>
    {valuesShow.find(selectorValue => (JSON.stringify(selectorValue) === JSON.stringify(value))) &&
    <Select
      labelId="demo-simple-select-outlined-label"
      id="demo-simple-select-outlined"
      value={value}
      onChange={(e) => {
        onChange(e.target.value)
      }}
      labelWidth={labelRatingWidth}
      onScroll={onScroll}
    >
      {valuesShow.map((value) => (
        <MenuItem
          value={value}
          key={'menu-item:' + JSON.stringify(value)}>
          {value.name}
        </MenuItem>
      ))}
    </Select>}
  </FormControl>;
}
