import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Editor from '../../components/Graph/Editor';
import { UserState, GraphState } from '../../store/types';
import { editGraph, putGraph } from '../../store/actions';
import Loader from '../../components/HOC/Loader';
import { APP_ROUTES } from '../../config/constans';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    user: state.user,
    graph: state.graphs[ownProps.match.params.graphId],
    password: (state.router.location.state && state.router.location.state.password) || ''
  }
};

const mapDispatchToProps = (dispatch: Function, ownProps: any) => {
  return {
    updateGraph: async (graph: GraphState) => {
      await dispatch(putGraph(graph));
    },
    editGraph: async (password: string) => {
      dispatch(editGraph(ownProps.match.params.graphId, password));
    }
  }
};

export type GraphEditorProps = {
  // state
  user: UserState,
  graph: GraphState,
  password: string,
  // dispatch
  updateGraph: Function,
  editGraph: Function,
  //
  history: any,
  match: any
}

export const GraphEditor = connect(mapStateToProps, mapDispatchToProps)(withRouter(Loader(Editor,
  async (props: GraphEditorProps) => {
    if (!props.graph || !props.graph.graph) {
      props.history.push(APP_ROUTES.ROOT.GRAPH.VIEW(props.match.params.graphId));
    } else {
      await props.editGraph(props.password);
    }
  }, (props: GraphEditorProps) => {
    return !!props.graph && !!props.graph.graph
  })));
