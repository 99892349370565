import React from 'react';

import Selector from './Selector';
import { EDITABLE, TYPE_EDITABLE, TYPE_NO_EDITABLE, SelectorDataType, ValuesTypes, ValueTypes } from '../../../types';

interface EditableSelectorProps {
  editable: TYPE_EDITABLE,
  selectorData: SelectorDataType,
  value: ValuesTypes,
  onChange: Function
}

interface NoEditableSelectorProps {
  editable: TYPE_NO_EDITABLE,
  defaultValue: JSX.Element,
}

type Props = EditableSelectorProps | NoEditableSelectorProps;

export const SelectorEditable = (props: Props) => {
  if (props.editable === EDITABLE) {
    const {selectorData, value, onChange} = props;
    const selectorValue = selectorData.values[
      Math.max(selectorData.values.findIndex((selectorValue: ValueTypes) => (value === selectorValue.value)), 0)];
    return <Selector
      selectorData={selectorData}
      value={selectorValue}
      onChange={onChange}/>
  } else {
    const {defaultValue} = props;
    return defaultValue;
  }
}
