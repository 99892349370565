import React from 'react';
import {
  Grid, CardMedia, Button
} from '@material-ui/core'
import { Link } from 'react-router-dom';

interface CoverProps {
  // state
  toReturn: string,
}

export default ({toReturn}: CoverProps) => {
  return (
    <>
      <div style={{height: '100vh', width: '100vw', position: 'absolute', top: '0', left: '0', zIndex: 1300}}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Grid item>
            <CardMedia
              component="img"
              alt="Preparation"
              height="100vh"
              // image={user.imageUrl}
              src={'/images/preparation.jpg'}
              title="Preparation"
              style={{height: '100vh', width: '100vw'}}
            />
          </Grid>
        </Grid>
        <Link to={{pathname: toReturn}} style={{bottom: '10px', right: '10px', position: 'absolute'}}>
          <Button variant="contained" size="large">
            LET'S START!
          </Button>
        </Link>
      </div>
    </>
  );
}
