import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';

import { Modal } from '../../Shared';
import { CodeState } from '../../../store/types';

type Props = {
  // store
  source: CodeState,
  // dispatch
  changePasswordSource: Function,
};

export default ({source, changePasswordSource}: Props) => {
  const [passwordNew, setPasswordNew] = useState<string>(source.password);
  const changePassword = useCallback(() => {
    changePasswordSource(passwordNew);
  }, [changePasswordSource, passwordNew]);
  useEffect(() => {
    setPasswordNew(source.password);
  }, [source]);
  return (
    <Modal button="Change Password" title="Changing Password" actions={[
      <Button
        key="change-password-source-action"
        onClick={changePassword}
        disabled={!passwordNew}
      >
        Change
      </Button>]} onCancel={() => setPasswordNew(source.password)}>
      <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
        <Grid item>
          <TextField
            id="outlined-basic-new-user-name"
            label="New Password"
            variant="outlined"
            value={passwordNew}
            onChange={(event) => setPasswordNew(event.target.value)}
          />
        </Grid>
      </Grid>
    </Modal>
  );
}
