export enum TEST_STATUS {
  OK = 'ok',
  CORRUPT = 'corrupt'
}

export interface TestSamplesType {
  input: string,
  output: string,
  group: number,
  status: TEST_STATUS,
  id: number,
}

export interface TestCasesType {
  group: number,
  status: TEST_STATUS,
  id: number,
}

export enum PROBLEM_JUDGE_TYPE {
  TOTAL = 'total',
  POINTS = 'points',
}

export const PRINT_PROBLEM_JUDGE_TYPE = {
  [PROBLEM_JUDGE_TYPE.TOTAL]: 'Total',
  [PROBLEM_JUDGE_TYPE.POINTS]: 'By points'
};

export interface TypeJudgeType {
  mode: PROBLEM_JUDGE_TYPE,
  groupsPoint: Array<{ id: number, points: number }>
}

export enum PROBLEM_STATUS {
  PRIVATE = 'private',
  PUBLIC = 'public',
}

export const PRINT_PROBLEM_STATUS = {
  [PROBLEM_STATUS.PRIVATE]: 'Private',
  [PROBLEM_STATUS.PUBLIC]: 'Public'
};

export enum PROBLEM_INPUT_TYPE {
  STANDARD = 'standard',
  INTERACTIVE = 'interactive'
}

export const PRINT_PROBLEM_INPUT_TYPE = {
  [PROBLEM_INPUT_TYPE.STANDARD]: 'Standard',
  [PROBLEM_INPUT_TYPE.INTERACTIVE]: 'Interactive'
};

export enum PROBLEM_OUTPUT_TYPE {
  EXACT = 'exact',
  SEVERAL = 'several',
  INTERACTIVE = 'interactive'
}

export const PRINT_PROBLEM_OUTPUT_TYPE = {
  [PROBLEM_OUTPUT_TYPE.EXACT]: 'Exact match',
  [PROBLEM_OUTPUT_TYPE.SEVERAL]: 'Several solutions',
  [PROBLEM_OUTPUT_TYPE.INTERACTIVE]: 'Interactive'
};

