import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { AppTable } from '../../Shared';
import { CodeState } from '../../../store/types';
import { HeaderBar } from '../../../containers/Shared';
import { APP_ROUTES } from '../../../config/constans';

export interface Props {
  // state
  sources: Array<CodeState>
  // dispatch
  getAllSources: Function
}

export default ({sources}: Props) => {
  const headCells: Array<{ type: 'numeric' | 'date' | 'string' | 'array', align: 'left' | 'center' | 'right', label: string }> =
    [
      {type: 'string', align: 'center', label: 'Name'},
      {type: 'date', align: 'center', label: 'Creation Date'},
      {type: 'string', align: 'center', label: 'Owner'},
      {type: 'string', align: 'center', label: 'Visibility'}
    ];
  const bodyRows: Array<{ rows: Array<{ value: any }>, id: string }> = sources.map(source => ({
    rows: [
      {value: source.name || source.id, link: APP_ROUTES.ROOT.CODE.VIEW(source.id)},
      {value: new Date(source.date)},
      {value: source.ownerNickname, link: APP_ROUTES.ROOT.PROFILE.USER(source.ownerNickname)},
      {value: source.type}
    ],
    id: source.id
  }));
  return (
    <>
      <HeaderBar title="Sources" actions={[
        <Link to={APP_ROUTES.ROOT.CODE.NEW}>
          <Button variant="outlined" key="add-user-action">
            New Source
          </Button>
        </Link>]}/>
      <AppTable headCells={headCells} bodyRows={bodyRows} config={{order: 2}}/>
    </>
  )
}
