import { connect } from 'react-redux';

import List, { Props } from '../../components/CodeEditor/List';
import { getAllSources } from '../../store/actions/codes';
import Loader from '../../components/HOC/Loader';
import { RootStateType } from '../../store/types';

export const mapStateToProps = (state: RootStateType) => {
  return {
    sources: Object.values(state.sources)
  }
};

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    getAllSources: async () => {
      await dispatch(getAllSources());
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Loader(List,
  async (props: Props) => {
    if (props.sources.length) {
      props.getAllSources();
    } else {
      await props.getAllSources();
    }
  }
));

