import { connect } from 'react-redux';

import Rooms from '../../../../components/Train/Rooms/List/Rooms';
import { Message } from '../../../../store/types';
import { addNotice } from '../../../../store/actions';

export const mapStateToProps = () => {
  return {}
};

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    addNotice(notice: Message) {
      dispatch(addNotice(notice));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Rooms);

