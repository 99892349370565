import { connect } from 'react-redux';

import UserEditable from '../../components/Admin/Users/UserEditable';
import { RootStateType } from '../../store/types';

export const mapStateToProps = (state: RootStateType) => {
  return {
    user: state.user
  }
};

export default connect(mapStateToProps)(UserEditable);

