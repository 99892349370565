import { connect } from 'react-redux';

import TrainingList from '../../../components/Navbar/Tabs/Train';
import { RootStateType } from '../../../store/types';

export const mapStateToProps = (state: RootStateType) => {
  return {
    user: state.user
  }
};

export default connect(mapStateToProps)(TrainingList);

