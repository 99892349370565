import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import ViewCode from '../../components/CodeEditor/Viewer';
import { Message } from '../../store/types/notices';
import { addNotice } from '../../store/actions';
import { deleteSource, getCode, putSource } from '../../store/actions/codes';
import { CodeState } from '../../store/types/codes';
import Loader from '../../components/HOC/Loader';
import { UserState } from '../../store/types/user';

export const mapStateToProps = (state: any, ownProps: any) => {
  return {
    user: state.user,
    source: state.sources[ownProps.match.params.codeId]
  }
};

export const mapDispatchToProps = (dispatch: Function, ownProps: any) => {
  return {
    addNotice: (notice: Message) => {
      dispatch(addNotice(notice));
    },
    getCode: async () => {
      await dispatch(getCode(ownProps.match.params.codeId));
    },
    updateSource: async (source: CodeState) => {
      await dispatch(putSource(source));
    },
    deleteSource: () => {
      dispatch(deleteSource(ownProps.match.params.codeId));
    }
  }
};

export type Props = {
  // state
  user: UserState,
  source: CodeState,
  // dispatch
  addNotice: Function,
  getCode: Function,
  updateSource: Function,
  deleteSource: Function
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Loader(ViewCode,
  async (props: Props) => {
    await props.getCode();
  }, (props: Props) => { // when to click back
    return !!props.source
  })));
