import React from 'react';
// import React, {useCallback, useEffect, useState} from 'react';
// import * as DateFns from 'date-fns';
// import {
//   Table, TableBody, TableCell, TableHead, TableRow,
//   Container,
// } from "@material-ui/core";
// import {Link} from "react-router-dom";
//
// import {SCOPE_SELECTOR, ROOM_TYPE_SELECTOR} from "../../../../types/selectors";
// import {
//   serverGetAllAuthorizedRooms,
// } from "../../../../config/services/backend";
// import TextFieldEditable from "../../../Shared/TextFieldEditable";
// import SelectorEditable from "../../../Shared/SelectorEditable";
// import TableRowStyled from "../../../Shared/TableRowStyled";
// import {ALERT_TYPES} from "../../../../types/alert";
// import AddRoom from "../../../../containers/Train/Rooms/List/AddRoom";
// import EditRoom from "../../../../containers/Train/Rooms/List/EditRoom";
// import DeleteRoom from "../../../../containers/Train/Rooms/List/DeleteRoom";
// import {OK} from "../../../../types/services";
// import AppBar from '../../../../containers/Shared/HeaderBar';
// import { NO_EDITABLE } from '../../../../types';

export default ({addNotice}) => {
  return <></>;
  // const [rooms, setRooms] = useState([]);
  //
  // const getData = useCallback(async () => {
  //   const request = await serverGetAllAuthorizedRooms();
  //   if (request.ok === OK) {
  //     const ordered = request.result.sort((roomA, roomB) => {
  //       if (DateFns.isAfter(new Date(roomA.date), new Date(roomB.date))) {
  //         return -1;
  //       }
  //       if (DateFns.isAfter(new Date(roomB.date), new Date(roomA.date))) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setRooms(JSON.parse(JSON.stringify(ordered)));
  //     addNotice({type: ALERT_TYPES.SUCCESS, label: 'Successfully rooms loaded'});
  //   } else {
  //     addNotice({type: ALERT_TYPES.ERROR, label: request.message});
  //   }
  // }, [addNotice]);
  // useEffect(() => {
  //   getData();
  // }, [getData]);
  //
  // return <>
  //   <AppBar title={'Rooms'} actions={[<AddRoom getData={getData} key="add-room-action-app"/>]}/>
  //   <Container>
  //     <Table aria-label="customized table">
  //       <TableHead>
  //         <TableRowStyled>
  //           <TableCell align={'center'}>Name</TableCell>
  //           <TableCell align={'center'}>Type</TableCell>
  //           <TableCell align={'center'}>Members</TableCell>
  //           <TableCell align={'center'}>Owner</TableCell>
  //           <TableCell align={'center'}>Scope</TableCell>
  //           <TableCell align={'center'}>OPS</TableCell>
  //         </TableRowStyled>
  //       </TableHead>
  //       <TableBody>
  //         {rooms.map(room => {
  //           return <TableRow key={JSON.stringify(room)}>
  //             <TableCell component="td">
  //               <Link to={{pathname: `/room/${room._id}`}}>
  //                 <TextFieldEditable
  //                   editable={false}
  //                   label="Name"
  //                   defaultValue={room.name + DateFns.format(new Date(room.date), 'd MMMM uuuu, k:m:s')}
  //                 />
  //               </Link>
  //             </TableCell>
  //             <TableCell component="td">
  //               <SelectorEditable
  //                 editable={NO_EDITABLE}
  //                 selectorData={ROOM_TYPE_SELECTOR}
  //                 defaultValue={(ROOM_TYPE_SELECTOR.values.find(typeRoom => typeRoom.value === room.type) || {name: 'Other'}).name}/>
  //             </TableCell>
  //             <TableCell component="td">
  //               <TextFieldEditable
  //                 editable={false}
  //                 label="Members"
  //                 defaultValue={room.members.map((member) => member.userEmail).join(`\n`)}/>
  //             </TableCell>
  //             <TableCell component="td">
  //               <TextFieldEditable
  //                 editable={false}
  //                 label="Email"
  //                 defaultValue={room.ownerUserEmail}/>
  //             </TableCell>
  //             <TableCell component="td">
  //               <SelectorEditable
  //                 editable={NO_EDITABLE}
  //                 selectorData={SCOPE_SELECTOR}
  //                 defaultValue={(SCOPE_SELECTOR.values.find(scope => scope.value === room.scope) || {name: 'Other'}).name}/>
  //             </TableCell>
  //             <TableCell component="td" style={{minWidth: '60px'}}>
  //               <EditRoom room={room} getData={getData}/>
  //               <DeleteRoom room={room} getData={getData}/>
  //             </TableCell>
  //           </TableRow>
  //         })}
  //       </TableBody>
  //     </Table>
  //   </Container>
  // </>
}
