import { connect } from 'react-redux';

import Account from '../../components/Account/Account';
import { addNotice, updateUser, logoutUser, getUser } from '../../store/actions';
import { Message } from '../../store/types/notices';
import { UserState } from '../../store/types/user';
import { RootStateType } from '../../store/types';

export const mapStateToProps = (state: RootStateType, ownProps: any) => {
  return {
    user: state.user
  }
};

export const mapDispatchToProps = (dispatch: Function, ownProps: any) => {
  return {
    getUser() {
      dispatch(getUser());
    },
    async updateUser(user: UserState) {
      await dispatch(updateUser(user));
    },
    logoutGoogle() {
      dispatch(logoutUser());
    },
    addNotice: (notice: Message) => {
      dispatch(addNotice(notice));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);

