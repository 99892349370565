import {connect} from 'react-redux';

import BitbucketAuth from "./BitbucketAuth";
import {saveBitbucketAccessToken} from "../../store/actions";
import {withRouter} from "react-router";

export const mapStateToProps = (state: any, ownProps: any) => {
  return {
    hash: state.router.location.hash
  }
};

export const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    saveAccessToken(token: string) {
      dispatch(saveBitbucketAccessToken(token));
    }
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BitbucketAuth));

