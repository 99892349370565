import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import ChangePassword from '../../components/CodeEditor/Viewer/ChangePassword';
import { changePasswordSource } from '../../store/actions/codes';
import { RootStateType } from '../../store/types';

export const mapStateToProps = (state: RootStateType, ownProps: any) => {
  return {
    source: state.sources[ownProps.match.params.codeId]
  }
};

export const mapDispatchToProps = (dispatch: Function, ownProps: any) => {
  return {
    changePasswordSource: (password: string) => {
      dispatch(changePasswordSource(ownProps.match.params.codeId, password));
    }
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));

