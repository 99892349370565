import { connect } from 'react-redux';

import List from '../../components/Graph/List';
import { getAllGraphs } from '../../store/actions';
import Loader from '../../components/HOC/Loader';
import { GraphState, RootStateType } from '../../store/types';

const mapStateToProps = (state: RootStateType) => {
  return {
    graphs: Object.values(state.graphs)
  }
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    getAllGraphs: async () => {
      await dispatch(getAllGraphs());
    }
  }
};

export interface GraphListProps {
  // state
  graphs: Array<GraphState>
  // dispatch
  getAllGraphs: Function
}

export const GraphList = connect(mapStateToProps, mapDispatchToProps)(Loader(List,
  async (props: GraphListProps) => {
    if (props.graphs.length) {
      props.getAllGraphs();
    } else {
      await props.getAllGraphs();
    }
  }
));

