import {Grid, Typography} from "@material-ui/core";
import GoogleLogin from "react-google-login";
import {GOOGLE} from "../../config/constans";
import clsx from "clsx";
import React, {useState} from "react";
import {serverLoginUser, serverSignUpNewUser} from "../../config/services/backend";
import {OK} from "../../types/services";
import {ALERT_TYPES} from "../../types/alert";
import {useStyles} from "../../config/styles";

export default ({
                  loginGoogle, addNotice // dispatch
                }) => {
  const [registered, setRegistered] = useState(null);
  const [errorLogin, setErrorLogin] = useState(null);
  const responseGoogle = (response) => {
    if (!response.error) {
      serverLoginUser(response.tokenId)
        .then(request => {
          if (request.ok === OK) {
            loginGoogle(request.result);
            addNotice({type: ALERT_TYPES.SUCCESS, label: 'Welcome ' + response.profileObj.givenName});
          } else {
            setErrorLogin(request.message);
          }
        });
    } else {
      setErrorLogin(response.error);
    }
  };
  const signUpWithGoogle = (response) => {
    if (!response.error) {
      serverSignUpNewUser(response.tokenId)
        .then(request => {
          if (request.ok === OK) {
            setRegistered(<Typography variant="h6" gutterBottom>
              OK! Now Login Please
            </Typography>);
            setErrorLogin(null);
          } else {
            setRegistered(<Typography variant="h6" gutterBottom>
              {request.message}
            </Typography>);
          }
        });
    }
  };
  const classes = useStyles();
  return <Grid
    container
    direction="column"
    justify="space-around"
    alignItems="center"
    style={{height: '100%'}}
  >
    <Grid item>
      <Typography className={classes.flexGrow1}
                  color="textSecondary" gutterBottom>
        LOGIN
      </Typography>
    </Grid>
    <Grid item>
      <GoogleLogin
        clientId={GOOGLE.CLIENT_ID}
        onSuccess={responseGoogle}
        onFailure={(err) => console.warn('failure google login', err)}
        cookiePolicy={'single_host_origin'}
        className={clsx(classes.flexGrow1)}
      />
    </Grid>
    <Grid item>
      <div className={classes.flexGrow1}>
        {errorLogin}
      </div>
    </Grid>
    <Grid item>
      <GoogleLogin
        clientId={GOOGLE.CLIENT_ID}
        buttonText="Sign up with Google"
        onSuccess={signUpWithGoogle}
        onFailure={(err) => console.warn('failure google login', err)}
        cookiePolicy={'single_host_origin'}
        className={classes.flexGrow1}
      />
    </Grid>
    <Grid item>
      <div className={classes.flexGrow1}>
        {registered}
      </div>
    </Grid>
  </Grid>
}
