import React, { useState } from 'react';
import clsx from 'clsx';
import { Button, Grid } from '@material-ui/core';

import { useStyles } from '../../../config/styles';
import { UserState, NewCodeState } from '../../../store/types';
import {
  LANGUAGE_TYPE_SELECTOR,
  SCOPE_VISIBILITY_LOGGED_IN_SELECTOR,
  SCOPE_VISIBILITY_SELECTOR,
  EDITABLE,
  LANGUAGE_TYPES
} from '../../../types';
import { AceEditor, SelectorEditable, TextFieldEditable, TitleBox } from '../../Shared';
import { SCOPE_VISIBILITY } from '../../../config/constans';
import { HeaderBar } from '../../../containers/Shared';

type Props = {
  // state
  user: UserState,
  // dispatch
  createNewSource: Function,
}

export default ({user, createNewSource}: Props) => {
  const classes = useStyles();
  const [source, setSource] = useState<NewCodeState>({
    name: '',
    description: '',
    type: SCOPE_VISIBILITY.PUBLIC,
    language: LANGUAGE_TYPES.CPP,
    source: `#include <iostream>

using namespace std;

int main() {
  cout << "May the force be with you" << endl;
  return 0;
}`
  });

  const actions = [<Button variant="outlined" onClick={() => createNewSource(source)}>
    Save & Create
  </Button>];
  return (
    <>
      <HeaderBar title="Editor" actions={actions}/>
      <div className={clsx(classes.pageMaxHeight, classes.boxShadow)}>
        <TitleBox
          body={
            <div style={{height: 'calc(100% - 50px)'}}>
              <AceEditor
                value={source.source}
                language={source.language}
                onChange={(value) => setSource(prevState => ({...prevState, source: value}))}/>
            </div>
          }
          title={
            <Grid
              container
              justify="space-between"
              alignItems="center"
              className={classes.maxDivSize}
            >
              <Grid item>
                <TextFieldEditable
                  label="Title"
                  value={source.name}
                  onChange={({target}: React.ChangeEvent<HTMLInputElement>) => setSource(prevState => ({
                    ...prevState,
                    name: target.value
                  }))}/>
              </Grid>
              <Grid item>
                <SelectorEditable
                  editable={EDITABLE}
                  selectorData={LANGUAGE_TYPE_SELECTOR}
                  value={source.language}
                  onChange={(value: { value: LANGUAGE_TYPES }) => setSource(prevState => ({
                    ...prevState,
                    language: value.value
                  }))}
                />
              </Grid>
              <Grid item>
                <SelectorEditable
                  editable={EDITABLE}
                  selectorData={user.isLogged ? SCOPE_VISIBILITY_LOGGED_IN_SELECTOR : SCOPE_VISIBILITY_SELECTOR}
                  value={source.type}
                  onChange={(value: { value: SCOPE_VISIBILITY }) => setSource(prevState => ({
                    ...prevState,
                    type: value.value
                  }))}
                />
              </Grid>
            </Grid>
          }
        />
      </div>
    </>
  );
}
