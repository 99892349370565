import {
  PROBLEM_INPUT_TYPE,
  PROBLEM_OUTPUT_TYPE,
  PROBLEM_STATUS,
  TestCasesType,
  TestSamplesType,
  TypeJudgeType
} from '../../types';

export interface NewProblemState {
  name: string,
  status: PROBLEM_STATUS,
  timeLimitPerTest: number,
  memoryLimitPerTest: number
  typeInput: PROBLEM_INPUT_TYPE,
  typeOutput: PROBLEM_OUTPUT_TYPE,
  description: string,
  inputDescription: string,
  outputDescription: string,
  samples: Array<TestSamplesType>,
  author: string,
  tags: Array<String>
}

export interface ProblemState {
  id: number,
  name: string,
  date: Date,
  status: PROBLEM_STATUS,
  timeLimitPerTest: number,
  memoryLimitPerTest: number
  typeInput: PROBLEM_INPUT_TYPE,
  typeOutput: PROBLEM_OUTPUT_TYPE,
  description: string,
  inputDescription: string,
  outputDescription: string,
  samples: Array<TestSamplesType>,
  ownerNickname: string,
  author: string,
  tags: Array<String>,
  typeJudge: TypeJudgeType,
  testCases: Array<TestCasesType>
}

export interface ProblemMetaState {
  id: number,
  name: string,
  date: Date,
  status: PROBLEM_STATUS,
  typeInput: PROBLEM_INPUT_TYPE,
  typeOutput: PROBLEM_OUTPUT_TYPE,
  ownerNickname: string,
  author: string,
  tags: Array<string>
}

export interface ProblemTestsState {
  samples: Array<TestSamplesType>,
  typeJudge: TypeJudgeType,
  testCases: Array<TestCasesType>
}

export interface ProblemTestState {
  input: string,
  output: string
}

export enum PROBLEMS_ACTIONS {
  UPDATE_PROBLEM = 'UPDATE_PROBLEM',
  REPLACE_PROBLEMS = 'REPLACE_PROBLEMS'
}

interface UpdateProblemAction {
  type: PROBLEMS_ACTIONS.UPDATE_PROBLEM,
  problem: ProblemState,
}

interface ReplaceProblemsAction {
  type: PROBLEMS_ACTIONS.REPLACE_PROBLEMS,
  problems: Array<ProblemMetaState>,
}

export type ProblemsActionTypes = UpdateProblemAction | ReplaceProblemsAction;
