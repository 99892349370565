import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction,
  IconButton
} from '@material-ui/core';
import {
  Folder as FolderIcon,
  Delete as DeleteIcon,
  InsertDriveFile as InsertDriveFileIcon,
  FolderOpen as FolderOpenIcon
} from '@material-ui/icons';

import {
  getCommitSHAHead,
  getAllSrcRepository,
  getAllSrcRepositoryFromFullPath
  // bitbucketTextPlain
} from '../config/services/services.bitbucket';

const Test = ({user}) => {
  const noAuth = 'First authenticate in Bitbucket';
  const [src, setSrc] = useState({src: [], pathParent: []});
  const [hashHead, setHashHead] = useState('');
  const initialGetSrc = async () => {
    const src = (await getAllSrcRepository('workspace', hashHead, '/'))
      .map((src, index) => {
        const params = src.path.split('/');
        return {
          name: params[params.length - 1],
          type: src.type,
          selfHref: src.links.self.href,
          pathParent: [index],
          src: null
        }
      });
    setSrc({src: src, pathParent: []});
  };

  const getSrc = async (pathParent, path) => {
    const srcPath = (await getAllSrcRepositoryFromFullPath(path))
      .map((src, index) => {
        const params = src.path.split('/');
        return {
          name: params[params.length - 1],
          type: src.type,
          selfHref: src.links.self.href,
          pathParent: [...pathParent, index]
        }
      });
    const newSrc = JSON.parse(JSON.stringify(src));
    let currentNode = newSrc;
    pathParent.forEach((indexNode) => {
      currentNode = currentNode.src[indexNode];
    });
    currentNode.src = srcPath;
    setSrc(JSON.parse(JSON.stringify(newSrc)));
  };

  const deleteSrc = async (pathParent) => {
    const newSrc = JSON.parse(JSON.stringify(src));
    let currentNode = newSrc;
    pathParent.forEach((indexNode) => {
      currentNode = currentNode.src[indexNode];
    });
    currentNode.src = null;
    setSrc(JSON.parse(JSON.stringify(newSrc)));
  };

  const getFile = async (fulPath) => {
    // const text = await bitbucketTextPlain(fulPath);

  };

  const print = () => {
    const printList = (list) => {
      if (!list.src) {
        return '';
      }
      return (
        <>
          <List dense={true} style={{padding: '2px 0px'}} key={'list-' + list.pathParent.join('-')}>
            {list.src.map((item, index) => <>
              <ListItem key={item.name + list.pathParent.join('-')}>
                <ListItemIcon>
                  <>
                    <div style={{width: (item.pathParent.length - 1) * 23 + 21 + 'px'}}>
                      <div
                        style={{
                          position: 'absolute', height: '41px',
                          width: item.pathParent.length * 23 + 'px',
                          display: 'inline-flex', top: '-14px'
                        }}>
                        {item.pathParent.map((indexParent, index) => <div style={{
                            height: '100%',
                            width: '0px',
                            borderLeft: '3px solid',
                            margin: '0px 13px 0px 7px'
                          }} key={item.name + item.pathParent.join('-') + 'div' + index}/>
                        )}
                        <div style={{
                          height: '100%',
                          width: '14px',
                          borderBottom: '3px solid',
                          left: (item.pathParent.length - 1) * 23 + 7 + 'px',
                          bottom: '4px',
                          position: 'absolute'
                        }}/>
                      </div>
                    </div>
                    {item.type === 'commit_directory' ? (item.src ?
                      <FolderOpenIcon/> :
                      <FolderIcon/>) :
                      <InsertDriveFileIcon/>}
                  </>
                </ListItemIcon>
                <ListItemText
                  primary={<div>
                    {item.name}
                  </div>}
                  onClick={() => item.type === 'commit_directory' ? (item.src ?
                    deleteSrc(item.pathParent) :
                    getSrc(item.pathParent, item.selfHref)) :
                    getFile(item.selfHref)
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton>
                    <DeleteIcon/>
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              {printList(item)}
            </>)}
          </List>
        </>
      );
    };

    return printList(src)

  };

  useEffect(() => {
    getCommitSHAHead('workspace').then(hashHead => {
      if (hashHead) {
        setHashHead(hashHead)
      } else {
        setHashHead(noAuth);
      }
    });
  }, []);
  return <div>
    {hashHead === noAuth ? hashHead : <div onClick={initialGetSrc}>click</div>}
    {print()}
  </div>
};


export const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user
  }
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Test));
