import React from 'react';

import {CONTENT, TYPES_CONTENTS} from "../../../../config/constans";
import ViewerPlainText from "./ViewerPlainText";
// import ViewerLatex from "./ViewerLatex";

export default ({section}) => {
  const content = CONTENT[section] ||
    {
      title: 'Redactando...', contentType: TYPES_CONTENTS.LATEX, pathContent: '/latex/heap.tex'
    } ||
    {
      title: 'Redactando...', contentType: TYPES_CONTENTS.PLAIN_TEXT, pathContent: '/plainText/void.txt'
    };
  const file = require('../../../../files' + content.pathContent);
  return (
    <div>
      <h1>
        content.title
      </h1>
      {content.contentType === TYPES_CONTENTS.PLAIN_TEXT ?
        <ViewerPlainText source={file}/> :
        content.contentType === TYPES_CONTENTS.LATEX ?
          <ViewerPlainText source={file}/> :
          null}
    </div>
  )
}
