import { connect } from 'react-redux';

import { WebPageComponent } from '../../../components/Shared/WebPage';
import { addNotice, loginUser } from '../../../store/actions';
import { USER_GUEST, UserState, Message, RootStateType } from '../../../store/types';

const mapStateToProps = (state: RootStateType) => {
  return {
    blocking: state.flags.blocking
  }
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    addNotice: (notice: Message) => {
      dispatch(addNotice(notice));
    },
    loginGoogle: (user: UserState) => {
      const userLogged = {...USER_GUEST, ...user, isLogged: true};
      dispatch(loginUser(userLogged));
    }
  }
};

export const WebPage = connect(mapStateToProps, mapDispatchToProps)(WebPageComponent);

